import {IntlShape} from "react-intl";
import * as yup from "yup";
import {Car, DriverDocuments, DriverPersonalInformation} from "../generated-api/registerApi";
import {cachedPhoneNumberValidator} from "../utils/cachedValidators";
import {minCarYear} from "./constants";
import {RegistrationData} from "./model";

export const registrationLoginSchema = (intl: IntlShape) => {
    const validationUtil = new SchemaValidationUtil(intl);
    return yup.object().shape({
        email: validationUtil
            .requiredString("email")
            .email(({label}: {label: string}) => intl.formatMessage({id: "invalidField"}, {label})),
        password: validationUtil
            .requiredString("password")
            .test(
                "len",
                intl.formatMessage({id: "passwordShort"}),
                (val: string) => (val?.length ?? 0) >= 8
            )
            .test(
                "whitespaces",
                intl.formatMessage({id: "passwordWhitespace"}),
                (val: string) => !val?.includes(" ")
            ),
        passwordAgain: validationUtil
            .requiredString("passwordAgain")
            .oneOf([yup.ref("password")], intl.formatMessage({id: "passwordsAreNotSame"})),
    });
};

export const personalInfoYupSchema = (intl: IntlShape) => {
    const validationUtil = new SchemaValidationUtil(intl);

    const personalInfoSchema = yup.object().shape({
        firstName: validationUtil.requiredString("personalInfo.firstName"),
        lastName: validationUtil.requiredString("personalInfo.lastName"),
        phoneNumber: validationUtil
            .requiredString("personalInfo.phoneNumber")
            .test(
                "checkPhoneNumber",
                intl.formatMessage({id: "personalInfo.phoneNumberInvalid"}),
                cachedPhoneNumberValidator
            ),
        workingCity: validationUtil.requiredString("personalInfo.workingCity"),
        driverGroup: validationUtil.requiredString("personalInfo.driverGroup"),
        taximeter: yup.boolean(),
    });

    return yup.object().shape({
        personalInfo: personalInfoSchema,
    });
};

export const documentsYupSchema = (
    intl: IntlShape,
    driverGroup: DriverPersonalInformation["driverGroup"]
) => {
    const validationUtil = new SchemaValidationUtil(intl);

    const drivingLicenseSchema = yup.object().shape({
        number: validationUtil.requiredString("documents.drivingLicense.number"),
        expiration: validationUtil.requiredString("documents.drivingLicense.expiration"),
        birthPlace: validationUtil.requiredString("documents.drivingLicense.birthPlace"),
        birthDate: validationUtil.requiredString("documents.drivingLicense.birthDate"),
        photoPage1: validationUtil.requiredString("documents.drivingLicense.photoPage1"),
        photoPage2: validationUtil.requiredString("documents.drivingLicense.photoPage2"),
    });

    const yellowCardSchema = yup.object().shape({
        number: validationUtil
            .requiredStringWithCondition(
                "documents.yellowCard.number",
                () => driverGroup === "CARGO"
            )
            .matches(/^\d{1,6}$/, ({label}) => intl.formatMessage({id: "invalidField"}, {label})),
        validity: validationUtil.requiredStringWithCondition(
            "documents.yellowCard.validity",
            () => driverGroup === "CARGO"
        ),
        photo: validationUtil.requiredStringWithCondition(
            "documents.yellowCard.photo",
            () => driverGroup === "CARGO"
        ),
    });

    const idCardSchema = yup.object().shape({
        number: validationUtil.requiredStringWithCondition(
            "documents.idCard.number",
            () => driverGroup !== "CARGO"
        ),
        expiration: validationUtil.requiredStringWithCondition(
            "documents.idCard.expiration",
            () => driverGroup !== "CARGO"
        ),
        photo: validationUtil.requiredStringWithCondition(
            "documents.idCard.photo",
            () => driverGroup !== "CARGO"
        ),
    });

    const companySchema = yup.object().shape({
        companyId: validationUtil.required(
            yup
                .string()
                .matches(
                    /^\d+$/,
                    intl.formatMessage(
                        {id: "invalidField"},
                        {label: intl.formatMessage({id: "documents.company.companyId"})}
                    )
                ),
            "documents.company.companyId"
        ),
        paysVAT: yup.boolean(),
        taxId: yup.string().when('paysVAT', {
            is: true,
            then: () => validationUtil.requiredString("documents.company.taxId"),
            otherwise: () => yup.string(),
        }),
        companyName: validationUtil.requiredString("documents.company.companyName"),
        street: validationUtil.requiredString("documents.company.street"),
        city: validationUtil.requiredString("documents.company.city"),
        zip: validationUtil.required(
            yup
                .string()
                .matches(
                    /^\d{3} ?\d{2}$/,
                    intl.formatMessage({id: "document.company.zipIncorrectFormat"})
                ),
            "documents.company.zip"
        ),
    });

    const documentsSchema =
        driverGroup === "CARGO"
            ? yup.object({
                  profilePhoto: validationUtil.requiredString("documents.profilePhoto"),
                  idCard: idCardSchema,
                  drivingLicense: drivingLicenseSchema,
                  company: companySchema,
              })
            : yup.object({
                  profilePhoto: validationUtil.requiredString("documents.profilePhoto"),
                  yellowCard: yellowCardSchema,
                  drivingLicense: drivingLicenseSchema,
                  company: companySchema,
              });

    return yup.object().shape({
        documents: documentsSchema,
    });
};

export const carYupSchema = (
    intl: IntlShape,
    driverGroup: DriverPersonalInformation["driverGroup"]
) => {
    const validationUtil = new SchemaValidationUtil(intl);

    const carSchema = yup.object().shape({
        brand: validationUtil.requiredString("car.brand"),
        model: validationUtil.requiredString("car.model"),
        yearOfManufacture: validationUtil
            .required(
                yup
                    .number()
                    .typeError(({label}: {label: string}) =>
                        intl.formatMessage({id: "car.carYearOfManufactureInvalid"}, {label})
                    ),
                "car.yearOfManufacture"
            )
            .min(minCarYear, intl.formatMessage({id: "car.carYearOfManufactureTooLow"}))
            .max(
                new Date().getFullYear(),
                intl.formatMessage(
                    {id: "car.carYearOfManufactureTooHigh"},
                    {currentYear: new Date().getFullYear()}
                )
            ),
        type: validationUtil.requiredString("car.type"),
        licencePlate: validationUtil.requiredString("car.licencePlate"),
        color: validationUtil.requiredString("car.color"),
        fuelType: validationUtil.requiredString("car.fuelType"),
        photo: validationUtil.requiredString("car.photo"),
        carTechnicalCertificateNumber: validationUtil.requiredString(
            "car.carTechnicalCertificateNumber"
        ),
        seatCount: validationUtil
            .required(
                yup
                    .number()
                    .typeError(({label}: {label: string}) =>
                        intl.formatMessage({id: "car.seatCountInvalid"}, {label})
                    ),
                "car.seatCount"
            )
            .min(1, intl.formatMessage({id: "car.seatCountInvalid"}))
            .max(9, intl.formatMessage({id: "car.seatCountInvalid"})),
        technicalCertificatePage1: validationUtil.requiredString("car.technicalCertificatePage1"),
        technicalCertificatePage2: validationUtil.requiredString("car.technicalCertificatePage2"),
        taxiEvidencePhoto: validationUtil.requiredStringWithCondition(
            "car.taxiEvidencePhoto",
            () => driverGroup === "CARGO"
        ),
    });

    return yup.object().shape({
        car: carSchema,
    });
};

class SchemaValidationUtil {
    private intl: IntlShape;

    constructor(intl: IntlShape) {
        this.intl = intl;
    }

    required(field: yup.Schema, label: string) {
        return field
            .required(({label}) => this.intl.formatMessage({id: "requiredField"}, {label}))
            .label(this.intl.formatMessage({id: label}));
    }

    requiredString(label: string) {
        return this.required(yup.string(), label);
    }

    requiredStringWithCondition(label: string, notRequiredCondition: () => boolean) {
        return yup
            .string()
            .label(this.intl.formatMessage({id: label}))
            .notRequired()
            .test(
                `required_${label}`,
                ({label}) => this.intl.formatMessage({id: "requiredField"}, {label}),
                (value) => {
                    if (notRequiredCondition() || value) {
                        return true;
                    } else {
                        return false;
                    }
                }
            );
    }
}

export const registrationDataInitValues: RegistrationData = {
    email: "",
    password: "",
    passwordAgain: "",
};

export const personalInfoInitValues: DriverPersonalInformation = {
    firstName: "",
    lastName: "",
    phoneNumber: "+420",
    workingCity: "",
    driverGroup: "UNIVERSAL",
    taximeter: true,
};

export const documentsInitValues: DriverDocuments = {
    profilePhoto: "",
    yellowCard: {
        number: "",
        validity: "",
        photo: "",
    },
    idCard: {
        number: "",
        expiration: "",
        photo: "",
    },
    drivingLicense: {
        number: "",
        expiration: "",
        photoPage1: "",
        photoPage2: "",
        birthDate: "",
        birthPlace: "",
    },
    company: {
        companyId: "",
        companyName: "",
        paysVAT: false,
        taxId: "",
        city: "",
        street: "",
        zip: "",
        editable: true,
    },
};

export const carInitValues: Car = {
    brand: "",
    model: "",
    yearOfManufacture: "",
    type: "Combi",
    licencePlate: "",
    color: "BLACK",
    fuelType: "Diesel",
    photo: "",
    technicalCertificatePage1: "",
    technicalCertificatePage2: "",
    carTechnicalCertificateNumber: "",
    taxiEvidencePhoto: "",
    seatCount: undefined,
};
