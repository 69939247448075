import {createRoot} from "react-dom/client";
import TagManager from "react-gtm-module";
import {App} from "./App";
import "./index.scss";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<App />);

declare const appConfig: {gtmId: string};

if (appConfig.gtmId != null) {
    TagManager.initialize({
        gtmId: appConfig.gtmId,
    });
}
