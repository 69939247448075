import {yupResolver} from "@hookform/resolvers/yup";
import {useEffect} from "react";
import {Form, Spinner} from "react-bootstrap";
import {useForm, useWatch} from "react-hook-form";
import {FormattedMessage, useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {styled} from "styled-components";
import {DriverInformation} from "../generated-api/registerApi";
import {logGtm} from "../utils/gtm";
import {ControlledRow, RegistrationPage, SectionCard} from "./RegistrationPage";
import {useCities, useLoadDriverState, useUpdateDriverState} from "./apiClientProvider";
import {personalInfoInitValues, personalInfoYupSchema} from "./formsSchema";
import {formatPlaceholder, navigateToNext} from "./functions";
import {PageName, SubFormProps} from "./model";

export function PersonalInfoForm() {
    const intl = useIntl();
    const navigate = useNavigate();

    const {driverState, isFetching} = useLoadDriverState();
    const mutation = useUpdateDriverState();

    // redirect to registration overview just in case user tries to get here via navigation
    useEffect(() => {
        if (driverState.personalInfo?.lastName && !isFetching) {
            navigateToNext(navigate, PageName.PERSONAL_INFO);
        }
    }, [driverState.personalInfo, isFetching]);

    const initValues: DriverInformation = driverState.personalInfo
        ? driverState
        : {...driverState, personalInfo: personalInfoInitValues};

    const {
        formState: {errors},
        control,
        handleSubmit,
        setValue,
    } = useForm({
        resolver: yupResolver(personalInfoYupSchema(intl)),
        values: initValues,
        defaultValues: initValues,
        mode: "onBlur",
    });

    async function onSubmit(data: DriverInformation) {
        mutation.mutate(data, {
            onSuccess: () => logGtm("registration", data.personalInfo?.driverGroup),
        });
        navigateToNext(navigate, PageName.PERSONAL_INFO);
    }

    function onBlurTrim(field: any) {
        setValue(field.name, field.value?.trim());
        field.onBlur();
    }

    const children = () => (
        <>
            <Profile control={control} errors={errors} onBlurTrim={onBlurTrim} />
            <Gdpr />
        </>
    );

    return (
        <RegistrationPage
            hasNextPage
            headerTextId="account"
            onSubmitClick={handleSubmit(onSubmit)}
            children={children}
        />
    );
}

function Gdpr() {
    const intl = useIntl();

    return (
        <div style={{marginTop: "var(--spacing-medium)"}}>
            <GdprText>
                <FormattedMessage id="sign_up.gdpr_intro" />
            </GdprText>
            <ul>
                <li>
                    <GdprText>
                        <FormattedMessage id="sign_up.gdpr_bullet_1" />
                    </GdprText>
                </li>
                <li>
                    <GdprText>
                        <FormattedMessage
                            id="sign_up.gdpr_bullet_2"
                            values={{
                                a: (label) => (
                                    <a
                                        style={{color: "var(--bs-primary)", textDecoration: "none"}}
                                        target="_blank"
                                        rel="noreferrer"
                                        href={intl.formatMessage({id: "generalTerms"})}
                                    >
                                        {label}
                                    </a>
                                ),
                            }}
                        />
                    </GdprText>
                </li>
                <li>
                    <GdprText>
                        <FormattedMessage id="sign_up.gdpr_bullet_3" />
                    </GdprText>
                </li>
            </ul>
            <GdprText>
                <FormattedMessage
                    id="sign_up.gdpr_outro"
                    values={{
                        a: (label) => (
                            <a
                                style={{color: "var(--bs-primary)", textDecoration: "none"}}
                                target="_blank"
                                rel="noreferrer"
                                href={intl.formatMessage({id: "privacyPolicy"})}
                            >
                                {label}
                            </a>
                        ),
                    }}
                />
            </GdprText>
        </div>
    );
}

function Profile({control, errors, onBlurTrim = (field: any) => field.onBlur()}: SubFormProps) {
    const intl = useIntl();
    const selectedDriverGroup = useWatch({
        control: control,
        name: "personalInfo.driverGroup",
    });

    const {data: cities} = useCities();
    return (
        <SectionCard headerId="profile">
            <ControlledRow
                name="personalInfo.firstName"
                control={control}
                render={({field}) => (
                    <Form.Control
                        {...field}
                        data-cy="first-name-input"
                        type="text"
                        placeholder={formatPlaceholder(intl, field.name)}
                        onBlur={(_) => onBlurTrim(field)}
                    />
                )}
                errors={errors}
            />
            <ControlledRow
                name="personalInfo.lastName"
                control={control}
                render={({field}) => (
                    <Form.Control
                        {...field}
                        data-cy="last-name-input"
                        type="text"
                        placeholder={formatPlaceholder(intl, field.name)}
                        onBlur={(_) => onBlurTrim(field)}
                    />
                )}
                errors={errors}
            />
            <ControlledRow
                name="personalInfo.phoneNumber"
                control={control}
                render={({field}) => (
                    <Form.Control {...field} data-cy="phone-number-input" type="text" onBlur={(_) => onBlurTrim(field)} />
                )}
                errors={errors}
            />

            <ControlledRow
                name="personalInfo.workingCity"
                labelId="personalInfo.workingCityLabel"
                control={control}
                render={({field}) =>
                    cities && cities.length > 0 ? (
                        <div className="align-middle">
                            <Form.Select {...field} data-cy="working-city-select">
                                <option value="" />
                                {cities
                                    .sort((city1, city2) => (city1.id > city2.id ? 1 : -1))
                                    .map((city) => (
                                        <option key={city.id} value={city.id}>
                                            {city.label}
                                        </option>
                                    ))}
                            </Form.Select>
                        </div>
                    ) : (
                        <div>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    )
                }
                errors={errors}
            />

            <ControlledRow
                name="personalInfo.driverGroup"
                labelId="personalInfo.driverGroupLabel"
                control={control}
                render={({field}) => (
                    <Form.Select {...field} data-cy="driver-group-select">
                        <option value="UNIVERSAL">
                            {intl.formatMessage({id: "driverGroupAll"})}
                        </option>
                        <option value="TAXI">{intl.formatMessage({id: "driverGroupTaxi"})}</option>
                        <option value="CARGO">
                            {intl.formatMessage({id: "driverGroupDelivery"})}
                        </option>
                    </Form.Select>
                )}
                errors={errors}
            />
            {selectedDriverGroup !== "CARGO" && (
                <ControlledRow
                    displayLabel={false}
                    name="personalInfo.taximeter"
                    control={control}
                    render={({field}) => (
                        <>
                            <Form.Check
                            data-cy="taximeter-checkbox"
                                {...field}
                                checked={field.value}
                                label={
                                    <span style={{fontSize: "small"}}>
                                        <FormattedMessage id="personalInfo.taximeter" />
                                    </span>
                                }
                            />
                            {!field.value && (
                                <Form.Text>
                                    <FormattedMessage id="personalInfo.noTaximeterDescription" />
                                </Form.Text>
                            )}
                        </>
                    )}
                    errors={errors}
                />
            )}
        </SectionCard>
    );
}

const GdprText = styled.span`
    font-size: var(--font-size-small);
    color: var(--bs-secondary);
`;
