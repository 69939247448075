import {ReactElement} from "react";
import {Control, FieldErrors} from "react-hook-form";
import {LoginResponse, RegistrationRequestV2} from "../generated-api/registerApi";

export type LoginType = LoginResponse["type"] | "REACTIVATION";

export interface Token extends Omit<LoginResponse, "type"> {
    type: LoginType;
}

export enum PageName {
    REGISTER_LOGIN = "register",
    PERSONAL_INFO = "registration/personal-info",
    REGISTRATION_SECTIONS = "registration/sections",
    REGISTRATION_DOCUMENTS = "registration/documents",
    REGISTRATION_CAR = "registration/car",
    REGISTRATION_TUTORIAL = "registration/tutorial",
    REGISTRATION_TEST = "registration/test",
    REGISTRATION_COMPLETE = "registration/complete",
    REGISTRATION_CLOSED = "registration/closed",
    ERROR = "error",
    REACTIVATION_SECTIONS = "reactivation/sections",
    REACTIVATION_DOCUMENTS = "reactivation/documents",
    REACTIVATION_CAR = "reactivation/car",
    REACTIVATION_TUTORIAL = "reactivation/tutorial",
    REACTIVATION_TEST = "reactivation/test",
}

export enum SectionType {
    DOCUMENTS = "documents",
    CAR = "car",
    TUTORIAL = "tutorial",
    TEST = "test",
}
export interface PageNavigation {
    element: () => ReactElement;
    nextPage?: PageName;
    prevPage?: PageName;
}

export interface SubFormProps {
    control: Control<any>;
    errors: FieldErrors;
    onBlurTrim?: (field: any) => void;
}

export interface FormProps {
    loginType?: LoginType;
}

export interface RegistrationData extends RegistrationRequestV2 {
    passwordAgain: string;
}

export enum SectionResult {
    NOT_COMPLETED,
    COMPLETED_OK,
    COMPLETED_FAILED,
}
