import {FormattedMessage, useIntl} from "react-intl";
import {LoginType} from "./model";

export interface SectionsHeaderProps {
    loginType: LoginType;
    allSectionsCompleted: boolean;
}

export function SectionsHeader({loginType, allSectionsCompleted}: SectionsHeaderProps) {
    if (allSectionsCompleted) {
        return <SectionHeaderComplete loginType={loginType} />;
    } else {
        return <SectionHeaderInProgress loginType={loginType} />;
    }
}

function SectionHeaderComplete({loginType}: {loginType: LoginType}) {
    const intl = useIntl();
    const headerForRegistrationComplete = () => (
        <>
            <h4>
                <FormattedMessage
                    id="successHeader"
                    values={{type: intl.formatMessage({id: "registrationCase4"}).toLowerCase()}}
                />
            </h4>
            <span>
                <FormattedMessage
                    id="successBody"
                    values={{type: intl.formatMessage({id: "registration"}).toLowerCase()}}
                />
            </span>
        </>
    );
    const headerForReactivationComplete = () => (
        <>
            <h4>
                <FormattedMessage
                    id="successHeader"
                    values={{type: intl.formatMessage({id: "request"}).toLowerCase()}}
                />
            </h4>
            <span>
                <FormattedMessage
                    id="successBody"
                    values={{type: intl.formatMessage({id: "request"}).toLowerCase()}}
                />
            </span>
        </>
    );
    switch (loginType) {
        case "REGISTRATION":
            return headerForRegistrationComplete();
        case "REACTIVATION":
            return headerForReactivationComplete();
        default:
            return null;
    }
}

function SectionHeaderInProgress({loginType}: {loginType: LoginType}) {
    const headerForRegistrationInProgress = () => (
        <span>
            <FormattedMessage id="registrationSectionsHeader" />{" "}
            <strong>
                <FormattedMessage id="registrationSectionsTimeLimit"></FormattedMessage>
            </strong>
            . <FormattedMessage id="registrationSectionsHeaderAddon" />:
        </span>
    );
    const headerForReactivationInProgress = () => (
        <>
            <h4>
                <FormattedMessage id="reactivationSectionsHeader" />
            </h4>
            <span>
                <FormattedMessage id="reactivationSectionsText" />
            </span>
        </>
    );

    switch (loginType) {
        case "REGISTRATION":
            return headerForRegistrationInProgress();
        case "REACTIVATION":
            return headerForReactivationInProgress();
        default:
            return null;
    }
}
