import {useMutation, useQuery, useQueryClient, UseQueryResult} from "@tanstack/react-query";
import {isEqual} from "lodash";
import {NavigateFunction} from "react-router-dom";
import {
    Api,
    City,
    CompanyInformation,
    DriverInformation,
    DriverReactivationState,
    RegistrationRequestV2,
} from "../generated-api/registerApi";
import {maxRetries, reactivationTokenCookieKey, regTokenCookieKey} from "./constants";
import {personalInfoInitValues} from "./formsSchema";
import {
    driverInfoToReactivationState,
    getCookie,
    navigateTo,
    reactivationStateToDriverInfo,
} from "./functions";
import {isWebView} from "./isWebView";
import {LoginType, PageName, SectionType, Token} from "./model";

declare const appConfig: {baseApiUrl: string};

const tokenKey = "accessTokenKey";
const stateQueryKey = "driverStatus";
const reactivationSectionsKey = "reactivationSections";

export const initialData: DriverInformation = {
    trainingCompleted: false,
    personalInfo: personalInfoInitValues,
};

const httpClient = () => new Api({baseURL: appConfig.baseApiUrl});

let apiClient: Api<any>["drv"] | null = null;

export function getApiClient(): Api<any>["drv"] {
    apiClient = apiClient || httpClient().drv;
    return apiClient;
}

const loadDriverStateQuery = (loginType: LoginType) => ({
    queryKey: [stateQueryKey, loginType],
    queryFn: async () => {
        const tokenType = getTokenType();
        try {
            switch (tokenType) {
                case "REGISTRATION": {
                    const {data} = await getApiClient().getRegistrationState(headers());
                    return data;
                }
                case "REACTIVATION": {
                    const {data} = await getApiClient().getState(headers());
                    return reactivationStateToDriverInfo(data);
                }
                default:
                    return initialData;
            }
        } catch (ex: any) {
            if (ex.response?.status === 403 || ex.response?.status === 401) {
                deleteToken();
                return initialData;
            } else {
                throw ex;
            }
        }
    },
    initialData: initialData,
    enabled: false,
    retry: retryOnRecoverableError,
});

function retryOnRecoverableError(failureCount: number, error: any) {
    if (error.response?.status === 409) {
        return false;
    } else {
        return failureCount <= maxRetries;
    }
}

function headers() {
    const token = readToken()?.accessToken;
    const headerContentType = {
        "Content-Type": "application/json",
        Accept: "application/json",
    };
    if (token) {
        return {secure: true, headers: {...headerContentType, Authorization: `Bearer ${token}`}};
    } else {
        return {headers: headerContentType};
    }
}

export function useCities(): UseQueryResult<City[]> {
    return useQuery(
        ["cities"],
        async () => {
            const response = await getApiClient().getCities(headers());
            return response.data;
        },
        {
            refetchOnWindowFocus: false,
        }
    );
}

export function useUpdateDriverState(
    loginType: LoginType = "REGISTRATION",
    navigate?: NavigateFunction
) {
    const queryClient = useQueryClient();
    const {refetch} = useLoadDriverState(loginType);
    return useMutation({
        mutationFn: async (data: DriverInformation) => {
            queryClient.setQueryData([stateQueryKey, loginType], data);
            const response = await updateDriveState(loginType, data);
            return response.data;
        },
        onSuccess: (data) => queryClient.setQueryData([stateQueryKey, loginType], data),
        onError: (error: any) => {
            refetch();
            if (
                loginType === "REACTIVATION" &&
                error?.response?.status === 409 &&
                navigate !== undefined
            ) {
                navigateTo(navigate, PageName.REACTIVATION_SECTIONS);
            }
        },
        retry: retryOnRecoverableError,
    });
}

async function updateDriveState(loginType: LoginType, data: DriverInformation) {
    switch (loginType) {
        case "REGISTRATION":
            return await getApiClient().putRegistrationState(data, headers());
        case "REACTIVATION": {
            const reactivationData: DriverReactivationState = driverInfoToReactivationState(data);
            const {data: updatedData} = await getApiClient().putState(reactivationData, headers());
            return {data: reactivationStateToDriverInfo(updatedData)};
        }
        default:
            return {data: data};
    }
}

export async function lookupCompany(companyId: string): Promise<CompanyInformation | undefined> {
    return await getApiClient()
        .lookupCompany({companyId: companyId}, headers())
        .then((value) => value.data)
        .catch(() => undefined);
}

export function useLoadDriverState(loginType: LoginType = "REGISTRATION") {
    const driverStateQuery = loadDriverStateQuery(loginType);
    const query = useQuery(driverStateQuery);
    if (isEqual(query.data, initialData) && getTokenType() && !query.isFetching && !query.isError) {
        query.refetch();
    }
    return {
        refetch: query.refetch,
        error: query.error,
        isError: query.isError,
        isFetching: query.isFetching,
        driverState: query.data,
    };
}

export async function isPhoneNumberValid(phoneNumber: string): Promise<boolean> {
    return getApiClient()
        .phoneNumberValidation({phoneNumber: phoneNumber}, headers())
        .then(({data}) => data.valid);
}

export async function register(params: RegistrationRequestV2): Promise<LoginType> {
    const {data} = await getApiClient().registerV2(params);
    storeToken(data);
    return data.type;
}

export async function finishRegistrationOrReactivation(
    loginType: LoginType = "REGISTRATION"
): Promise<void> {
    switch (loginType) {
        case "REGISTRATION":
            return await finishRegistration();
        case "REACTIVATION":
            return await finishReactivation();
        default:
            return;
    }
}

async function finishReactivation() {
    await getApiClient().finish(headers());
    notifyAndroidReactivationDone();
    clearStorage();
}

async function finishRegistration() {
    const {data} = await getApiClient().finishRegistration(headers());
    notifyAndroidRegistrationDone(data);
    clearStorage();
}

export async function isPlateValid(licensePlate: string): Promise<boolean> {
    return getApiClient()
        .validateLicensePlate({licensePlate: licensePlate}, headers())
        .then(({data}) => data.valid);
}

function storeToken(object: Token) {
    clearStorage();
    const obj = JSON.stringify(object);
    localStorage.setItem(tokenKey, obj);
}

export function deleteToken() {
    localStorage.removeItem(tokenKey);
}

export function parseAndSaveTokenFromCookie() {
    if (isWebView()) {
        const registrationTokenPassed = parseAndSaveToken("REGISTRATION");
        if (!registrationTokenPassed) {
            parseAndSaveToken("REACTIVATION");
        }
    }
}

function parseAndSaveToken(type: LoginType): boolean {
    const key = cookieKeyForType(type);
    const cookie = getCookie(key);
    if (cookie) {
        // else we get 401
        storeTokenOfType(type, encodeURIComponent(cookie));
        return true;
    } else {
        return false;
    }
}

function cookieKeyForType(type: LoginType): string {
    switch (type) {
        case "REGISTRATION":
            return regTokenCookieKey;
        case "REACTIVATION":
            return reactivationTokenCookieKey;
        default:
            return "";
    }
}

function storeTokenOfType(type: LoginType, token: string) {
    const resp: Token = {
        accessToken: token,
        type: type,
        userId: "",
    };
    storeToken(resp);
}

function readToken(): Token | null {
    const objString = localStorage.getItem(tokenKey);
    if (objString) {
        const parsedObject: Token = JSON.parse(objString);
        return parsedObject;
    } else {
        return null;
    }
}

export function getTokenType(): LoginType | null {
    const token = readToken();
    if (token) {
        return token.type;
    } else {
        return null;
    }
}

export function notifyAndroidRegistrationDone(login?: Token) {
    const token = login ?? readToken();
    if (token && isWebView()) {
        if (token.type === "REGISTRATION") {
            window.AndroidV2.registrationTokenReceived(token.userId, token.accessToken);
        } else {
            window.AndroidV2.userTokenReceived(token.userId, token.accessToken);
        }
    }
}

function notifyAndroidReactivationDone() {
    if (isWebView()) {
        window.AndroidReactivationV1.reactivationDone("OK");
    }
}

export function completeReactivationSection(sectionType: SectionType) {
    const sections = readCompletedSections();
    if (!sections.includes(sectionType)) {
        writeCompletedSections([...sections, sectionType]);
    }
}

export function reactivationSectionCompleted(sectionType: SectionType) {
    const sections = readCompletedSections();
    return sections.includes(sectionType);
}

function writeCompletedSections(sections: SectionType[]) {
    localStorage.setItem(reactivationSectionsKey, JSON.stringify(sections));
}

function readCompletedSections(): SectionType[] {
    const result = localStorage.getItem(reactivationSectionsKey) ?? "";
    if (result) {
        return JSON.parse(result);
    } else {
        return [];
    }
}

export function clearStorage() {
    localStorage.clear();
}
