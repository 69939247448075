import {webViewKey} from "./constants";

export function isWebView(): boolean {
    // @ts-ignore: Property 'standalone' does not exist on type 'Navigator'.
    const standalone = window.navigator.standalone;
    const userAgent = window.navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent); //unfortunately new ipad has 'macintosh' user agent

    if (ios) {
        if (!standalone && safari) {
            // Safari
            return false;
        } else if (!standalone && !safari) {
            // iOS webview
            return true;
        }
    } else {
        if (userAgent.includes(webViewKey)) {
            // Android webview
            return true;
        } else {
            // Chrome
            return false;
        }
    }
    return false;
}
