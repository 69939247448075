import {useEffect, useRef, useState} from "react";
import {Button, Card, Col, Modal, Row} from "react-bootstrap";
import {Loader} from "react-bootstrap-typeahead";
import {ControllerRenderProps} from "react-hook-form";
import ReactCrop, {Crop, PixelCrop, centerCrop, makeAspectCrop} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {FormattedMessage} from "react-intl";

const runningTestEnv = process.env.NODE_ENV === "test";

export function ImageInput({field}: {field: ControllerRenderProps}) {
    const [resizing, setResizing] = useState(false);
    const [existingImage, setExistingImage] = useState(field.value || readImage(field.name));

    // if the image is stored in the local storage but not on BE
    useEffect(() => {
        if (!field.value && existingImage) {
            field.onChange(existingImage);
        }
    }, [existingImage]);

    // don't allow being stuck "resizing" forever
    useEffect(() => {
        if (resizing) {
            setTimeout(() => {
                setResizing(false);
            }, 30000);
        }
    }, [resizing]);

    if (resizing) {
        return (
            <Card>
                <Card.Body className="text-muted">
                    <FormattedMessage id="pleaseWait" />
                </Card.Body>
            </Card>
        );
    }

    if (!existingImage) {
        return (
            <>
                <div className="input-group custom-file-button">
                    <label
                        className="input-group-text"
                        htmlFor={field.name}
                        style={{
                            backgroundColor: "var(--bs-light-blue)",
                            color: "var(--bs-secondary)",
                        }}
                    >
                        <FormattedMessage id="chooseFile" />
                    </label>
                    <input
                        type="file"
                        className="form-control"
                        id={field.name}
                        accept="image/*"
                        onChange={(e) => {
                            const files = e.target.files;
                            setResizing(true);
                            const url = URL.createObjectURL(files!![0]);
                            const callback = (imageResult: string) => {
                                setResizing(false);
                                storeImage(field.name, imageResult);
                                field.onChange(imageResult);
                                setExistingImage(imageResult);
                            };
                            doResizing(field.name, url, callback);
                        }}
                    />
                </div>
            </>
        );
    } else {
        return (
            <Card>
                <Card.Body style={{display: "flex", alignItems: "center"}}>
                    <img
                        src={toSrcWithMetadata(existingImage)}
                        width={100}
                        alt=""
                        className="me-3"
                    />
                    <Button
                        onClick={() => {
                            field.onChange("");
                            removeImage(field.name);
                            setExistingImage("");
                        }}
                        variant="danger"
                    >
                        <FormattedMessage id="remove" />
                    </Button>
                </Card.Body>
            </Card>
        );
    }
}

export function ImageInputWithCrop({field}: {field: ControllerRenderProps}) {
    const [existingImage, setExistingImage] = useState(field.value || readImage(field.name));
    const [showModal, setShowModal] = useState(false);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [imgSrc, setImgSrc] = useState("");
    const [croppedImage, setCroppedImage] = useState("");
    const [selectedFileName, setSelectedFileName] = useState("");
    const imageRef = useRef<HTMLImageElement | null>(null);

    const handleShowModal = () => setShowModal(true);

    function handleCancelRemoveImage() {
        field.onChange("");
        removeImage(field.name);
        setExistingImage("");
        handleCommonClose();
    }

    function handleCloseModalOk() {
        storeImage(field.name, croppedImage);
        field.onChange(croppedImage);
        setExistingImage(croppedImage);
        handleCommonClose();
    }

    function handleCommonClose() {
        setCrop(undefined);
        setCompletedCrop(undefined);
        setImgSrc("");
        setCroppedImage("");
        setShowModal(false);
        setSelectedFileName("");
    }

    // if the image is stored in the local storage but not on BE
    useEffect(() => {
        if (!field.value && existingImage) {
            field.onChange(existingImage);
        }
    }, [existingImage]);

    useEffect(() => {
        if (completedCrop?.height && completedCrop?.width && imageRef.current) {
            const url = cropImage(imageRef.current, completedCrop);
            setCroppedImage(url);
        }
    }, [completedCrop]);

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        const files = e.target.files;
        if (files && files.length > 0) {
            setSelectedFileName(files[0].name);
            const url = URL.createObjectURL(files[0]);
            const callback = (imageResult: string) => {
                setImgSrc(toSrcWithMetadata(imageResult));
                handleShowModal();
            };
            doResizing(field.name, url, callback);
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        const {width, height} = e.currentTarget;
        setCrop(centerAspectCrop(width, height));
    }

    if (!existingImage) {
        return (
            <>
                <div className="input-group custom-file-button">
                    <label
                        className="input-group-text"
                        htmlFor={field.name}
                        style={{
                            backgroundColor: "var(--bs-light-blue)",
                            color: "var(--bs-secondary)",
                        }}
                    >
                        <FormattedMessage id="chooseFile" />
                    </label>
                    <input
                        data-cy="file-upload-input"
                        type="file"
                        key={selectedFileName}
                        className="form-control"
                        id={field.name}
                        data-testid="image-crop-test"
                        accept="image/*"
                        onChange={onSelectFile}
                    />
                </div>
                <Modal
                    show={showModal}
                    onHide={handleCancelRemoveImage}
                    dialogClassName="modal-90w"
                >
                    <Modal.Header closeButton>
                        <Modal.Title style={{fontSize: "20px", fontWeight: "500"}}>
                            <FormattedMessage id="cropImage" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{textAlign: "center"}}>
                            {imgSrc ? (
                                <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    onComplete={(c) => {
                                        setCompletedCrop(c);
                                    }}
                                    aspect={1}
                                    minHeight={200}
                                    circularCrop
                                    locked
                                >
                                    <img
                                        id={`image_${field.name}`}
                                        alt="To crop"
                                        ref={imageRef}
                                        src={imgSrc}
                                        style={{transform: `scale(${1}) rotate(${0}deg)`}}
                                        onLoad={onImageLoad}
                                    />
                                </ReactCrop>
                            ) : (
                                <Loader />
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row style={{width: "100%"}}>
                            <Col style={{textAlign: "left"}}>
                                <Button
                                    style={{
                                        width: "100%",
                                        color: "var(--bs-primary)",
                                        fontSize: "var(--font-size-text)",
                                        fontWeight: 500,
                                    }}
                                    size="lg"
                                    onClick={handleCancelRemoveImage}
                                    variant="light"
                                >
                                    <FormattedMessage id="back" />
                                </Button>
                            </Col>
                            <Col style={{textAlign: "right"}}>
                                <Button
                                    onClick={handleCloseModalOk}
                                    disabled={!croppedImage && !runningTestEnv}
                                    variant="primary"
                                    size="lg"
                                    style={{
                                        width: "100%",
                                        fontSize: "var(--font-size-text)",
                                        fontWeight: 500,
                                    }}
                                >
                                    <FormattedMessage id="ok" />
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
            </>
        );
    } else {
        return (
            <Card>
                <Card.Body style={{display: "flex", alignItems: "center"}}>
                    <img
                        src={toSrcWithMetadata(existingImage)}
                        width={100}
                        height={100}
                        alt=""
                        className="me-3"
                        style={{borderRadius: "50%", objectFit: "cover"}}
                    />
                    <Button onClick={handleCancelRemoveImage} variant="danger">
                        <FormattedMessage id="remove" />
                    </Button>
                </Card.Body>
            </Card>
        );
    }
}

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number = 1) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: "%",
                width: 100,
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
}

function createCanvas(canvasId: string = "fileupload-resize-area"): HTMLCanvasElement {
    let canvasEl = document.getElementById(canvasId) as HTMLCanvasElement;
    if (!canvasEl) {
        canvasEl = document.createElement("canvas");
        canvasEl.id = canvasId;
        canvasEl.style.display = "none";
        document.body.appendChild(canvasEl);
    }
    return canvasEl;
}

function resizeImage(origImage: HTMLImageElement | HTMLCanvasElement): string {
    const maxHeight = 1000;
    const maxWidth = 1000;

    const canvas = createCanvas(`canvas_${origImage.id}`);

    let height = origImage.height;
    let width = origImage.width;

    // calculate the width and height, constraining the proportions
    if (width > height) {
        if (width > maxWidth) {
            height = Math.round((height *= maxWidth / width));
            width = maxWidth;
        }
    } else {
        if (height > maxHeight) {
            width = Math.round((width *= maxHeight / height));
            height = maxHeight;
        }
    }
    canvas.width = width;
    canvas.height = height;
    // draw image on canvas
    const ctx = canvas.getContext("2d");
    ctx?.drawImage(origImage, 0, 0, width, height);
    return toDataUrlWithoutMetaData(canvas);
}

function cropImage(origImage: HTMLImageElement, crop: PixelCrop): string {
    const canvas = createCanvas("crop-image");

    const scaleX = origImage.naturalWidth / origImage.width;
    const scaleY = origImage.naturalHeight / origImage.height;

    canvas.width = Math.floor(crop.width * scaleX);
    canvas.height = Math.floor(crop.height * scaleY);

    const ctx = canvas.getContext("2d");
    if (!ctx) {
        console.warn("Could not create canvas for image crop");
        return "";
    }

    ctx.imageSmoothingQuality = "high";

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const centerX = origImage.naturalWidth / 2;
    const centerY = origImage.naturalHeight / 2;

    ctx.save();
    // 3) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);
    // 2) Move the origin to the center of the original position
    ctx.translate(centerX, centerY);
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(origImage, 0, 0, origImage.naturalWidth, origImage.naturalHeight);
    return toDataUrlWithoutMetaData(canvas);
}

function createImage(fieldName: string, url: string, callback: (image: HTMLImageElement) => void) {
    const image = new Image();
    image.onload = function () {
        callback(image);
    };
    image.src = url;
    image.id = `hidden_img_${fieldName}`;
    if (runningTestEnv) {
        image.hidden = true;
        image.alt = image.id;
        document.body.appendChild(image);
    }
}

function toDataUrlWithoutMetaData(canvas: HTMLCanvasElement, quality: number = 0.7): string {
    const type = "image/jpeg";
    const url = canvas.toDataURL(type, quality);
    return url.replace(/^data:image\/[a-z]+;base64,/, "");
}

function toSrcWithMetadata(src: string): string {
    return `data:image/jpeg;base64, ${src}`;
}

function doResizing(fieldName: string, url: string, callback: (result: string) => void) {
    createImage(fieldName, url, (image: HTMLImageElement) => {
        const dataURL = resizeImage(image);
        callback(dataURL);
    });
}

function storeImage(key: string, object: string) {
    localStorage.setItem(key, object);
}

function readImage(key: string): string | null {
    return localStorage.getItem(key);
}

function removeImage(key: string) {
    localStorage.removeItem(key);
}

// exposed for tests only
export const testResizeImage: (origImage: HTMLImageElement) => string = runningTestEnv
    ? resizeImage
    : (origImage: HTMLImageElement) => "";
export const testCreateCanvas: () => HTMLCanvasElement = runningTestEnv
    ? createCanvas
    : () => document.createElement("canvas");
export const testCropImage: (origImage: HTMLImageElement, crop: PixelCrop) => string =
    runningTestEnv ? cropImage : (origImage: HTMLImageElement, crop: PixelCrop) => "";
