import {ReactElement, useEffect, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {BsCheckLg, BsCircle, BsXLg} from "react-icons/bs";
import {FormattedMessage, FormattedPlural, useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {LoaderSpinner} from "../App";
import {
    DriverInformation,
    DriverPersonalInformation,
    DriverTest,
} from "../generated-api/registerApi";
import {ReactivationErrorModal} from "./ReactivationErrorModal";
import {RegistrationPage} from "./RegistrationPage";
import {SectionsHeader} from "./SectionsHeader";
import {finishRegistrationOrReactivation, useLoadDriverState} from "./apiClientProvider";
import {
    completedSections,
    infoLinkIdForRideType,
    navigateTo,
    navigateToWithProps,
    orderNumTextForSection,
    pageNameForSection,
    remainingTestAttempts,
    sectionResult,
    testCompleted,
    useReactivationUnrecoverableErrorText,
} from "./functions";
import {isWebView} from "./isWebView";
import {FormProps, LoginType, PageName, SectionResult, SectionType} from "./model";

function iconForSectionResult(sectionResult: SectionResult): ReactElement {
    switch (sectionResult) {
        case SectionResult.NOT_COMPLETED:
            return <BsCircle color="var(--bs-light-grey)" size="24" />;
        case SectionResult.COMPLETED_OK:
            return <BsCheckLg color="var(--bs-primary)" size="24" data-testid="icon-success" data-cy="success-section-icon" />;
        case SectionResult.COMPLETED_FAILED:
            return <BsXLg color="var(--bs-danger)" size="24" data-testid="icon-failed" />;
        default:
            throw Error("Unknown section result: " + sectionResult);
    }
}

function isRegistrationClosed(info?: DriverPersonalInformation) {
    return (
        ["bratislava"].includes(info?.workingCity?.toLowerCase() ?? "") &&
        ["TAXI", "UNIVERSAL"].includes(info?.driverGroup ?? "")
    );
}

export function Sections({loginType = "REGISTRATION"}: FormProps) {
    const intl = useIntl();
    const navigate = useNavigate();

    const [completeRegistrationTriggered, setCompleteRegistrationTriggered] = useState(false);
    const {driverState, isFetching, isError, error} = useLoadDriverState(loginType);

    const nextButtonTextSupplier = () => {
        if (completeRegistrationTriggered) {
            return "sending";
        }
        switch (loginType) {
            case "REGISTRATION":
                return "finishRegistration";
            case "REACTIVATION":
                return "finishRequest";
            default:
                return "sending";
        }
    };

    // automatic redirect to personal info if we're in registration but data is missing (like last name...)
    useEffect(() => {
        if (!isFetching && loginType === "REGISTRATION") {
            if (!driverState.personalInfo?.lastName) {
                goToPage(PageName.PERSONAL_INFO);
            } else if (isRegistrationClosed(driverState.personalInfo)) {
                goToPage(PageName.REGISTRATION_CLOSED);
            }
        }
    }, [driverState.personalInfo, isFetching, loginType]);

    const completedSectionsList = completedSections(loginType, driverState);
    const testScoreAvailable = driverState.test?.score ?? driverState.test?.previousScore;

    const allSectionsCompleted =
        completedSectionsList.includes(SectionType.DOCUMENTS) &&
        completedSectionsList.includes(SectionType.CAR) &&
        completedSectionsList.includes(SectionType.TUTORIAL) &&
        completedSectionsList.includes(SectionType.TEST);

    function goToPage(pageName: PageName) {
        return navigateTo(navigate, pageName);
    }

    function callFinishRegistration() {
        finishRegistrationOrReactivation(loginType)
            .then((_) => {
                if (!isWebView() && loginType === "REGISTRATION") {
                    navigateToWithProps(navigate, PageName.REGISTRATION_COMPLETE, {
                        workingCity: driverState.personalInfo?.workingCity || "unknown",
                        driverGroup: driverState.personalInfo?.driverGroup || "TAXI",
                    });
                }
            })
            .catch((_: any) => navigateTo(navigate, PageName.ERROR))
            .finally(() => setCompleteRegistrationTriggered(false));
    }

    async function onSubmit(event: any) {
        event.preventDefault();
        setCompleteRegistrationTriggered(true);
        callFinishRegistration();
    }

    const errorMessage = useReactivationUnrecoverableErrorText(loginType, error);
    const sectionDisabled = allSectionsCompleted || isFetching || isError;
    
    const sections = () => (
        <>
            <div>
                <SectionsHeader allSectionsCompleted={allSectionsCompleted} loginType={loginType} />
            </div>
            <Section
                driverState={driverState}
                sectionType={SectionType.DOCUMENTS}
                loginType={loginType}
                goToPage={goToPage}
                sectionDisabled={sectionDisabled}
            />
            <Section
                driverState={driverState}
                sectionType={SectionType.CAR}
                loginType={loginType}
                goToPage={goToPage}
                sectionDisabled={sectionDisabled}
            />
            <Section
                driverState={driverState}
                sectionType={SectionType.TUTORIAL}
                loginType={loginType}
                goToPage={goToPage}
                sectionDisabled={sectionDisabled}
            />
            <Section
                driverState={driverState}
                sectionType={SectionType.TEST}
                loginType={loginType}
                goToPage={goToPage}
                sectionDisabled={
                    !completedSectionsList.includes(SectionType.TUTORIAL) ||
                    sectionDisabled ||
                    testCompleted(driverState.test)
                }
            />
            <div style={{paddingTop: "var(--spacing-medium)"}}>
                <a
                    style={{
                        fontWeight: "normal",
                        color: "var(--bs-primary)",
                        textDecoration: "none",
                    }}
                    href={intl.formatMessage({
                        id: infoLinkIdForRideType(
                            driverState?.personalInfo?.driverGroup ?? "UNIVERSAL"
                        ),
                    })}
                    target="_blank"
                    rel="noreferrer"
                >
                    <FormattedMessage id="driverTermsInfo" />
                </a>
            </div>
            {isFetching && <LoaderSpinner />}
            {isError && errorMessage && (
                <ReactivationErrorModal errorMessageId={errorMessage} showModal={isError} />
            )}
        </>
    );

    return (
        <RegistrationPage
            onSubmitClick={onSubmit}
            headerTextId={loginType.toLowerCase()}
            children={sections}
            hasNextPage={allSectionsCompleted && testScoreAvailable && !isFetching && !isError}
            nextButtonTextId={nextButtonTextSupplier()}
            nextPageDisabled={() => completeRegistrationTriggered || isFetching || isError}
        />
    );
}

interface SectionProps {
    driverState: DriverInformation;
    sectionType: SectionType;
    loginType: LoginType;
    goToPage: (page: PageName) => void;
    sectionDisabled: boolean;
}

function Section({driverState, sectionType, loginType, goToPage, sectionDisabled}: SectionProps) {
    const page = pageNameForSection(loginType, sectionType);

    const answersSection = () =>
        sectionType === SectionType.TEST &&
        (driverState.test?.score || driverState.test?.previousScore) && (
            <span
                style={{
                    fontSize: "small",
                    color: "var(--bs-secondary)",
                }}
            >
                {testScoreDisplay(driverState.test)}
            </span>
        );

    const orderNum = orderNumTextForSection(sectionType);
    const remainingAttempts = remainingTestAttempts(driverState.test);
    return (
        <div style={{paddingTop: "var(--spacing-small)"}}>
            <Button
                variant="white"
                style={{
                    width: "100%",
                    borderColor: "var(--bs-light-grey)",
                    minHeight: "var(--navigation-height)",
                    backgroundColor: sectionDisabled ? "var(--bs-light)" : "",
                }}
                onClick={() => goToPage(page)}
                disabled={sectionDisabled}
            >
                <Row style={{display: "flex", alignItems: "center"}}>
                    <Col style={{textAlign: "left"}}>
                        <span>
                            {orderNum}. <FormattedMessage id={sectionType} />
                        </span>
                        {sectionType === SectionType.TEST && !testCompleted(driverState.test) && (
                            <div
                                style={{
                                    paddingLeft: "var(--spacing-medium)",
                                    fontStyle: "italic",
                                    fontSize: "var(--font-size-small)",
                                    fontWeight: "var(--font-weight-thin)",
                                    color: "var(--bs-secondary)",
                                }}
                            >
                                <FormattedPlural
                                    value={remainingAttempts}
                                    one={
                                        <>
                                            <FormattedMessage id="testAttemptRemainsOne" /> 1{" "}
                                            <FormattedMessage id="attemptOne" />
                                        </>
                                    }
                                    other={
                                        <>
                                            <FormattedMessage id="testAttemptRemainsMany" />
                                            {` `}
                                            {remainingAttempts}
                                            {` `}
                                            <FormattedMessage id="attemptsMany" />
                                        </>
                                    }
                                />
                            </div>
                        )}
                    </Col>
                    <Col style={{textAlign: "right"}}>
                        {answersSection()}{" "}
                        {iconForSectionResult(sectionResult(loginType, sectionType, driverState))}
                    </Col>
                </Row>
            </Button>
        </div>
    );
}

function testScoreDisplay(test: DriverTest): string {
    if (test.score) {
        return `${test.score.correct}/${test.score.count}`;
    } else if (test.previousScore) {
        return `${test.previousScore.correct}/${test.previousScore.count}`;
    } else {
        return "";
    }
}
