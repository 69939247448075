import {isPhoneNumberValid, isPlateValid} from "../app/apiClientProvider";
import {createCache} from "./createCache";

export const getCachedPhoneResult = createCache<boolean>(true);
export const getCachedLicensePlateResult = createCache<boolean>(true);

export function cachedPhoneNumberValidator(phoneNumber?: string | null): Promise<boolean> {
    // phone number is optional
    if (phoneNumber == null) {
        return Promise.resolve(true);
    }

    return getCachedPhoneResult(phoneNumber, () => isPhoneNumberValid(phoneNumber));
}

export function cachedLicensePlateValidator(licensePlate: string): Promise<boolean> {
    return getCachedLicensePlateResult(licensePlate, () => isPlateValid(licensePlate));
}
