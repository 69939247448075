import {IntlShape} from "react-intl";
import {NavigateFunction} from "react-router-dom";
import {pageFlow} from "../App";
import {
    DriverInformation,
    DriverPersonalInformation,
    DriverReactivationState,
    DriverTest,
} from "../generated-api/registerApi";
import {reactivationSectionCompleted} from "./apiClientProvider";
import {maxTestAttempts, tutorialUrlDelivery, tutorialUrlTaxi} from "./constants";
import {carInitValues, documentsInitValues, personalInfoInitValues} from "./formsSchema";
import {LoginType, PageName, SectionResult, SectionType} from "./model";
import {RegistrationCompleteDriverState} from "./RegistrationComplete";

export function getCookie(name: string): string {
    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split("; ");
        const matchingCookie = cookies
            .map((cookie) => cookie.trim())
            .filter((cookie) => cookie.startsWith(`${name}=`))
            .map((cookie) => cookie.replace(`${name}=`, ""))
            .map((parsedValue) => decodeURIComponent(parsedValue))
            .find((_) => true);
        return matchingCookie ?? "";
    }
    return "";
}

export function videoUrls(driverGroup: DriverPersonalInformation["driverGroup"]) {
    if (driverGroup === "CARGO") {
        return tutorialUrlDelivery;
    } else {
        return tutorialUrlTaxi;
    }
}

export function formatPlaceholder(intl: IntlShape, fieldName: string): string {
    const fieldNamePlaceholder = fieldName + "Placeholder";
    if (intl.messages[fieldNamePlaceholder]) {
        return intl.formatMessage({id: fieldNamePlaceholder});
    } else {
        return "";
    }
}

export function navigateTo(navigate: NavigateFunction, page: PageName) {
    return navigate(`/${page}`, {replace: true});
}

export function navigateToWithProps(
    navigate: NavigateFunction,
    page: PageName,
    state: RegistrationCompleteDriverState
) {
    return navigate(`/${page}`, {replace: true, state: state});
}

export function navigateToNext(navigate: NavigateFunction, currentPage: PageName) {
    const {nextPage} = pageFlow[currentPage];
    if (nextPage) {
        return navigateTo(navigate, nextPage);
    }
}

export function navigateToPrev(navigate: NavigateFunction, currentPage: PageName) {
    const {prevPage} = pageFlow[currentPage];
    if (prevPage) {
        return navigateTo(navigate, prevPage);
    }
}

export function infoLinkIdForRideType(
    driverGroup: DriverPersonalInformation["driverGroup"]
): string {
    switch (driverGroup) {
        case "CARGO":
            return "linkInfoDelivery";
        case "TAXI":
            return "linkInfoTaxi";
        case "UNIVERSAL":
            return "linkInfoAll";
        default:
            throw Error("Unknown driver group: " + driverGroup);
    }
}

export function testCompleted(test?: DriverTest): boolean {
    return test?.success === true || remainingTestAttempts(test) === 0;
}

export function remainingTestAttempts(test?: DriverTest): number {
    return test?.remainingAttempts ?? maxTestAttempts;
}

export function pageNameForSection(loginType: LoginType, sectionType: SectionType): PageName {
    switch (loginType) {
        case "REGISTRATION":
            return pageNameForRegistration(sectionType);
        case "REACTIVATION":
            return pageNameForReactivation(sectionType);
        default:
            return PageName.REGISTER_LOGIN;
    }
}

function pageNameForRegistration(sectionType: SectionType): PageName {
    switch (sectionType) {
        case SectionType.DOCUMENTS:
            return PageName.REGISTRATION_DOCUMENTS;
        case SectionType.CAR:
            return PageName.REGISTRATION_CAR;
        case SectionType.TUTORIAL:
            return PageName.REGISTRATION_TUTORIAL;
        case SectionType.TEST:
            return PageName.REGISTRATION_TEST;
    }
}

function pageNameForReactivation(sectionType: SectionType): PageName {
    switch (sectionType) {
        case SectionType.DOCUMENTS:
            return PageName.REACTIVATION_DOCUMENTS;
        case SectionType.CAR:
            return PageName.REACTIVATION_CAR;
        case SectionType.TUTORIAL:
            return PageName.REACTIVATION_TUTORIAL;
        case SectionType.TEST:
            return PageName.REACTIVATION_TEST;
    }
}

export function orderNumTextForSection(sectionType: SectionType): number {
    switch (sectionType) {
        case SectionType.DOCUMENTS:
            return 1;
        case SectionType.CAR:
            return 2;
        case SectionType.TUTORIAL:
            return 3;
        case SectionType.TEST:
            return 4;
        default:
            throw Error("Unexpected section: " + sectionType);
    }
}

export function completedSections(
    loginType: LoginType,
    driverState: DriverInformation
): SectionType[] {
    switch (loginType) {
        case "REGISTRATION":
            return completedSectionsRegistration(driverState);
        case "REACTIVATION":
            return completedSectionsReactivation(driverState);
        default:
            return [];
    }
}

function completedSectionsReactivation(driverState: DriverInformation): SectionType[] {
    const completedSections: SectionType[] = [];
    if (reactivationSectionCompleted(SectionType.DOCUMENTS)) {
        completedSections.push(SectionType.DOCUMENTS);
    }
    if (reactivationSectionCompleted(SectionType.CAR)) {
        completedSections.push(SectionType.CAR);
    }
    if (driverState.trainingCompleted) {
        completedSections.push(SectionType.TUTORIAL);
    }
    if (testCompleted(driverState.test)) {
        completedSections.push(SectionType.TEST);
    }
    return completedSections;
}

function completedSectionsRegistration(driverState: DriverInformation): SectionType[] {
    const completedSections: SectionType[] = [];
    if (
        driverState.documents &&
        driverState.documents.drivingLicense.birthPlace &&
        driverState.documents.drivingLicense.birthDate &&
        driverState.documents.company
    ) {
        completedSections.push(SectionType.DOCUMENTS);
    }
    if (driverState.car) {
        completedSections.push(SectionType.CAR);
    }
    if (driverState.trainingCompleted) {
        completedSections.push(SectionType.TUTORIAL);
    }
    if (testCompleted(driverState.test)) {
        completedSections.push(SectionType.TEST);
    }
    return completedSections;
}

export function reactivationStateToDriverInfo(state: DriverReactivationState): DriverInformation {
    return {
        ...state,
        personalInfo: {...personalInfoInitValues, driverGroup: state.personalInfo.driverGroup},
    };
}

export function driverInfoToReactivationState(
    driverInfo: DriverInformation
): DriverReactivationState {
    return {
        documents: driverInfo.documents ?? documentsInitValues,
        car: driverInfo.car ?? carInitValues,
        test: driverInfo.test!,
        trainingCompleted: driverInfo.trainingCompleted ?? false,
        personalInfo: {driverGroup: driverInfo.personalInfo!.driverGroup},
    };
}

export function useReactivationUnrecoverableErrorText(loginType: LoginType, error?: any): string {
    if (loginType === "REACTIVATION" && error?.response?.status === 409) {
        const errorCode: string = error?.response?.data?.code ?? "";
        switch (errorCode.toUpperCase()) {
            case "DRIVER_REACTIVATION_ALREADY_FINISHED":
                return "reactivationAlreadyCompleted";
            case "DRIVER_REACTIVATION_NOT_POSSIBLE":
            default:
                return "reactivationError";
        }
    }
    return "";
}

export function sectionResult(
    loginType: LoginType,
    sectionType: SectionType,
    driverState: DriverInformation
): SectionResult {
    const completedSectionsList = completedSections(loginType, driverState);
    switch (sectionType) {
        case SectionType.DOCUMENTS:
        case SectionType.CAR:
        case SectionType.TUTORIAL:
            return completedSectionsList.includes(sectionType)
                ? SectionResult.COMPLETED_OK
                : SectionResult.NOT_COMPLETED;
        case SectionType.TEST:
            if (testCompleted(driverState.test)) {
                return driverState.test!.success
                    ? SectionResult.COMPLETED_OK
                    : SectionResult.COMPLETED_FAILED;
            } else if (!testCompleted(driverState.test) && driverState.test?.success === false) {
                return SectionResult.COMPLETED_FAILED;
            } else {
                return SectionResult.NOT_COMPLETED;
            }
        default:
            throw Error("Unexpected section type: " + sectionType);
    }
}
