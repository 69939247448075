import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {useEffect} from "react";
import {FormattedMessage, IntlProvider} from "react-intl";
import {
    RouteObject,
    RouterProvider,
    createBrowserRouter,
    redirect,
    useNavigate,
    useRouteError,
} from "react-router-dom";
import {CarForm} from "./app/CarForm";
import {DocumentsForm} from "./app/DocumentsForm";
import {PersonalInfoForm} from "./app/PersonalInfoForm";
import {RegisterLogin} from "./app/RegisterLogin";
import {RegistrationClosed} from "./app/RegistrationClosed";
import {RegistrationComplete} from "./app/RegistrationComplete";
import {RegistrationPage} from "./app/RegistrationPage";
import {Sections} from "./app/Sections";
import {Test} from "./app/Test";
import {Tutorial} from "./app/Tutorial";
import {
    deleteToken,
    getTokenType,
    parseAndSaveTokenFromCookie,
    useLoadDriverState,
} from "./app/apiClientProvider";
import {PageName, PageNavigation} from "./app/model";
import "./index.scss";
import {getLanguage, getMessages} from "./translations";

const queryClient = new QueryClient();

const pagesWithoutLoader: PageName[] = [
    PageName.REGISTER_LOGIN,
    PageName.REGISTRATION_COMPLETE,
    PageName.ERROR,
];

function prepareRoutes(loaderFunction: (page: PageName) => Promise<any>): RouteObject[] {
    return Object.keys(pageFlow).map((key) => {
        const pageName: PageName = key as PageName;
        const flow = pageFlow[pageName];
        const route: RouteObject = {
            path: pageName,
            element: flow.element(),
            loader: async () => loaderFunction(pageName),
            errorElement: <ErrorBoundary />,
        };
        return route;
    });
}

function redirectTo(pageName: PageName) {
    return redirect(`/${pageName}`);
}

export function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <IntlProvider locale={getLanguage()} messages={getMessages()}>
                <AppRouting />
            </IntlProvider>
        </QueryClientProvider>
    );
}

function AppRouting() {
    useLoadDriverState();

    async function loadInitData() {
        parseAndSaveTokenFromCookie();
        const tokenType = getTokenType();
        switch (tokenType) {
            case "REGISTRATION":
                return redirectTo(PageName.REGISTRATION_SECTIONS);
            case "REACTIVATION":
                return redirectTo(PageName.REACTIVATION_SECTIONS);
            default: {
                if (tokenType === "USER") {
                    deleteToken();
                }
                return redirectTo(PageName.REGISTER_LOGIN);
            }
        }
    }

    async function loadDataForPage(pageName: PageName) {
        const tokenType = getTokenType();
        if (tokenType || pagesWithoutLoader.includes(pageName)) {
            return null;
        } else {
            return redirectTo(PageName.REGISTER_LOGIN);
        }
    }

    const router = () =>
        createBrowserRouter([
            {
                path: "/",
                element: <Loader />,
                loader: async () => loadInitData(),
                errorElement: <ErrorBoundary />,
            },
            ...prepareRoutes(loadDataForPage),
        ]);

    return <RouterProvider router={router()} fallbackElement={<Loader />} />;
}

function Loader() {
    return <RegistrationPage headerTextId="loading" children={() => <LoaderSpinner />} />;
}

export const pageFlow: Record<PageName, PageNavigation> = {
    [PageName.REGISTER_LOGIN]: {
        nextPage: PageName.PERSONAL_INFO,
        element: () => <RegisterLogin />,
    },
    [PageName.PERSONAL_INFO]: {
        nextPage: PageName.REGISTRATION_SECTIONS,
        element: () => <PersonalInfoForm />,
    },
    [PageName.REGISTRATION_SECTIONS]: {
        element: () => <Sections />,
    },
    [PageName.REGISTRATION_DOCUMENTS]: {
        nextPage: PageName.REGISTRATION_CAR,
        prevPage: PageName.REGISTRATION_SECTIONS,
        element: () => <DocumentsForm />,
    },
    [PageName.REGISTRATION_CAR]: {
        nextPage: PageName.REGISTRATION_TUTORIAL,
        prevPage: PageName.REGISTRATION_SECTIONS,
        element: () => <CarForm />,
    },
    [PageName.REGISTRATION_TUTORIAL]: {
        nextPage: PageName.REGISTRATION_TEST,
        prevPage: PageName.REGISTRATION_SECTIONS,
        element: () => <Tutorial />,
    },
    [PageName.REGISTRATION_TEST]: {
        prevPage: PageName.REGISTRATION_SECTIONS,
        nextPage: PageName.REGISTRATION_SECTIONS,
        element: () => <Test />,
    },
    [PageName.ERROR]: {
        element: () => <ErrorBoundary />,
    },
    [PageName.REGISTRATION_COMPLETE]: {
        element: () => <RegistrationComplete />,
    },
    [PageName.REGISTRATION_CLOSED]: {
        element: () => <RegistrationClosed />,
    },
    [PageName.REACTIVATION_SECTIONS]: {
        element: () => <Sections loginType="REACTIVATION" />,
    },
    [PageName.REACTIVATION_DOCUMENTS]: {
        nextPage: PageName.REACTIVATION_CAR,
        prevPage: PageName.REACTIVATION_SECTIONS,
        element: () => <DocumentsForm loginType="REACTIVATION" />,
    },
    [PageName.REACTIVATION_CAR]: {
        nextPage: PageName.REACTIVATION_TUTORIAL,
        prevPage: PageName.REACTIVATION_SECTIONS,
        element: () => <CarForm loginType="REACTIVATION" />,
    },
    [PageName.REACTIVATION_TUTORIAL]: {
        nextPage: PageName.REACTIVATION_TEST,
        prevPage: PageName.REACTIVATION_SECTIONS,
        element: () => <Tutorial loginType="REACTIVATION" />,
    },
    [PageName.REACTIVATION_TEST]: {
        prevPage: PageName.REACTIVATION_SECTIONS,
        nextPage: PageName.REACTIVATION_SECTIONS,
        element: () => <Test loginType="REACTIVATION" />,
    },
};

function ErrorBoundary() {
    let error: any = useRouteError();
    if (error) {
        console.error(error);
    }
    const navigate = useNavigate();

    if (error && error.response && [401, 403].includes(error.response.status)) {
        deleteToken();
    }

    useEffect(() => navigate(`/`), []);

    const errorText = () => (
        <h4>
            <FormattedMessage id="unknownError" />
        </h4>
    );

    return <RegistrationPage headerTextId="error" children={errorText} />;
}

export function LoaderSpinner() {
    return (
        <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status" />
        </div>
    );
}
