/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface AcceptanceRate {
    /** @format int32 */
    acceptanceRate?: number;
    /** @format int32 */
    acceptanceRateDelivery?: number;
    /** @format int32 */
    acceptanceRateTaxi?: number;
}

export type AccessTokenType = "USER" | "REGISTRATION";

export interface AccountNumber {
    "@type": string;
    number?: string;
    placeholderText: string;
}

export interface Address {
    formattedAddress: string;
    geometry?: GeometryLocation;
    id: string;
    title: string;
}

export interface AddressV2 {
    coordinates?: Coordinates;
    formattedAddress: string;
    id: string;
    title: string;
}

export interface AddressWithCoordinates {
    formattedAddress: string;
    geometry: GeometryLocation;
    id: string;
    title: string;
}

export interface AddressWithCoordinatesV2 {
    coordinates: Coordinates;
    formattedAddress: string;
    id: string;
    title: string;
}

export interface AlertCommentPayload {
    photos?: Base64Content[];
    text?: string;
}

export interface AnonymousAppParams {
    driverResetPasswordUrl: string;
    registration: RegistrationAppParams;
}

export interface B4FColor {
    /** @format float */
    alpha?: number;
    hex: string;
}

export interface BankAccountV2 {
    accountNumber?: string;
    editable: boolean;
    holderName?: string;
}

export interface Base64Content {
    contentType: string;
    encodedContent: string;
}

export type CancelTarget = "RIDE" | "LEG";

export type CancelType = "ORDERER" | "LIFTAGO" | "PASSENGER" | "DRIVER";

export interface Car {
    /**
     * @minLength 0
     * @maxLength 30
     */
    brand: string;
    carTechnicalCertificateNumber: string;
    color: CarColor;
    fuelType: FuelType;
    /**
     * @minLength 0
     * @maxLength 20
     */
    licencePlate: string;
    /**
     * @minLength 0
     * @maxLength 50
     */
    model: string;
    photo: string;
    /** @format int32 */
    seatCount?: number;
    taxiEvidencePhoto?: string;
    technicalCertificatePage1: string;
    technicalCertificatePage2: string;
    type: CarType;
    /**
     * @minLength 0
     * @maxLength 4
     */
    yearOfManufacture: string;
}

export type CarColor =
    | "BLACK"
    | "BLUE"
    | "BROWN"
    | "GREEN"
    | "ORANGE"
    | "PURPLE"
    | "RED"
    | "SILVER"
    | "WHITE"
    | "YELLOW";

export interface CarInfo {
    chips: Chip[];
    description: string;
    /** Null means don't show the section */
    paymentTerminalEnabled?: boolean;
}

export type CarType = "Combi" | "Sedan" | "Hatchback" | "Van" | "MPV" | "Minibus" | "SUV" | "Other";

export interface ChangeDriverStatusRequest {
    currentPosition: Position;
    deviceId?: string;
    reason?: string;
    status: TaxiStatus;
}

export type ChangeVehicleAction = UtilRequiredKeys<
    SupportCenterAction,
    "icon" | "subtitle" | "title"
>;

export interface Chip {
    background: ThemedColor;
    border?: ThemedColor;
    name: string;
    text: ThemedColor;
    visibleInMenu: boolean;
}

export interface ChipPreviewResponse {
    expandedChips: OrderChip[];
    headerChips: OrderChip[];
}

export interface City {
    country: Country;
    id: string;
    label: string;
}

export interface CodeVerificationRequest {
    code: string;
}

export interface CodeVerificationResponse {
    result: CodeVerificationResult;
}

export type CodeVerificationResult = "OK" | "INCORRECT" | "ATTEMPTS_DEPLETED";

export interface CollectOnDelivery {
    paymentReference: string;
    price: Money;
}

export interface CollectOnDeliveryConfiguration {
    tomSupportUrl: string;
}

export interface CollectOnDeliveryDebt {
    amount?: Money;
    banned: boolean;
}

export interface CollectOnDeliveryDebtInfoResponse {
    debt?: Money;
    promises?: CollectOnDeliveryDebtPromise[];
}

export interface CollectOnDeliveryDebtPromise {
    /** @format date */
    date: string;
    /** @format int64 */
    idDebtPromise: number;
    paid: Money;
}

export interface CollectOnDeliveryPartitionedQrCode {
    debt: Money;
    qrCode: Base64Content;
}

export interface CollectOnDeliveryPaymentInstructionsResponse {
    accountNumber: string;
    debt: Money;
    qrCodeTotalDebt: Base64Content;
    qrCodesPartitioned: CollectOnDeliveryPartitionedQrCode[];
    sazkaInfo: SazkaPaymentInfo;
    /** @format int64 */
    variableSymbol: number;
}

export type CollectOnDeliveryPaymentType = "CASH" | "EXTERNAL_APP";

export interface CollectOnDeliveryPromisePaymentRequest {
    promisedPayment: Money;
}

export interface ColoredLabel {
    text: string;
    textColor: ThemedColor;
}

export interface CompanyInformation {
    /**
     * @minLength 0
     * @maxLength 100
     */
    city: string;
    /**
     * @minLength 0
     * @maxLength 8
     */
    companyId: string;
    /**
     * @minLength 0
     * @maxLength 100
     */
    companyName: string;
    editable?: boolean;
    paysVAT: boolean;
    /**
     * @minLength 0
     * @maxLength 100
     */
    street: string;
    /**
     * @minLength 0
     * @maxLength 12
     */
    taxId: string;
    taxIdPrefillType?: TaxIdPrefillType;
    /**
     * @minLength 0
     * @maxLength 10
     */
    zip: string;
}

export interface ConfirmRidePaymentRequest {
    price?: Money;
}

export interface ConfirmRideRatingRequest {
    comment?: string;
    indicationKeys: string[];
    /** @format int32 */
    rating: number;
}

export interface Contact {
    name: string;
    phoneNumber: string;
}

export interface Coordinates {
    /** @format double */
    lat: number;
    /** @format double */
    lon: number;
}

export interface CoordinatesWithBearing {
    /** @format int32 */
    bearing?: number;
    /** @format double */
    lat: number;
    /** @format double */
    lon: number;
}

export interface CoordinatesWithBearingAndPrecision {
    /** @format int32 */
    bearing?: number;
    /** @format double */
    lat: number;
    /** @format double */
    lon: number;
    /** @format int32 */
    precision?: number;
}

export interface CoordinatesWithDescription {
    description: string;
    /** @format double */
    lat: number;
    /** @format double */
    lon: number;
}

export type Country = "CZ" | "SK";

export interface CreatePositionRequest {
    rideId?: string;
    tracePoint: TracePoint;
}

export type CzechAccountNumber = UtilRequiredKeys<AccountNumber, "placeholderText">;

/** Placeholder for java.time.DayOfWeek documentation */
export type DayOfWeek =
    | "MONDAY"
    | "TUESDAY"
    | "WEDNESDAY"
    | "THURSDAY"
    | "FRIDAY"
    | "SATURDAY"
    | "SUNDAY";

export type Delivery = UtilRequiredKeys<
    HistoryRide,
    "id" | "orderedAt" | "paymentType" | "status"
> & {
    collectOnDelivery?: HistoryRideCollectOnDelivery;
    rideConfirmationUrl?: string;
    stops: DeliveryHistoryRideStop[];
};

export type DeliveryDepot = UtilRequiredKeys<
    Leg,
    | "cancellableTargets"
    | "dependentLegIds"
    | "destination"
    | "id"
    | "increasesFinalPrice"
    | "label"
    | "state"
> & {
    details?: DeliveryDepotDetails;
    /** @format date-time */
    eta?: string;
    packagesTransfers: PackagesTransfer[];
};

export type DeliveryDepotDestinationArrived = LegDestinationArrived;

export interface DeliveryDepotDetails {
    destinationDescription: string;
    destinationName: string;
    destinationPictureUrls: string[];
}

export type DeliveryDepotFailure = LegFinish & {
    comment?: string;
    photo?: Base64Content;
    reason?: string;
};

export type DeliveryDepotFinish = LegFinish;

export type DeliveryDepotStart = UtilRequiredKeys<LegStart, "position">;

export type DeliveryDropOff = UtilRequiredKeys<
    Leg,
    | "cancellableTargets"
    | "dependentLegIds"
    | "destination"
    | "id"
    | "increasesFinalPrice"
    | "label"
    | "state"
> & {
    /** @format date-time */
    arrivedAt?: string;
    collectOnDelivery?: CollectOnDelivery;
    details: DeliveryLegDetails;
    /** @format date-time */
    eta?: string;
    expandedChips: OrderChip[];
    expandedIndicators: ExpandedOrderIndicator[];
    headerGroupedChips: OrderChip[];
    headerIndicators: HeaderOrderIndicator[];
    headerSingleChips: OrderChip[];
    paymentType: DeliveryPaymentType;
    pickup: Position;
    recipientEta?: TimeInterval;
    sender: Contact;
    shipmentReturns?: ShipmentReturns;
    type: DeliveryDropOffType;
};

export type DeliveryDropOffType = "HANDOVER" | "RETURN";

export interface DeliveryHistoryRideStop {
    collectOnDelivery?: StopCollectOnDelivery;
    label: string;
    subtitle: string;
    title: string;
}

export type DeliveryInquiry = PushMsg & {
    deliveryOrder: InquiryDeliveryOrderInfo;
    inquiry: Inquiry;
};

export type DeliveryLegDestinationArrived = LegDestinationArrived;

export interface DeliveryLegDetails {
    linksForDriver: LegLink[];
    noteByDriver?: NoteByDriver;
    packages: Package[];
    proofSpecification?: ProofSpecification;
}

export type DeliveryLegFailure = LegFinish & {
    comment?: string;
    photo?: Base64Content;
    reason?: string;
    wholeRide: boolean;
};

export type DeliveryLegFinish = LegFinish & {
    cancelledHandoverIds?: string[];
    collectOnDeliveryPaymentType?: CollectOnDeliveryPaymentType;
    noteByDriverComment?: string;
    noteByDriverPhoto?: Base64Content;
    paymentInfo?: PaymentInfo;
};

export type DeliveryLegStart = UtilRequiredKeys<LegStart, "position">;

export type DeliveryPaymentType = "NONE" | "CASH" | "BY_APP";

export type DeliveryPickup = UtilRequiredKeys<
    Leg,
    | "cancellableTargets"
    | "dependentLegIds"
    | "destination"
    | "id"
    | "increasesFinalPrice"
    | "label"
    | "state"
> & {
    /** @format date-time */
    arrivedAt?: string;
    details: DeliveryLegDetails;
    enableDriverNotes: boolean;
    /** @format date-time */
    eta?: string;
    headerSingleChips: OrderChip[];
    pickupTimeSpecified: boolean;
    showCancelChooser: boolean;
};

export interface DesiredDestination {
    locationSuggestion: LocationSuggestionWithCoordinates;
    /** Placeholder for ISO 8601 duration string */
    maxEtaDurationFromActualDestination: Duration;
}

export interface DesiredDestinationState {
    current?: DesiredDestination;
    /** Placeholder for ISO 8601 duration string */
    minEtaDurationFromActualDestination: Duration;
    /** @format date-time */
    nextRemainingUsesReset: string;
    /** @format int32 */
    remainingDeclinesForCurrent?: number;
    /** @format int32 */
    remainingUses: number;
}

export type DesiredDestinationStateMsg = PushMsg & {
    state: DesiredDestinationState;
};

export type DialHelpOption = UtilRequiredKeys<HelpOption, "label"> & {
    phoneNumber: string;
};

export type DialViaConnectHelpOption = UtilRequiredKeys<HelpOption, "label"> & {
    type: DialViaConnectType;
};

export type DialViaConnectType = "LAST" | "HELPLINE";

export interface DriverAlert {
    /** @format date-time */
    end?: string;
    id: string;
    read: boolean;
    /** @format date-time */
    start: string;
    title: string;
}

export interface DriverAlertComment {
    /** @format date-time */
    createdAt: string;
    /** @format int32 */
    photoCount: number;
    text?: string;
}

export interface DriverAlertCommentInputField {
    required: boolean;
    type: DriverAlertCommentInputFieldType;
}

export type DriverAlertCommentInputFieldType = "INPUT_TEXT" | "INPUT_PHOTOS";

export interface DriverAlertCommentParams {
    inputFields?: DriverAlertCommentInputField[];
}

export interface DriverAlertDetail {
    body: string;
    commentParams?: DriverAlertCommentParams;
    comments?: DriverAlertComment[];
    id: string;
    title: string;
}

export interface DriverAlerts {
    alerts: DriverAlert[];
    next?: string;
}

export interface DriverAppConfiguration {
    collectOnDeliveryConfiguration: CollectOnDeliveryConfiguration;
    liteAppConfiguration: LiteAppConfiguration;
    strings: DriverStrings;
    voiceNotesEnabled: boolean;
}

export interface DriverBan {
    actionType?: DriverBanActionType;
    description: string;
    reasonCode: string;
    title: string;
}

export type DriverBanActionType = "PROFILE_REACTIVATION" | "PROFILE_EDIT";

export interface DriverBankAccount {
    accountNumber: AccountNumber;
    holderName?: string;
}

export interface DriverBasicInfo {
    chips: Chip[];
    email: string;
    group: DriverGroup;
    name: string;
    profilePhotoUrl?: string;
}

export interface DriverCard {
    cardType: string;
    number: string;
    /** @format date */
    validUntil: string;
}

export interface DriverCompany {
    billingAddress?: DriverCompanyAddress;
    companyId?: string;
    companyName?: string;
    displayName?: string;
    editable: boolean;
    paysVAT?: boolean;
    taxId?: string;
}

export interface DriverCompanyAddress {
    /**
     * @minLength 0
     * @maxLength 500
     */
    city: string;
    /**
     * @minLength 0
     * @maxLength 100
     */
    country?: string;
    /**
     * @minLength 0
     * @maxLength 500
     */
    street: string;
    /**
     * @minLength 0
     * @maxLength 10
     */
    zip: string;
}

export interface DriverCurrentPayoutResponse {
    basicInfo: DriverPayoutBasicInfo;
    regionsWithFeeLevels: RegionWithFeeLevels[];
}

export interface DriverDocuments {
    company?: CompanyInformation;
    drivingLicense: DrivingLicense;
    idCard?: IdCard;
    profilePhoto: string;
    yellowCard?: YellowCard;
}

export type DriverGroup = "TAXI" | "UNIVERSAL" | "CARGO";

export interface DriverInformation {
    car?: Car;
    documents?: DriverDocuments;
    personalInfo?: DriverPersonalInformation;
    test?: DriverTest;
    trainingCompleted?: boolean;
}

export interface DriverLastPayoutsResponse {
    payouts: DriverPayoutBasicInfo[];
}

export interface DriverPayoutBasicInfo {
    cashRideIncome: Money;
    /** @format date */
    end: string;
    inAppRideIncome: Money;
    inAppTips?: Money;
    /** @format int32 */
    points: number;
    /** @format int32 */
    rides: number;
    /** @format date */
    start: string;
    totalIncome: Money;
}

export interface DriverPersonalInformation {
    driverGroup: DriverGroup;
    /**
     * @minLength 0
     * @maxLength 100
     */
    firstName: string;
    /**
     * @minLength 0
     * @maxLength 100
     */
    lastName: string;
    /**
     * @minLength 4
     * @maxLength 15
     */
    phoneNumber: string;
    taximeter: boolean;
    workingCity: string;
}

export interface DriverReactivationState {
    car: Car;
    documents: DriverDocuments;
    personalInfo: ReactivationPersonalInfo;
    test: DriverTest;
    trainingCompleted: boolean;
}

export interface DriverState {
    ban?: DriverBan;
    countryNotLaunched: boolean;
    currentRideId?: string;
    helpLinePhoneNumber: string;
    inFleet: boolean;
    missingCardTerminalInfo: boolean;
    nextRideOverview?: NextRideOverview;
    offDutyTransitionBlocked: boolean;
    searchableDestination?: Position;
    status: TaxiStatus;
    verifyPhoneNumber: boolean;
}

export interface DriverStateAction {
    "@type": string;
}

export interface DriverStateV2 {
    "@type": string;
}

export type DriverStatusChangedMsg = PushMsg & {
    status: TaxiStatus;
};

export interface DriverStrings {
    customerSupportWorkingHours: string[];
}

export interface DriverTest {
    previousScore?: TestScore;
    questions: DriverTestPublicQuestion[];
    /** @format int32 */
    remainingAttempts?: number;
    score?: TestScore;
    success?: boolean;
}

export interface DriverTestPossibleAnswer {
    id: string;
    text: string;
}

export interface DriverTestPublicQuestion {
    answers: DriverTestPossibleAnswer[];
    id: string;
    selectedAnswerId?: string;
    text: string;
}

export interface DriversAroundResponse {
    drivers: OtherDriver[];
    /** Placeholder for ISO 8601 duration string */
    validFor: Duration;
}

export interface DrivingLicense {
    /** @format date */
    birthDate?: string;
    birthNumber?: string;
    birthPlace?: string;
    /** @format date */
    expiration: string;
    number: string;
    photoPage1: string;
    photoPage2: string;
}

export interface DrvAcceptInquiryRequest {
    /** Placeholder for ISO 8601 duration string */
    arrivalDuration: Duration;
    bidId: string;
    inquiryId: string;
}

export interface DrvAckInquiryRequest {
    driverSearchId: string;
    inquiryId: string;
}

export interface DrvIgnoreInquiryRequest {
    inquiryId: string;
}

export interface DrvLiteAppConfiguration {
    /** @format int64 */
    requiredAppBuild: number;
    /** @format int64 */
    upcomingRequiredAppBuild: number;
}

export interface DrvLiteAppNotificationRegistrationRequest {
    token: string;
}

export interface DrvLiteDriverProfile {
    driverId: string;
    firstName?: string;
    lastName: string;
    liteAppEnabled: boolean;
    phoneNumber: string;
    profileImage?: Image;
}

export interface DrvNotificationRegistrationRequest {
    token?: string;
}

export interface DrvRejectInquiryRequest {
    inquiryId: string;
}

/** Placeholder for ISO 8601 duration string */
export interface Duration {
    duration: string;
}

export type EndOfDeliveryRideRatingInfo = EndOfRideRatingInfo;

export interface EndOfRideClientInfo {
    imageUrl?: string;
    name: string;
}

export interface EndOfRideInfo {
    "@type": string;
}

export interface EndOfRideInfoV2 {
    "@type": string;
}

export type EndOfRideRatingInfo = UtilRequiredKeys<EndOfRideInfoV2, "@type"> & {
    "@type": string;
    /** @format int32 */
    indicationsRequiredUntilStars: number;
    nextRide?: NextRideOverview;
    ratingIndications: SelectableItem[];
    /** @format int32 */
    textRequiredUntilStars: number;
    topBanner?: InfoBanner;
};

export interface EndOfRideTipMode {
    "@type": string;
}

export type EndOfTaxiRidePaymentInfo = EndOfRideInfoV2 & {
    allowedPriceRange: PriceRange;
    confirmButtonTitle: string;
    defaultPrice: Money;
    expectedPriceRange: PriceRange;
    paymentInfo: OrderChip;
    promisedTipBanner?: InfoBanner;
    qrCode?: Base64Content;
    ridePriceMode: RidePriceMode;
    tipMode: EndOfRideTipMode;
    title: string;
    waitingTipInfo?: OrderChip;
};

export type EndOfTaxiRideRatingInfo = EndOfRideRatingInfo & {
    passengerIconUrl?: string;
    passengerName: string;
};

export type ErrorCode =
    | "RIDE_NOT_FOUND"
    | "LEG_NOT_FOUND"
    | "ILLEGAL_STOP_TRANSITION"
    | "ILLEGAL_STOP_OPERATION"
    | "ILLEGAL_PAYMENT_STATUS"
    | "TAXI_NOT_AVAILABLE"
    | "NO_REMAINING_DESIRED_DESTINATION"
    | "MAX_DESIRED_DESTINATION_ETA_TOO_SMALL"
    | "FORBIDDEN_OPERATION"
    | "UNAUTHORIZED_OPERATION"
    | "FORCE_UPDATE_REQUIRED"
    | "STATE_CHANGE_CONFLICT"
    | "DRIVER_REACTIVATION_ALREADY_FINISHED"
    | "DRIVER_REACTIVATION_NOT_POSSIBLE"
    | "INVALID_PHONE_NUMBER"
    | "END_OF_RIDE_INFO_NOT_AVAILABLE";

export interface Event {
    parameters?: Record<string, string>;
    type: string;
}

export interface ExpandedOrderIndicator {
    icon: ThemedIcon;
    label: string;
}

export interface FeeLevel {
    /** @format int32 */
    from: number;
    isCurrent: boolean;
    percentage: number;
    /** @format int32 */
    to?: number;
}

export interface FeedbackIndication {
    /** @format int32 */
    complaints: number;
    description: string;
    iconUrl: string;
    /** @format int32 */
    praises: number;
}

export interface FeedbackPeriodStatistics {
    "@type": string;
    description: string;
    periodTitle: string;
}

export type FeedbackPeriodStatisticsData = UtilRequiredKeys<
    FeedbackPeriodStatistics,
    "description" | "periodTitle"
> & {
    indications: FeedbackIndication[];
    stars: StarsCount[];
};

export type FeedbackPeriodStatisticsPreparing = UtilRequiredKeys<
    FeedbackPeriodStatistics,
    "description" | "periodTitle"
>;

export interface FeedbackStatistics {
    /** @format int32 */
    avgFromRidesCount: number;
    name: string;
    periods: FeedbackPeriodStatistics[];
    rating: number;
}

export type FinishingEndOfRideInfo = EndOfRideInfo & {
    customerPrice?: Money;
    estimatedPrice: Money;
    paymentState?: PaymentState;
    paymentType: PassengerPaymentType;
    qrCode?: Base64Content;
    ratingInfo?: RatingInfo;
};

export interface FleetCarPick {
    deviceId: string;
}

export interface FleetCarPickResponse {
    verifyPhoneNumber: boolean;
}

export type FuelType = "Diesel" | "Gasoline" | "Gas" | "Hybrid" | "Electric";

export interface GeoPoint {
    address: string;
    id: string;
    /** @format double */
    lat: number;
    /** @format double */
    lon: number;
    title: string;
}

export interface GeometryLocation {
    location: LngLatLocation;
}

export type GoToUrlAction = UtilRequiredKeys<SupportCenterAction, "icon" | "subtitle" | "title"> & {
    url: string;
};

export interface GpCollectOnDeliveryStatus {
    canGenerateTransactionId: boolean;
    lastPayments: GpPaymentInfo[];
    /** @format int32 */
    tomVersion?: number;
}

export interface GpPaymentInfo {
    /** @format date-time */
    date: string;
    errorMessage?: string;
    paymentReference: string;
    success: boolean;
    tid?: string;
}

export interface HeaderOrderIndicator {
    group: boolean;
    icon: ThemedIcon;
    label?: string;
}

export interface HelpOption {
    "@type": string;
    label: string;
}

export interface HelpOptions {
    options: HelpOption[];
}

export interface HistoryOption {
    "@type": string;
}

export interface HistoryRide {
    "@type": string;
    id: string;
    /** @format date-time */
    orderedAt: string;
    paymentType: PassengerPaymentType;
    price?: Money;
    status: HistoryRideStatus;
}

export interface HistoryRideCollectOnDelivery {
    app?: Money;
    cash?: Money;
    total: Money;
}

export interface HistoryRideListItem {
    destination: string;
    id: string;
    /** @format int32 */
    intermediateStops: number;
    /** @format date-time */
    orderedAt: string;
    pickup: string;
    price?: Money;
    service: OrderChip;
    status: HistoryRideStatus;
}

export type HistoryRideStatus = "IN_PROGRESS" | "COMPLETED" | "CANCELLED" | "AWAITING_ACCOUNTING";

export interface HistoryRidesResponse {
    cursor?: string;
    rides: HistoryRide[];
}

export interface HistoryRidesV2Response {
    nextCursor?: string;
    previousCursor?: string;
    timeWindows: RideTimeWindow[];
}

export type Iban = UtilRequiredKeys<AccountNumber, "placeholderText">;

export interface IdCard {
    /** @format date */
    expiration: string;
    number: string;
    photo: string;
}

export interface Image {
    href: string;
    uuid: string;
}

export type InAppTipMode = EndOfRideTipMode & {
    tipPaymentDisclaimer: TipPaymentDisclaimer;
};

export interface InfoBanner {
    background: ThemedColor;
    icon?: ThemedIcon;
    subtitle?: string;
    textColor: ThemedColor;
    title?: string;
}

export type InitiateCallMsg = PushMsg & {
    contact: Contact;
    placeName: string;
};

export interface Inquiry {
    arrivalEstimate: RouteEstimate;
    bids: PriceBid[];
    /** @format date-time */
    createdAt: string;
    decreasesAcceptance: boolean;
    destination?: CoordinatesWithDescription;
    driverSearchId: string;
    emergency: boolean;
    id: string;
    /** Placeholder for ISO 8601 duration string */
    maxArrival: Duration;
    /** Placeholder for ISO 8601 duration string */
    minArrival: Duration;
    pickup: CoordinatesWithDescription;
    pickupTimeSpecified: boolean;
    points?: number;
    /** Placeholder for ISO 8601 duration string */
    responseTime: Duration;
    rideEstimate: RouteEstimate;
}

export interface InquiryDeliveryOrderInfo {
    /** @format int32 */
    dropOffCount: number;
    headerIndicators: HeaderOrderIndicator[];
    orderer: InquiryOrdererInfo;
}

export interface InquiryEstimates {
    endOfRideEstimate?: RouteEstimate;
    /** Placeholder for ISO 8601 duration string */
    maxArrivalDuration: Duration;
    /** Placeholder for ISO 8601 duration string */
    minArrivalDuration: Duration;
    pickupArrivalEstimate: RouteEstimate;
    rideEstimate: RouteEstimate;
}

export interface InquiryOrdererInfo {
    icon?: ThemedIcon;
    name: string;
    rating?: number;
}

export interface InquiryPresentation {
    bidsAreaBackground?: ThemedColor;
    inquiryThemeColor: ThemedColor;
    leftSideChips: OrderChip[];
    pickupTimeSpecified: boolean;
    rightSideChips: OrderChip[];
    sound?: string;
    textLines: ColoredLabel[];
    timerColor?: ThemedColor;
}

export interface InquiryPriceBid {
    backgroundColor: ThemedColor;
    borderColor: ThemedColor;
    iconUrl?: string;
    id: string;
    label?: ColoredLabel;
    price: Money;
    textColor: ThemedColor;
}

export interface InquiryPrices {
    bids: InquiryPriceBid[];
}

export interface InquiryRouteShapes {
    endOfRide?: string;
    pickupArrival?: string;
    ride?: string;
}

export interface InquiryStop {
    address?: string;
    coordinates: Coordinates;
    label?: string;
}

export interface InquiryTaxiOrderInfo {
    /** @format int32 */
    destinationCount: number;
    headerIndicators: HeaderOrderIndicator[];
    incentive?: Money;
    orderer: InquiryOrdererInfo;
    specialSound: boolean;
}

export type InquiryV2 = PushMsg & {
    /** @format date-time */
    createdAt: string;
    currentDestination?: Coordinates;
    decreasesAcceptance: boolean;
    driverSearchId: string;
    estimates: InquiryEstimates;
    inquiryId: string;
    /** Placeholder for ISO 8601 duration string */
    maxResponseTime: Duration;
    orderer: InquiryOrdererInfo;
    presentation: InquiryPresentation;
    prices: InquiryPrices;
    stops: InquiryStop[];
};

/**
 *
 * Leg states example:
 * Delivery ride with 1 pickup, 2 drop-offs
 * 1. Ride starts with pickup ON_THE_WAY, drop-offs NOT_AVAILABLE
 * 2. Pickup set to AT_DESTINATION, drop-offs still NOT_AVAILABLE
 * 3. Pickup set to SUCCESS, drop-off 1 NOT_STARTED, drop-off 2 AVAILABLE
 * 4. Drop-off 1 set to ON_THE_WAY, drop-off 2 still AVAILABLE...
 */
export interface Leg {
    "@type": string;
    cancellableTargets: CancelTarget[];
    contact?: Contact;
    dependentLegIds: string[];
    destination: Position;
    groupLabel?: string;
    id: string;
    increasesFinalPrice: boolean;
    label: string;
    noteForDriver?: string;
    /** @format int32 */
    showCtaFromDistanceMeters: number;
    state: LegState;
    voiceNotes?: VoiceNote[];
}

export interface LegCancelInfo {
    banners: InfoBanner[];
    checklist?: string[];
    checklistTitle?: string;
    helplinePhoneNumber?: string;
    instructionLinks?: LegLink[];
    reasons: LegFailReasonInfo[];
    reasonsTitle: string;
    title: string;
}

export interface LegCancelProofs {
    callCheckDescription?: string;
    driverCommentInputDescription: string;
    driverCommentInputMandatory: boolean;
    driverPhotoDescription: string;
    driverPhotoMandatory: boolean;
    title: string;
    waitingCheckDescription?: string;
    /** @format date-time */
    waitingCheckWarningUntil?: string;
}

export interface LegCancelResult {
    contactSupport: boolean;
    ride?: Ride;
    rideEffect: LegCancelRideEffect;
}

export type LegCancelRideEffect = "RIDE_CANCELLED" | "RIDE_CONTINUES" | "RIDE_FINISH_PENDING";

export interface LegDestinationArrived {
    "@type": string;
    position?: Position;
}

export interface LegFailReasonInfo {
    additionalBanners: InfoBanner[];
    commentMandatory: boolean;
    photoMandatory: boolean;
    proofs?: LegCancelProofs;
    reason: string;
    title: string;
    unfulfilledStandards: UnfulfilledStandards;
}

export interface LegFinish {
    "@type": string;
    position?: Position;
}

export interface LegLink {
    title?: string;
    url: string;
}

export interface LegStart {
    "@type": string;
    position?: Position;
}

export type LegState =
    | "NOT_STARTED"
    | "ON_THE_WAY"
    | "AT_DESTINATION"
    | "NOT_AVAILABLE"
    | "AVAILABLE"
    | "SUCCESS"
    | "FAILURE";

export interface LiteAppConfiguration {
    downloadUrl: string;
    enabled: boolean;
}

export interface LiteAppSettings {
    enabled: boolean;
}

export interface LngLatLocation {
    lat: number;
    lng: number;
}

export interface LocalTime {
    /** @format int32 */
    hour?: number;
    /** @format int32 */
    minute?: number;
    /** @format int32 */
    nano?: number;
    /** @format int32 */
    second?: number;
}

export interface LocationSuggestion {
    address: Address;
    type: LocationSuggestionType;
}

export type LocationSuggestionType = "SEARCH" | "DESIRED_DESTINATION_HISTORY";

export interface LocationSuggestionV2 {
    address: AddressV2;
    type: LocationSuggestionType;
}

export interface LocationSuggestionWithCoordinates {
    address: AddressWithCoordinates;
    type: LocationSuggestionType;
}

export interface LocationSuggestionsResponse {
    items: LocationSuggestion[];
}

export interface LocationSuggestionsResponseV2 {
    items: LocationSuggestionV2[];
}

export interface LoggedInAppParams {
    mqttAppParams?: MqttAppParams;
    reactivation: RegistrationAppParams;
}

export interface LoginRequest {
    password: string;
    username: string;
}

export interface LoginResponse {
    accessToken: string;
    type: AccessTokenType;
    userId: string;
}

export interface Menu {
    codDebt?: CollectOnDeliveryDebt;
    /** @format int32 */
    currentPayoutRideCountWithCoefficient: number;
    currentPayoutTotalIncome: Money;
    displayFlags: MenuDisplayFlags;
    driver: MenuDriver;
    /** @format int32 */
    messageCount: number;
    offerToRide?: OfferToRide;
    weeklyAcceptanceRate: AcceptanceRate;
}

export interface MenuDisplayFlags {
    showPeakHours: boolean;
}

export interface MenuDriver {
    chips: Chip[];
    name: string;
    profilePhotoUrl?: string;
    rating: number;
    status: TaxiStatus;
}

export interface MenuStatistics {
    /** @format int32 */
    alertCount: number;
    codDebt?: CollectOnDeliveryDebt;
    /** @format int32 */
    currentPayoutRideCountWithCoeff: number;
    currentPayoutTotalIncome: Money;
    offerToRide?: OfferToRide;
    rating: number;
    weeklyAcceptanceRate: AcceptanceRate;
}

export interface MessageOverview {
    snippets: MessageSnippet[];
    /** @format int32 */
    unreadCount: number;
}

export interface MessageSnippet {
    icon: ThemedIcon;
    /** @format date-time */
    lastActivityAt: string;
    title: string;
    type: string;
    unread: boolean;
}

export interface ModalWindow {
    sections: TextSection[];
    title: string;
}

export interface Money {
    amount: number;
    ccy: string;
}

export interface MoneyRange {
    ccy: string;
    max?: number;
    min: number;
}

export interface MqttAppParams {
    driverPositionTopic: string;
    host: string;
    incomingMessageUserQueue: string;
    /** Placeholder for ISO 8601 duration string */
    keepAliveInterval: Duration;
    lastWillAndTestamentTopic: string;
    password: string;
    /** @format int32 */
    port: number;
    protocol: string;
    username: string;
}

export interface NextFeeLevelMotivation {
    /** @format int32 */
    numberOfRides: number;
    percentage: number;
}

export type NextRideCreatedMsg = PushMsg & {
    nextRide: NextRideOverview;
};

export interface NextRideOverview {
    contact: Contact;
    /** @format date-time */
    firstStopEta?: string;
    firstStopPlaceName: string;
    id: string;
}

export type NoPaymentRequiredEndOfRideInfo = EndOfRideInfo & {
    driverPrice: Money;
    ratingInfo?: RatingInfo;
};

export type NoTipMode = EndOfRideTipMode;

export interface NoteByDriver {
    description?: string;
    photoUrl?: string;
}

export interface Notification {
    body: string;
    threadId?: string;
    /** Placeholder for ISO 8601 duration string */
    timeout?: Duration;
    title: string;
}

export type OfferExpiredMsgContent = PushMsg & {
    inquiryId: string;
    searchId: string;
};

export type OfferRejectedMsgContent = PushMsg & {
    inquiryId: string;
    searchId: string;
};

export interface OfferToRide {
    /** @format int32 */
    benchmark: number;
    /** @format int32 */
    driver: number;
    regionName: string;
}

export type OfflineState = DriverStateV2 & {
    actions: DriverStateAction[];
    sessionId?: string;
};

export type OlderHistoryOption = HistoryOption;

export type OnlineAvailableState = OnlineState & {
    desiredDestinationState: DesiredDestinationState;
};

export type OnlineServingState = OnlineState & {
    currentRideId: string;
    nextRideOverview?: NextRideOverview;
    /** Placeholder for ISO 8601 duration string */
    onBoardPositionSendInterval: Duration;
};

export type OnlineState = UtilRequiredKeys<DriverStateV2, "@type"> & {
    "@type": string;
    inquiriesPaused: boolean;
    /** Placeholder for ISO 8601 duration string */
    positionSendInterval: Duration;
};

export type OnlineStreetPickupState = OnlineState & {
    nextRideOverview?: NextRideOverview;
    searchableDestination: Position;
};

export interface OrderChip {
    backgroundColor?: ThemedColor;
    icon?: ThemedIcon;
    label?: ColoredLabel;
}

export interface OrderIntoRide {
    enabled: boolean;
}

export interface OtherDriver {
    id: string;
    position: CoordinatesWithBearing;
}

export interface Package {
    destination: Position;
    handoverId: string;
    /** Empty string if none available */
    packageId: string;
    parcels: Parcel[];
    recipientName: string;
}

export interface PackagesTransfer {
    operatingInstructions: string[];
    operatingInstructionsUrl?: string;
    packages: Package[];
    type: PackagesTransferType;
    verificationCode: string;
}

export type PackagesTransferType = "PICKUP" | "DROP_OFF";

export interface Parcel {
    clientParcelId: string;
}

export type PassengerDropOff = UtilRequiredKeys<
    Leg,
    | "cancellableTargets"
    | "dependentLegIds"
    | "destination"
    | "id"
    | "increasesFinalPrice"
    | "label"
    | "state"
> & {
    description: string;
    /**
     * Always end of ride ETA
     * @format date-time
     */
    eta?: string;
    expandedChips: OrderChip[];
    expandedIndicators: ExpandedOrderIndicator[];
    headerGroupedChips: OrderChip[];
    headerIndicators: HeaderOrderIndicator[];
    headerSingleChips: OrderChip[];
    showCannotChangeDestination: boolean;
};

export type PassengerPaymentType = "CASH" | "CARD_TERMINAL" | "BY_APP";

export type PassengerPickup = UtilRequiredKeys<
    Leg,
    | "cancellableTargets"
    | "dependentLegIds"
    | "destination"
    | "id"
    | "increasesFinalPrice"
    | "label"
    | "state"
> & {
    /** @format date-time */
    arrivedAt?: string;
    description: string;
    /** @format date-time */
    eta: string;
    expandedChips: OrderChip[];
    expandedIndicators: ExpandedOrderIndicator[];
    headerGroupedChips: OrderChip[];
    headerIndicators: HeaderOrderIndicator[];
    headerSingleChips: OrderChip[];
    /** @format date-time */
    requestedPickupAt?: string;
};

export type PassengerPositionChangedMsgContent = PushMsg & {
    position: PassengerTracePoint;
    userProfileId: string;
};

export interface PassengerTracePoint {
    location: Coordinates;
    /** @format date-time */
    timestamp: string;
}

export interface PaymentInfo {
    provider: string;
    transactionId: string;
}

export type PaymentRequiredEndOfRideInfo = EndOfRideInfo & {
    allowedPriceRange: PriceRange;
    allowedPriceRangeCurrency: string;
    expectedPriceRange: PriceRange;
    fixedPrice: Money;
    paymentType: PassengerPaymentType;
    ridePriceMode: RidePriceMode;
};

export type PaymentState = "IN_PROCESS" | "SUCCESS" | "FAILURE";

export interface PhoneValidationRequest {
    phoneNumber: string;
}

export interface PhoneValidationResponse {
    valid: boolean;
}

export interface PickCarRequest {
    carId: string;
}

export interface Point {
    "@type": string;
}

export interface PointLegend {
    color: ThemedColor;
    value: number;
}

export interface PointsForWeek {
    days: PointsRow[];
    legend: PointLegend[];
}

export interface PointsRow {
    /** Placeholder for java.time.DayOfWeek documentation */
    day: DayOfWeek;
    points: Point[];
}

export interface Position {
    /** @format int32 */
    bearing?: number;
    id?: string;
    /** @format double */
    lat: number;
    /** @format double */
    lon: number;
    placeName?: string;
    /** @format int32 */
    precision?: number;
}

export interface PositionPush {
    driverId: string;
    position: TracePoint;
    rideId?: string;
}

export interface PriceBid {
    disabled: boolean;
    id: string;
    label?: string;
    price: Money;
    recommended?: boolean;
}

export interface PriceInfo {
    estimatedPrice: Money;
}

export interface PriceRange {
    max?: number;
    min?: number;
}

export interface Profile {
    bankAccount: DriverBankAccount;
    basicInfo: DriverBasicInfo;
    car: CarInfo;
    company: DriverCompany;
    rating: number;
}

export interface ProfileV2 {
    bankAccount?: BankAccountV2;
    basicInfo: DriverBasicInfo;
    car: CarInfo;
    company: DriverCompany;
    driverCard: DriverCard;
    phoneNumber: string;
    rating: number;
    status: TaxiStatus;
    workingCity: WorkingCity;
}

export interface ProofOfDeliveryDocument {
    holderName: string;
    number?: string;
    type: ProofOfDeliveryDocumentType;
}

export type ProofOfDeliveryDocumentType = "ID" | "PASSPORT" | "OTHER";

export type ProofOfDeliveryMode =
    | "NONE"
    | "CODE_DOCUMENT_WITH_SIGN"
    | "CODE_DOCUMENT"
    | "CODE_SIGN"
    | "DOCUMENT_SIGN"
    | "DOCUMENT_WITH_SIGN"
    | "SIGN"
    | "DOCUMENT"
    | "NAME_WITH_SIGN";

export interface ProofSpecification {
    mode: ProofOfDeliveryMode;
    verificationCodeParams?: VerificationCodeParams;
}

export interface PushMsg {
    ackCategory?: string;
    notification?: Notification;
    /** @format date-time */
    occurredAt?: string;
    pushId: string;
}

export interface PushRegistrationResponse {
    mqttPushId?: string;
}

export interface RatingInfo {
    clientInfo: EndOfRideClientInfo;
}

export type ReactivateAccountHelpOption = UtilRequiredKeys<HelpOption, "label"> & {
    webParams: RegistrationAppParams;
};

export interface ReactivationPersonalInfo {
    driverGroup: DriverGroup;
}

export interface RegionWithFeeLevels {
    levels: FeeLevel[];
    nextFeeLevelMotivation?: NextFeeLevelMotivation;
    regionName: string;
}

export interface RegistrationAppParams {
    cookieUrl: string;
    javascriptInterfaceVersion: string;
    url: string;
}

export interface RegistrationRequestV2 {
    email: string;
    password: string;
}

export interface ReorderLeg {
    type: ReorderLegType;
}

export type ReorderLegType = "NOW" | "NEXT" | "END";

export type ReportBugAction = UtilRequiredKeys<
    SupportCenterAction,
    "icon" | "subtitle" | "title"
> & {
    modalBody: string;
    modalTitle: string;
};

export interface ReturningShipmentDetail {
    cancelPhotoUrl?: string;
    cancelledDropOffLegId: string;
    dropOffAddressSubtitle: string;
    dropOffAddressTitle: string;
    dropOffLabel: string;
    note?: string;
    /** @format int32 */
    parcelCount: number;
    recipientName: string;
}

export interface Ride {
    currentLegId?: string;
    id: string;
    legs: Leg[];
    priceInfo: PriceInfo;
}

export type RideCancelledMsg = PushMsg & {
    cancelledBy: CancelType;
    rideId: string;
};

export type RideChangedMsg = PushMsg & {
    moveToForeground: boolean;
    rideId: string;
};

export interface RideFinalizationRequest {
    /** @format int32 */
    passengerRating?: number;
    ratingComment?: string;
    taximeterPrice?: Money;
}

export interface RideHistoryFilter {
    /**
     * @format int32
     * @max 100
     */
    limit: number;
    /** Placeholder for ISO 8601 duration string */
    month?: YearMonth;
    next?: string;
    previous?: string;
    type?: RideType;
}

export interface RideHistoryOptions {
    availableOptions: HistoryOption[];
    availableRideTypes: RideType[];
}

export interface RideHistoryRideTypeOptions {
    availableOptions: (OlderHistoryOption | YearMonthHistoryOption)[];
}

export interface RidePaths {
    activePath?: string;
    nextPath?: string;
}

export interface RidePaymentRequest {
    cash?: boolean;
    price?: Money;
}

export type RidePaymentResultMsg = PushMsg & {
    cash?: Money;
    inApp?: PaymentState;
    rideId: string;
};

export type RidePriceMode = "WRITE" | "READ";

export type RideStartedMsg = PushMsg & {
    /** Placeholder for ISO 8601 duration string */
    goingToPickupPositionSendInterval: Duration;
    /** Placeholder for ISO 8601 duration string */
    onBoardPositionSendInterval: Duration;
    rideId: string;
};

export interface RideTimeWindow {
    historyOption: HistoryOption;
    name: string;
    rides: HistoryRideListItem[];
}

export type RideType = "TAXI" | "DELIVERY";

export interface RouteChangedStop {
    label: string;
    state: RouteChangedStopState;
    subtitle: string;
    title: string;
}

export type RouteChangedStopState = "NOT_MODIFIED" | "MODIFIED" | "ADDED" | "REMOVED" | "COMPLETED";

export interface RouteEstimate {
    /** @format int32 */
    distance: number;
    /** Placeholder for ISO 8601 duration string */
    duration: Duration;
}

export interface SazkaPaymentInfo {
    highDebt: Money;
    howToUrl: string;
    mapsUrl: string;
}

export interface ScheduledAuction {
    "@type": string;
    location: Position;
}

export interface ScheduledAuctionDetail {
    expandedChips: OrderChip[];
    expandedIndicators: ExpandedOrderIndicator[];
    /** @format date-time */
    from: string;
    lastStop: StopBasicInfo;
    payment?: ScheduledAuctionPaymentInfo;
    pickup?: StopBasicInfo;
    /** @format date-time */
    to?: string;
    type: ScheduledAuctionType;
}

export interface ScheduledAuctionPaymentInfo {
    amount: MoneyRange;
    type: PassengerPaymentType;
}

export type ScheduledAuctionType =
    | "TAXI_PREORDER"
    | "DELIVERY"
    | "DELIVERY_COD"
    | "MULTIPLE_DELIVERY"
    | "MULTIPLE_DELIVERY_COD";

export interface ScheduledAuctionsResponse {
    auctions: ScheduledAuction[];
    /** Placeholder for ISO 8601 duration string */
    validFor: Duration;
    /** @format int32 */
    validMeters: number;
}

export type ScheduledMultipleAuctions = UtilRequiredKeys<ScheduledAuction, "location"> & {
    items: ScheduledAuctionDetail[];
};

export type ScheduledSingleAuction = UtilRequiredKeys<ScheduledAuction, "location"> & {
    info: ScheduledAuctionDetail;
};

export type SelectCarAction = DriverStateAction & {
    /** Searchable cars to be selected */
    selectableCars: SelectableCar[];
};

/** Searchable cars to be selected */
export interface SelectableCar {
    chips: Chip[];
    id: string;
    licensePlate: string;
    shown: boolean;
    title: string;
}

export interface SelectableFleetCar {
    brand: string;
    id: string;
    licencePlate: string;
    model: string;
}

export interface SelectableFleetCarsResponse {
    cars: SelectableFleetCar[];
}

export interface SelectableItem {
    key: string;
    textInput: boolean;
    title: string;
}

export type SendEmailHelpOption = UtilRequiredKeys<HelpOption, "label"> & {
    prefilledBody?: string;
    prefilledSubject?: string;
    to: string;
};

export type SendFeedbackHelpOption = UtilRequiredKeys<HelpOption, "label">;

export interface SetAuthorizedRecipient {
    name: string;
}

export type SetBankAccountAction = DriverStateAction;

export interface SetDescriptionNote {
    /** It's possible to delete the description therefore nullable */
    description?: string;
}

export type SetPaymentTerminalAction = DriverStateAction;

export interface SetPaymentTerminalEnabled {
    enabled: boolean;
}

export interface SetPhoneNumber {
    /**
     * @minLength 4
     * @maxLength 15
     */
    phoneNumber: string;
}

export interface ShipmentReturnGroup {
    name: string;
    shipments: ReturningShipmentDetail[];
}

export interface ShipmentReturns {
    groups: ShipmentReturnGroup[];
    /** @format int32 */
    parcelCount: number;
}

export interface ShouldVerifyPhoneNumberError {
    phoneNumber: string;
}

export type ShowDriverBanAction = DriverStateAction & {
    ban: DriverBan;
};

export type ShowLinkHelpOption = UtilRequiredKeys<HelpOption, "label"> & {
    url: string;
};

export interface StandardsBanner {
    banner: InfoBanner;
    /** @format date-time */
    validUntil?: string;
}

export interface StarsCount {
    /** @format int32 */
    stars: number;
    /** @format int32 */
    sum: number;
}

export interface StatsFee {
    "@type": string;
}

export interface StatsFeeLevel {
    /** @format int32 */
    maxPoints?: number;
    /** @format int32 */
    minPoints: number;
    percentage: number;
}

export type StatsFlatFee = StatsFee & {
    percentage: number;
};

export type StatsFullPoint = Point & {
    value: ThemedColor;
};

export type StatsHalfPoint = Point & {
    left: ThemedColor;
    right: ThemedColor;
};

export type StatsLevelsFee = StatsFee & {
    levels: StatsFeeLevel[];
};

export interface StatsPoints {
    deliveryPoints: number;
    helpUrl: string;
    regions: StatsPointsRegion[];
    summary: number;
    taxiPoints: number;
}

export interface StatsPointsRegion {
    fee: StatsFee;
    helpUrl?: string;
    pointsForWeek?: PointsForWeek;
    regionName: string;
}

export interface StopBasicInfo {
    label: string;
    subtitle: string;
    title: string;
}

export interface StopCollectOnDelivery {
    paymentType: DeliveryPaymentType;
    price: Money;
}

export interface SupportCenterAction {
    "@type": string;
    icon: ThemedIcon;
    subtitle: string;
    title: string;
}

export interface SupportCenterOverview {
    actions: (ChangeVehicleAction | GoToUrlAction | ReportBugAction)[];
    customerSupportWorkingHours: string;
    helplinePhoneNumber: string;
    messageOverview?: MessageOverview;
}

export interface SwitchToOnlineRequest {
    "@type": string;
    collectOnDeliveryStatus?: GpCollectOnDeliveryStatus;
    currentPosition: CoordinatesWithBearing;
}

export type SwitchToOnlineWithCarRequest = UtilRequiredKeys<
    SwitchToOnlineRequest,
    "currentPosition"
> & {
    carId: string;
};

export type SwitchToOnlineWithoutCarRequest = UtilRequiredKeys<
    SwitchToOnlineRequest,
    "currentPosition"
>;

export interface SwitchToStreetPickupRequest {
    currentPosition: CoordinatesWithBearing;
    searchableDestination: AddressWithCoordinatesV2;
}

export type TaxIdPrefillType = "BIRTH_NUMBER" | "COMPANY_ID" | "FROM_RESPONSE" | "NONE";

export type Taxi = UtilRequiredKeys<HistoryRide, "id" | "orderedAt" | "paymentType" | "status"> & {
    confirmationOfTransportUrl?: string;
    stops: TaxiHistoryRideStop[];
};

export interface TaxiCoefficientResponse {
    currentCoefficient: number;
    peakTimeRanges: TaxiPeakDayRange[];
    regionName: string;
    url: string;
}

export interface TaxiHistoryRideStop {
    /** @format date-time */
    finishedAt?: string;
    label: string;
    subtitle: string;
    title: string;
}

export type TaxiInquiry = PushMsg & {
    inquiry: Inquiry;
    taxiOrder: InquiryTaxiOrderInfo;
};

export type TaxiLegDestinationArrived = LegDestinationArrived;

export type TaxiLegFailure = LegFinish & {
    comment?: string;
    photo?: Base64Content;
    reason: string;
};

export type TaxiLegFinish = LegFinish;

export type TaxiLegStart = LegStart;

export interface TaxiPeakDayRange {
    /** Placeholder for java.time.DayOfWeek documentation */
    dayFrom: DayOfWeek;
    /** Placeholder for java.time.DayOfWeek documentation */
    dayTo: DayOfWeek;
    timeRanges: TaxiPeakTimeRange[];
}

export interface TaxiPeakTimeRange {
    coefficient: number;
    timeFrom: LocalTime;
    timeTo: LocalTime;
}

export interface TaxiPosition {
    position: Position;
}

export type TaxiRouteChangedMsg = PushMsg & {
    distanceKm?: number;
    /** Placeholder for ISO 8601 duration string */
    duration?: Duration;
    passengerName: string;
    price?: Money;
    rideId: string;
    stops: RouteChangedStop[];
};

export type TaxiStatus = "OFF" | "SERVING" | "AVAILABLE" | "STREETPICKUP";

export interface TestDefaultEnumResponse {
    enumValue?: TestEnum;
    testName: string;
}

/** @default "ONE" */
export type TestEnum = "ONE" | "TWO";

export interface TestScore {
    /** @format int32 */
    correct: number;
    /** @format int32 */
    count: number;
}

export interface TextSection {
    text: string;
    title: string;
}

export interface ThemedColor {
    dark: B4FColor;
    light: B4FColor;
}

export interface ThemedIcon {
    darkUrl: string;
    lightUrl: string;
}

export interface TimeInterval {
    /** @format date-time */
    from: string;
    /** @format date-time */
    to: string;
}

export interface TipPaymentDisclaimer {
    banner: InfoBanner;
    linkTitle: string;
    modal: ModalWindow;
}

export interface TracePoint {
    location: CoordinatesWithBearingAndPrecision;
    /** @format date-time */
    timestamp: string;
}

export interface UnfulfilledStandards {
    banners: StandardsBanner[];
    /** Show only if any valid banner is displayed */
    unfulfilledDisclaimer: string;
}

export interface UpdateDriverBankAccountRequest {
    accountNumber: string;
    /**
     * @minLength 0
     * @maxLength 100
     */
    holderName: string;
}

export interface UpdateDriverCompanyRequest {
    billingAddress?: DriverCompanyAddress;
    /**
     * @minLength 0
     * @maxLength 50
     */
    companyId: string;
    /**
     * @minLength 0
     * @maxLength 500
     */
    companyName: string;
    /**
     * @minLength 0
     * @maxLength 500
     */
    displayName?: string;
    paysVAT?: boolean;
    /**
     * @minLength 0
     * @maxLength 50
     */
    taxId?: string;
}

export interface ValidateLicensePlateRequest {
    licensePlate: string;
}

export interface ValidateLicensePlateResponse {
    licensePlate: string;
    valid: boolean;
}

export interface VerificationCodeParams {
    /** @format int32 */
    codeLength: number;
    codeType: VerificationCodeType;
}

export type VerificationCodeType = "NUMERIC" | "ALPHANUMERIC";

export type VerifyPhoneNumberAction = DriverStateAction & {
    currentPhoneNumber: string;
};

export interface VoiceNote {
    /** @format int32 */
    distanceInMeters?: number;
    message: string;
    trigger: VoiceNoteTrigger;
}

export interface VoiceNoteSettings {
    enabled: boolean;
}

export type VoiceNoteTrigger = "ARRIVAL_STARTED" | "BEFORE_DESTINATION" | "END_OF_RIDE";

export interface WebhookRegistrationRequest {
    email: string;
    /**
     * @minLength 0
     * @maxLength 100
     */
    firstName: string;
    /**
     * @minLength 0
     * @maxLength 100
     */
    lastName: string;
    /**
     * @minLength 4
     * @maxLength 15
     */
    phoneNumber: string;
    workingCity: string;
}

export interface WeeklyStatsResponse {
    periodTitle: string;
    points: StatsPoints;
    regions: string[];
}

export interface WorkingCity {
    city: string;
    countryCode: string;
}

export type WriteableTipMode = EndOfRideTipMode;

/** Placeholder for ISO 8601 duration string */
export interface YearMonth {
    yearMonth: string;
}

export type YearMonthHistoryOption = HistoryOption & {
    /** Placeholder for ISO 8601 duration string */
    month: YearMonth;
};

export interface YellowCard {
    /** @pattern [0-9]{1,6} */
    number: string;
    photo: string;
    /** @format date */
    validity: string;
}

import type {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    HeadersDefaults,
    ResponseType,
} from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
    extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseType;
    /** request body */
    body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown>
    extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
    securityWorker?: (
        securityData: SecurityDataType | null
    ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
    secure?: boolean;
    format?: ResponseType;
}

export enum ContentType {
    Json = "application/json",
    FormData = "multipart/form-data",
    UrlEncoded = "application/x-www-form-urlencoded",
    Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
    public instance: AxiosInstance;
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
    private secure?: boolean;
    private format?: ResponseType;

    constructor({
        securityWorker,
        secure,
        format,
        ...axiosConfig
    }: ApiConfig<SecurityDataType> = {}) {
        this.instance = axios.create({
            ...axiosConfig,
            baseURL: axiosConfig.baseURL || "http://localhost",
        });
        this.secure = secure;
        this.format = format;
        this.securityWorker = securityWorker;
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected mergeRequestParams(
        params1: AxiosRequestConfig,
        params2?: AxiosRequestConfig
    ): AxiosRequestConfig {
        const method = params1.method || (params2 && params2.method);

        return {
            ...this.instance.defaults,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...((method &&
                    this.instance.defaults.headers[
                        method.toLowerCase() as keyof HeadersDefaults
                    ]) ||
                    {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        };
    }

    protected stringifyFormItem(formItem: unknown) {
        if (typeof formItem === "object" && formItem !== null) {
            return JSON.stringify(formItem);
        } else {
            return `${formItem}`;
        }
    }

    protected createFormData(input: Record<string, unknown>): FormData {
        return Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key];
            const propertyContent: any[] = property instanceof Array ? property : [property];

            for (const formItem of propertyContent) {
                const isFileType = formItem instanceof Blob || formItem instanceof File;
                formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
            }

            return formData;
        }, new FormData());
    }

    public request = async <T = any, _E = any>({
        secure,
        path,
        type,
        query,
        format,
        body,
        ...params
    }: FullRequestParams): Promise<AxiosResponse<T>> => {
        const secureParams =
            ((typeof secure === "boolean" ? secure : this.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const responseFormat = format || this.format || undefined;

        if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
            body = this.createFormData(body as Record<string, unknown>);
        }

        if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
            body = JSON.stringify(body);
        }

        return this.instance.request({
            ...requestParams,
            headers: {
                ...(requestParams.headers || {}),
                ...(type && type !== ContentType.FormData ? {"Content-Type": type} : {}),
            },
            params: query,
            responseType: responseFormat,
            data: body,
            url: path,
        });
    };
}

/**
 * @title Driver B4F
 * @baseUrl http://localhost
 * @contact Team Kaizen
 *
 * Driver backend for frontend API documentation
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
    drv = {
        /**
         * No description
         *
         * @tags driver-admin-controller
         * @name ResetVoiceNotes
         * @request POST:/drv/admin/drivers/{driverId}/reset-voice-notes
         */
        resetVoiceNotes: (driverId: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/admin/drivers/${driverId}/reset-voice-notes`,
                method: "POST",
                ...params,
            }),

        /**
         * No description
         *
         * @tags alert-controller
         * @name GetAlertList
         * @request GET:/drv/alerts
         */
        getAlertList: (
            query: {
                /**
                 * @format int32
                 * @max 100
                 */
                limit: number;
                next?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<DriverAlerts, void>({
                path: `/drv/alerts`,
                method: "GET",
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags alert-controller
         * @name GetAlert
         * @request GET:/drv/alerts/{alertId}
         */
        getAlert: (alertId: string, params: RequestParams = {}) =>
            this.request<DriverAlertDetail, void>({
                path: `/drv/alerts/${alertId}`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags alert-controller
         * @name RespondToAlert
         * @request POST:/drv/alerts/{alertId}/comments
         */
        respondToAlert: (alertId: string, data: AlertCommentPayload, params: RequestParams = {}) =>
            this.request<DriverAlertDetail, void>({
                path: `/drv/alerts/${alertId}/comments`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags alert-controller
         * @name MarkAlertRead
         * @request POST:/drv/alerts/{alertId}/read
         */
        markAlertRead: (alertId: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/alerts/${alertId}/read`,
                method: "POST",
                ...params,
            }),

        /**
         * No description
         *
         * @tags app-configuration-controller
         * @name GetAppConfiguration
         * @request GET:/drv/app-configuration
         */
        getAppConfiguration: (params: RequestParams = {}) =>
            this.request<DriverAppConfiguration, void>({
                path: `/drv/app-configuration`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags app-configuration-controller
         * @name SetLiteAppSettings
         * @request POST:/drv/app-configuration/liteapp
         */
        setLiteAppSettings: (data: LiteAppSettings, params: RequestParams = {}) =>
            this.request<DriverAppConfiguration, void>({
                path: `/drv/app-configuration/liteapp`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags app-configuration-controller
         * @name SetVoiceNoteSettings
         * @request POST:/drv/app-configuration/voice-notes
         */
        setVoiceNoteSettings: (data: VoiceNoteSettings, params: RequestParams = {}) =>
            this.request<DriverAppConfiguration, void>({
                path: `/drv/app-configuration/voice-notes`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags app-params-controller
         * @name GetLoggedInAppParams
         * @request GET:/drv/app-params
         */
        getLoggedInAppParams: (params: RequestParams = {}) =>
            this.request<LoggedInAppParams, void>({
                path: `/drv/app-params`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags app-params-controller
         * @name GetAnonymousAppParams
         * @request GET:/drv/app-params/anonymous
         */
        getAnonymousAppParams: (params: RequestParams = {}) =>
            this.request<AnonymousAppParams, void>({
                path: `/drv/app-params/anonymous`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags auth-controller
         * @name Login
         * @request POST:/drv/auth/login
         */
        login: (data: LoginRequest, params: RequestParams = {}) =>
            this.request<LoginResponse, void>({
                path: `/drv/auth/login`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags billing-controller
         * @name GetCurrentPayout
         * @request GET:/drv/billing/payouts/current
         */
        getCurrentPayout: (params: RequestParams = {}) =>
            this.request<DriverCurrentPayoutResponse, void>({
                path: `/drv/billing/payouts/current`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags billing-controller
         * @name GetLastPayouts
         * @request GET:/drv/billing/payouts/history
         */
        getLastPayouts: (params: RequestParams = {}) =>
            this.request<DriverLastPayoutsResponse, void>({
                path: `/drv/billing/payouts/history`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags chip-controller
         * @name GetChipsForPreview
         * @request GET:/drv/chips/preview
         */
        getChipsForPreview: (params: RequestParams = {}) =>
            this.request<ChipPreviewResponse, void>({
                path: `/drv/chips/preview`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-cod-controller
         * @name GetDriverDebtInfo
         * @request GET:/drv/collect-on-delivery/debt
         */
        getDriverDebtInfo: (params: RequestParams = {}) =>
            this.request<CollectOnDeliveryDebtInfoResponse, void>({
                path: `/drv/collect-on-delivery/debt`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-cod-controller
         * @name PromiseDriverDebtPayment
         * @request POST:/drv/collect-on-delivery/debt
         */
        promiseDriverDebtPayment: (
            data: CollectOnDeliveryPromisePaymentRequest,
            params: RequestParams = {}
        ) =>
            this.request<CollectOnDeliveryDebtInfoResponse, void>({
                path: `/drv/collect-on-delivery/debt`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-cod-controller
         * @name GetPaymentInstructions
         * @request GET:/drv/collect-on-delivery/debt/payment-instructions
         */
        getPaymentInstructions: (params: RequestParams = {}) =>
            this.request<CollectOnDeliveryPaymentInstructionsResponse, void>({
                path: `/drv/collect-on-delivery/debt/payment-instructions`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-cod-controller
         * @name CancelDriverCreditPromise
         * @request DELETE:/drv/collect-on-delivery/debt/{idDebtPromise}
         */
        cancelDriverCreditPromise: (idDebtPromise: number, params: RequestParams = {}) =>
            this.request<CollectOnDeliveryDebtInfoResponse, void>({
                path: `/drv/collect-on-delivery/debt/${idDebtPromise}`,
                method: "DELETE",
                ...params,
            }),

        /**
         * No description
         *
         * @tags desired-destination-controller
         * @name DeleteDesiredDestination
         * @request DELETE:/drv/desiredDestination
         */
        deleteDesiredDestination: (params: RequestParams = {}) =>
            this.request<DesiredDestinationState, void>({
                path: `/drv/desiredDestination`,
                method: "DELETE",
                ...params,
            }),

        /**
         * No description
         *
         * @tags desired-destination-controller
         * @name GetDesiredDestination
         * @request GET:/drv/desiredDestination
         */
        getDesiredDestination: (params: RequestParams = {}) =>
            this.request<DesiredDestinationState, void>({
                path: `/drv/desiredDestination`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags desired-destination-controller
         * @name SetDesiredDestination
         * @request POST:/drv/desiredDestination
         */
        setDesiredDestination: (data: DesiredDestination, params: RequestParams = {}) =>
            this.request<DesiredDestinationState, void>({
                path: `/drv/desiredDestination`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-controller
         * @name UpdateDriverBankAccount
         * @request PUT:/drv/driver/bank-account
         */
        updateDriverBankAccount: (
            data: UpdateDriverBankAccountRequest,
            params: RequestParams = {}
        ) =>
            this.request<Profile, void>({
                path: `/drv/driver/bank-account`,
                method: "PUT",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-controller
         * @name UpdateDriverCompany
         * @request PUT:/drv/driver/company
         */
        updateDriverCompany: (data: UpdateDriverCompanyRequest, params: RequestParams = {}) =>
            this.request<Profile, void>({
                path: `/drv/driver/company`,
                method: "PUT",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-controller
         * @name Profile
         * @request GET:/drv/driver/profile
         */
        profile: (
            query?: {
                fresh?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<Profile, void>({
                path: `/drv/driver/profile`,
                method: "GET",
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-state-controller
         * @name GetDriverStatus
         * @request GET:/drv/driver/state
         */
        getDriverStatus: (
            query: {
                /** @format double */
                lat: number;
                /** @format double */
                lon: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<DriverState, void>({
                path: `/drv/driver/state`,
                method: "GET",
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-state-controller
         * @name ChangeDriverStatus
         * @request POST:/drv/driver/state/change
         */
        changeDriverStatus: (data: ChangeDriverStatusRequest, params: RequestParams = {}) =>
            this.request<DriverState, void>({
                path: `/drv/driver/state/change`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-state-v-2-controller
         * @name GetDriverStateV2
         * @request GET:/drv/driver/stateV2
         */
        getDriverStateV2: (params: RequestParams = {}) =>
            this.request<DriverStateV2, void>({
                path: `/drv/driver/stateV2`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-state-v-2-controller
         * @name SwitchToOffline
         * @request POST:/drv/driver/stateV2/offline
         */
        switchToOffline: (params: RequestParams = {}) =>
            this.request<DriverStateV2, void>({
                path: `/drv/driver/stateV2/offline`,
                method: "POST",
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-state-v-2-controller
         * @name SwitchToOnline
         * @request POST:/drv/driver/stateV2/online
         */
        switchToOnline: (data: SwitchToOnlineRequest, params: RequestParams = {}) =>
            this.request<DriverStateV2, void | ShouldVerifyPhoneNumberError>({
                path: `/drv/driver/stateV2/online`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-state-v-2-controller
         * @name PauseInquiries
         * @request POST:/drv/driver/stateV2/pause-inquiries
         */
        pauseInquiries: (
            query: {
                pause: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<void, void>({
                path: `/drv/driver/stateV2/pause-inquiries`,
                method: "POST",
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-state-v-2-controller
         * @name CreatePosition
         * @request POST:/drv/driver/stateV2/position
         */
        createPosition: (data: CreatePositionRequest, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/driver/stateV2/position`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-state-v-2-controller
         * @name SwitchToStreetPickup
         * @request POST:/drv/driver/stateV2/street-pickup
         */
        switchToStreetPickup: (data: SwitchToStreetPickupRequest, params: RequestParams = {}) =>
            this.request<DriverStateV2, void>({
                path: `/drv/driver/stateV2/street-pickup`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-controller-v-2
         * @name UpdateDriverBankAccountV2
         * @request PUT:/drv/driver/v2/bank-account
         */
        updateDriverBankAccountV2: (
            data: UpdateDriverBankAccountRequest,
            params: RequestParams = {}
        ) =>
            this.request<ProfileV2, void>({
                path: `/drv/driver/v2/bank-account`,
                method: "PUT",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-controller-v-2
         * @name LookupDriverCompany
         * @request GET:/drv/driver/v2/companies/lookup
         */
        lookupDriverCompany: (
            query: {
                companyId: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<DriverCompany, void | DriverCompany>({
                path: `/drv/driver/v2/companies/lookup`,
                method: "GET",
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-controller-v-2
         * @name UpdateDriverCompanyV2
         * @request PUT:/drv/driver/v2/company
         */
        updateDriverCompanyV2: (data: UpdateDriverCompanyRequest, params: RequestParams = {}) =>
            this.request<ProfileV2, void>({
                path: `/drv/driver/v2/company`,
                method: "PUT",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-controller-v-2
         * @name SetPaymentTerminalEnabled
         * @request PUT:/drv/driver/v2/payment-terminal
         */
        setPaymentTerminalEnabled: (data: SetPaymentTerminalEnabled, params: RequestParams = {}) =>
            this.request<ProfileV2, void>({
                path: `/drv/driver/v2/payment-terminal`,
                method: "PUT",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-controller-v-2
         * @name SetPhoneNumber
         * @request PUT:/drv/driver/v2/phone-number
         */
        setPhoneNumber: (data: SetPhoneNumber, params: RequestParams = {}) =>
            this.request<ProfileV2, void>({
                path: `/drv/driver/v2/phone-number`,
                method: "PUT",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-controller-v-2
         * @name PickCar
         * @request POST:/drv/driver/v2/pick-car
         */
        pickCar: (data: PickCarRequest, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/driver/v2/pick-car`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-controller-v-2
         * @name ProfileV2
         * @request GET:/drv/driver/v2/profile
         */
        profileV2: (params: RequestParams = {}) =>
            this.request<ProfileV2, void>({
                path: `/drv/driver/v2/profile`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-search-inquiry-controller
         * @name AcceptInquiry
         * @request POST:/drv/driverSearch/inquiry/accept
         */
        acceptInquiry: (data: DrvAcceptInquiryRequest, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/driverSearch/inquiry/accept`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-search-inquiry-controller
         * @name AckInquiry
         * @request POST:/drv/driverSearch/inquiry/ack
         */
        ackInquiry: (data: DrvAckInquiryRequest, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/driverSearch/inquiry/ack`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-search-inquiry-controller
         * @name IgnoreInquiry
         * @request POST:/drv/driverSearch/inquiry/ignore
         */
        ignoreInquiry: (data: DrvIgnoreInquiryRequest, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/driverSearch/inquiry/ignore`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-search-inquiry-controller
         * @name RejectInquiry
         * @request POST:/drv/driverSearch/inquiry/reject
         */
        rejectInquiry: (data: DrvRejectInquiryRequest, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/driverSearch/inquiry/reject`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-search-inquiry-controller
         * @name InquiryRoutes
         * @request GET:/drv/driverSearch/inquiry/{inquiryId}/routes
         */
        inquiryRoutes: (inquiryId: string, params: RequestParams = {}) =>
            this.request<InquiryRouteShapes, void>({
                path: `/drv/driverSearch/inquiry/${inquiryId}/routes`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags drivers-around-controller
         * @name GetDriversAround
         * @request GET:/drv/drivers-around
         */
        getDriversAround: (
            query: {
                /** @format double */
                lat: number;
                /** @format double */
                lon: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<DriversAroundResponse, void>({
                path: `/drv/drivers-around`,
                method: "GET",
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags events-controller
         * @name LogEvent
         * @request POST:/drv/events
         */
        logEvent: (data: Event, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/events`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-fleet-controller
         * @name GetDriversFleetCars
         * @request GET:/drv/fleet/cars
         * @deprecated
         */
        getDriversFleetCars: (params: RequestParams = {}) =>
            this.request<SelectableFleetCarsResponse, void>({
                path: `/drv/fleet/cars`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-fleet-controller
         * @name PickFleetCar
         * @request PUT:/drv/fleet/cars/{carId}
         * @deprecated
         */
        pickFleetCar: (carId: string, data: FleetCarPick, params: RequestParams = {}) =>
            this.request<FleetCarPickResponse, void>({
                path: `/drv/fleet/cars/${carId}`,
                method: "PUT",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags lite-app-controller
         * @name Configuration
         * @request GET:/drv/liteapp/app-configuration
         */
        configuration: (params: RequestParams = {}) =>
            this.request<DrvLiteAppConfiguration, void>({
                path: `/drv/liteapp/app-configuration`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags lite-app-controller
         * @name LiteProfile
         * @request GET:/drv/liteapp/profile
         */
        liteProfile: (params: RequestParams = {}) =>
            this.request<DrvLiteDriverProfile, void>({
                path: `/drv/liteapp/profile`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags lite-app-controller
         * @name LitePushUnregister
         * @request DELETE:/drv/liteapp/push/registration
         */
        litePushUnregister: (params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/liteapp/push/registration`,
                method: "DELETE",
                ...params,
            }),

        /**
         * No description
         *
         * @tags lite-app-controller
         * @name LitePushRegister
         * @request POST:/drv/liteapp/push/registration
         */
        litePushRegister: (
            data: DrvLiteAppNotificationRegistrationRequest,
            params: RequestParams = {}
        ) =>
            this.request<void, void>({
                path: `/drv/liteapp/push/registration`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags location-controller
         * @name LocationSuggestions1
         * @request GET:/drv/location-suggestions
         * @deprecated
         */
        locationSuggestions1: (
            query: {
                q: string;
                /** @format double */
                lat?: number;
                /** @format double */
                lon?: number;
                lang?: string;
                typeFilter?: LocationSuggestionType[];
            },
            params: RequestParams = {}
        ) =>
            this.request<LocationSuggestionsResponse, void>({
                path: `/drv/location-suggestions`,
                method: "GET",
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags location-controller
         * @name LocationSuggestionDetail1
         * @request GET:/drv/location-suggestions/{placeId}
         * @deprecated
         */
        locationSuggestionDetail1: (placeId: string, params: RequestParams = {}) =>
            this.request<AddressWithCoordinates, void>({
                path: `/drv/location-suggestions/${placeId}`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags location-controller
         * @name ReverseGeocode1
         * @request GET:/drv/location/reverse-geocode
         * @deprecated
         */
        reverseGeocode1: (
            query: {
                /** @format double */
                lat: number;
                /** @format double */
                lon: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<GeoPoint, void>({
                path: `/drv/location/reverse-geocode`,
                method: "GET",
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags location-controller-v-2
         * @name ReverseGeocode
         * @request GET:/drv/locations/v2/reverse-geocode
         */
        reverseGeocode: (
            query: {
                /** @format double */
                lat: number;
                /** @format double */
                lon: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<AddressWithCoordinatesV2, void>({
                path: `/drv/locations/v2/reverse-geocode`,
                method: "GET",
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags location-controller-v-2
         * @name LocationSuggestions
         * @request GET:/drv/locations/v2/suggestions
         */
        locationSuggestions: (
            query: {
                q: string;
                /** @format double */
                lat?: number;
                /** @format double */
                lon?: number;
                lang?: string;
                typeFilter?: LocationSuggestionType[];
            },
            params: RequestParams = {}
        ) =>
            this.request<LocationSuggestionsResponseV2, void>({
                path: `/drv/locations/v2/suggestions`,
                method: "GET",
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags location-controller-v-2
         * @name LocationSuggestionDetail
         * @request GET:/drv/locations/v2/suggestions/{placeId}
         */
        locationSuggestionDetail: (placeId: string, params: RequestParams = {}) =>
            this.request<AddressWithCoordinatesV2, void>({
                path: `/drv/locations/v2/suggestions/${placeId}`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags menu-controller
         * @name GetMenu
         * @request GET:/drv/menu
         */
        getMenu: (
            query: {
                /** @format double */
                lat: number;
                /** @format double */
                lon: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<Menu, void>({
                path: `/drv/menu`,
                method: "GET",
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags menu-controller
         * @name GetMenuHelpOptions
         * @request GET:/drv/menu/help-options
         */
        getMenuHelpOptions: (params: RequestParams = {}) =>
            this.request<HelpOptions, void>({
                path: `/drv/menu/help-options`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags menu-controller
         * @name InitiateHelplineCall
         * @request POST:/drv/menu/helpline/call
         */
        initiateHelplineCall: (params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/menu/helpline/call`,
                method: "POST",
                ...params,
            }),

        /**
         * No description
         *
         * @tags menu-controller
         * @name GetMenuStatistics
         * @request GET:/drv/menu/statistics
         * @deprecated
         */
        getMenuStatistics: (
            query: {
                /** @format double */
                lat: number;
                /** @format double */
                lon: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<MenuStatistics, void>({
                path: `/drv/menu/statistics`,
                method: "GET",
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags notification-registration-controller
         * @name PushUnregister
         * @request DELETE:/drv/notification/registration
         */
        pushUnregister: (params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/notification/registration`,
                method: "DELETE",
                ...params,
            }),

        /**
         * No description
         *
         * @tags notification-registration-controller
         * @name PushRegister
         * @request POST:/drv/notification/registration
         */
        pushRegister: (data: DrvNotificationRegistrationRequest, params: RequestParams = {}) =>
            this.request<PushRegistrationResponse, void>({
                path: `/drv/notification/registration`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags reactivation-controller
         * @name Finish
         * @request POST:/drv/reactivation/finish
         */
        finish: (params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/reactivation/finish`,
                method: "POST",
                ...params,
            }),

        /**
         * No description
         *
         * @tags reactivation-controller
         * @name GetState
         * @request GET:/drv/reactivation/state
         */
        getState: (params: RequestParams = {}) =>
            this.request<DriverReactivationState, void>({
                path: `/drv/reactivation/state`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags reactivation-controller
         * @name PutState
         * @request PUT:/drv/reactivation/state
         */
        putState: (data: DriverReactivationState, params: RequestParams = {}) =>
            this.request<DriverReactivationState, void>({
                path: `/drv/reactivation/state`,
                method: "PUT",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-registration-controller
         * @name GetCities
         * @request GET:/drv/registration/cities
         */
        getCities: (params: RequestParams = {}) =>
            this.request<City[], void>({
                path: `/drv/registration/cities`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-registration-controller
         * @name LookupCompany
         * @request GET:/drv/registration/companies/lookup
         */
        lookupCompany: (
            query: {
                companyId: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<CompanyInformation, void>({
                path: `/drv/registration/companies/lookup`,
                method: "GET",
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-registration-controller
         * @name RegisterV2
         * @request POST:/drv/registration/v2
         */
        registerV2: (data: RegistrationRequestV2, params: RequestParams = {}) =>
            this.request<LoginResponse, void>({
                path: `/drv/registration/v2`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-registration-controller
         * @name FinishRegistration
         * @request POST:/drv/registration/v2/finish
         */
        finishRegistration: (params: RequestParams = {}) =>
            this.request<LoginResponse, void>({
                path: `/drv/registration/v2/finish`,
                method: "POST",
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-registration-controller
         * @name GetRegistrationState
         * @request GET:/drv/registration/v2/state
         */
        getRegistrationState: (params: RequestParams = {}) =>
            this.request<DriverInformation, void>({
                path: `/drv/registration/v2/state`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-registration-controller
         * @name PutRegistrationState
         * @request PUT:/drv/registration/v2/state
         */
        putRegistrationState: (data: DriverInformation, params: RequestParams = {}) =>
            this.request<DriverInformation, void>({
                path: `/drv/registration/v2/state`,
                method: "PUT",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-registration-controller
         * @name WebhookRegistration
         * @request POST:/drv/registration/v2/webhook
         */
        webhookRegistration: (data: WebhookRegistrationRequest, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/registration/v2/webhook`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-registration-controller
         * @name ValidateLicensePlate
         * @request POST:/drv/registration/validations/licence-plate
         */
        validateLicensePlate: (data: ValidateLicensePlateRequest, params: RequestParams = {}) =>
            this.request<ValidateLicensePlateResponse, void>({
                path: `/drv/registration/validations/licence-plate`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-registration-controller
         * @name PhoneNumberValidation
         * @request POST:/drv/registration/validations/phoneNumber
         */
        phoneNumberValidation: (data: PhoneValidationRequest, params: RequestParams = {}) =>
            this.request<PhoneValidationResponse, void>({
                path: `/drv/registration/validations/phoneNumber`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-ride-coefficient-controller
         * @name GetTaxiCoefficient
         * @request GET:/drv/ride-coefficient/taxi
         */
        getTaxiCoefficient: (
            query?: {
                id?: string;
                placeName?: string;
                /** @format int32 */
                precision?: number;
                /** @format int32 */
                bearing?: number;
                /** @format double */
                lat?: number;
                /** @format double */
                lon?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<TaxiCoefficientResponse, void | TaxiCoefficientResponse>({
                path: `/drv/ride-coefficient/taxi`,
                method: "GET",
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-controller
         * @name ActiveRide
         * @request GET:/drv/rides/active
         */
        activeRide: (params: RequestParams = {}) =>
            this.request<Ride, void | Ride>({
                path: `/drv/rides/active`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-controller
         * @name AllowOrderIntoRide
         * @request POST:/drv/rides/active/allowOrderIntoRide
         */
        allowOrderIntoRide: (data: OrderIntoRide, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/rides/active/allowOrderIntoRide`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-controller
         * @name GetRideCancelInfo
         * @request GET:/drv/rides/active/cancelInfo
         */
        getRideCancelInfo: (params: RequestParams = {}) =>
            this.request<LegCancelInfo, void>({
                path: `/drv/rides/active/cancelInfo`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags end-of-ride-controller
         * @name GetEndOfRideInfo1
         * @request GET:/drv/rides/active/end-of-ride-info
         * @deprecated
         */
        getEndOfRideInfo1: (params: RequestParams = {}) =>
            this.request<EndOfRideInfo, void>({
                path: `/drv/rides/active/end-of-ride-info`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags end-of-ride-v-2-controller
         * @name GetEndOfRideInfo
         * @request GET:/drv/rides/active/end-of-ride-v2/info
         */
        getEndOfRideInfo: (params: RequestParams = {}) =>
            this.request<EndOfRideInfoV2, void>({
                path: `/drv/rides/active/end-of-ride-v2/info`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags end-of-ride-v-2-controller
         * @name ConfirmRidePayment
         * @request POST:/drv/rides/active/end-of-ride-v2/payment
         */
        confirmRidePayment: (data: ConfirmRidePaymentRequest, params: RequestParams = {}) =>
            this.request<EndOfRideRatingInfo, void>({
                path: `/drv/rides/active/end-of-ride-v2/payment`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags end-of-ride-v-2-controller
         * @name RateActiveRide
         * @request POST:/drv/rides/active/end-of-ride-v2/rating
         */
        rateActiveRide: (data: ConfirmRideRatingRequest, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/rides/active/end-of-ride-v2/rating`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags end-of-ride-controller
         * @name FinalizeActiveRide
         * @request POST:/drv/rides/active/finalize
         * @deprecated
         */
        finalizeActiveRide: (data: RideFinalizationRequest, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/rides/active/finalize`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-controller
         * @name ArrivedAtDestination
         * @request POST:/drv/rides/active/legs/{legId}/arrivedAtDestination
         */
        arrivedAtDestination: (
            legId: string,
            data: LegDestinationArrived,
            params: RequestParams = {}
        ) =>
            this.request<Ride, void | Ride>({
                path: `/drv/rides/active/legs/${legId}/arrivedAtDestination`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-controller
         * @name Call
         * @request POST:/drv/rides/active/legs/{legId}/call
         */
        call: (legId: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/rides/active/legs/${legId}/call`,
                method: "POST",
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-controller
         * @name CancelLeg
         * @request POST:/drv/rides/active/legs/{legId}/cancel
         */
        cancelLeg: (legId: string, data: LegFinish, params: RequestParams = {}) =>
            this.request<LegCancelResult, void | LegCancelResult>({
                path: `/drv/rides/active/legs/${legId}/cancel`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-controller
         * @name GetCancelInfo
         * @request GET:/drv/rides/active/legs/{legId}/cancelInfo
         */
        getCancelInfo: (legId: string, params: RequestParams = {}) =>
            this.request<LegCancelInfo, void>({
                path: `/drv/rides/active/legs/${legId}/cancelInfo`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-controller
         * @name FinishLeg
         * @request POST:/drv/rides/active/legs/{legId}/finish
         */
        finishLeg: (legId: string, data: LegFinish, params: RequestParams = {}) =>
            this.request<Ride, void | Ride>({
                path: `/drv/rides/active/legs/${legId}/finish`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-controller
         * @name SetNoteDescription
         * @request PUT:/drv/rides/active/legs/{legId}/notes/description
         */
        setNoteDescription: (legId: string, data: SetDescriptionNote, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/rides/active/legs/${legId}/notes/description`,
                method: "PUT",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-controller
         * @name UploadNotePhoto
         * @request PUT:/drv/rides/active/legs/{legId}/notes/photo
         */
        uploadNotePhoto: (legId: string, data: Base64Content, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/rides/active/legs/${legId}/notes/photo`,
                method: "PUT",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-controller
         * @name SetAuthorizedRecipient
         * @request POST:/drv/rides/active/legs/{legId}/proof-of-delivery/authorized-recipient
         */
        setAuthorizedRecipient: (
            legId: string,
            data: SetAuthorizedRecipient,
            params: RequestParams = {}
        ) =>
            this.request<void, void>({
                path: `/drv/rides/active/legs/${legId}/proof-of-delivery/authorized-recipient`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-controller
         * @name VerifyCode
         * @request POST:/drv/rides/active/legs/{legId}/proof-of-delivery/code-verification
         */
        verifyCode: (legId: string, data: CodeVerificationRequest, params: RequestParams = {}) =>
            this.request<CodeVerificationResponse, void | CodeVerificationResponse>({
                path: `/drv/rides/active/legs/${legId}/proof-of-delivery/code-verification`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-controller
         * @name UploadProofOfDeliveryDocument
         * @request POST:/drv/rides/active/legs/{legId}/proof-of-delivery/document
         */
        uploadProofOfDeliveryDocument: (
            legId: string,
            data: ProofOfDeliveryDocument,
            params: RequestParams = {}
        ) =>
            this.request<void, void>({
                path: `/drv/rides/active/legs/${legId}/proof-of-delivery/document`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-controller
         * @name UploadSignature
         * @request POST:/drv/rides/active/legs/{legId}/proof-of-delivery/signature
         */
        uploadSignature: (legId: string, data: Base64Content, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/rides/active/legs/${legId}/proof-of-delivery/signature`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-controller
         * @name ReorderLeg
         * @request POST:/drv/rides/active/legs/{legId}/reorder
         */
        reorderLeg: (legId: string, data: ReorderLeg, params: RequestParams = {}) =>
            this.request<Ride, void | Ride>({
                path: `/drv/rides/active/legs/${legId}/reorder`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-controller
         * @name StartLeg
         * @request POST:/drv/rides/active/legs/{legId}/start
         */
        startLeg: (legId: string, data: LegStart, params: RequestParams = {}) =>
            this.request<Ride, void | Ride>({
                path: `/drv/rides/active/legs/${legId}/start`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-controller
         * @name ActiveRidePathsV2
         * @request POST:/drv/rides/active/paths
         */
        activeRidePathsV2: (data: TaxiPosition, params: RequestParams = {}) =>
            this.request<RidePaths, void | RidePaths>({
                path: `/drv/rides/active/paths`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags end-of-ride-controller
         * @name PayActiveRide
         * @request POST:/drv/rides/active/pay
         * @deprecated
         */
        payActiveRide: (data: RidePaymentRequest, params: RequestParams = {}) =>
            this.request<FinishingEndOfRideInfo, void>({
                path: `/drv/rides/active/pay`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-history-controller
         * @name GetRidesHistory
         * @request GET:/drv/rides/history
         */
        getRidesHistory: (
            query: {
                /**
                 * @format int32
                 * @max 100
                 */
                limit: number;
                type?: RideType;
                next?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<HistoryRidesResponse, void | HistoryRidesResponse>({
                path: `/drv/rides/history`,
                method: "GET",
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-history-v-2-controller
         * @name GetRideHistoryV2
         * @request GET:/drv/rides/history/v2
         */
        getRideHistoryV2: (
            query: {
                filter: RideHistoryFilter;
            },
            params: RequestParams = {}
        ) =>
            this.request<HistoryRidesV2Response, void>({
                path: `/drv/rides/history/v2`,
                method: "GET",
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-history-v-2-controller
         * @name GetRideHistoryOptions
         * @request GET:/drv/rides/history/v2/options
         */
        getRideHistoryOptions: (params: RequestParams = {}) =>
            this.request<RideHistoryOptions, void>({
                path: `/drv/rides/history/v2/options`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-history-v-2-controller
         * @name GetRideHistoryTypedOptions
         * @request GET:/drv/rides/history/v2/typed-options
         */
        getRideHistoryTypedOptions: (
            query: {
                rideType: RideType;
            },
            params: RequestParams = {}
        ) =>
            this.request<RideHistoryRideTypeOptions, void>({
                path: `/drv/rides/history/v2/typed-options`,
                method: "GET",
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-controller
         * @name InitiateNextRideCall
         * @request POST:/drv/rides/next/call
         */
        initiateNextRideCall: (params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/rides/next/call`,
                method: "POST",
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-controller
         * @name InitiatePreviousRideCall
         * @request POST:/drv/rides/previous/call
         */
        initiatePreviousRideCall: (params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/rides/previous/call`,
                method: "POST",
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-controller
         * @name GetDeliveryRideConfirmation
         * @request GET:/drv/rides/{rideId}/deliveryConfirmation
         */
        getDeliveryRideConfirmation: (rideId: string, params: RequestParams = {}) =>
            this.request<string, void>({
                path: `/drv/rides/${rideId}/deliveryConfirmation`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags scheduled-auctions-controller
         * @name ScheduledRides
         * @request GET:/drv/scheduled-auctions
         */
        scheduledRides: (
            query: {
                /** @format double */
                lat: number;
                /** @format double */
                lon: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<ScheduledAuctionsResponse, void>({
                path: `/drv/scheduled-auctions`,
                method: "GET",
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-statistics-controller
         * @name GetFeedbackStatistics
         * @request GET:/drv/statistics/feedback
         */
        getFeedbackStatistics: (params: RequestParams = {}) =>
            this.request<FeedbackStatistics, void>({
                path: `/drv/statistics/feedback`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-statistics-controller
         * @name GetWeeklyStats
         * @request GET:/drv/statistics/weekly
         */
        getWeeklyStats: (
            query?: {
                /** @format date */
                date?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<WeeklyStatsResponse, void>({
                path: `/drv/statistics/weekly`,
                method: "GET",
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags support-center-controller
         * @name GetSupportCenterOverview
         * @request GET:/drv/support-center/overview
         */
        getSupportCenterOverview: (params: RequestParams = {}) =>
            this.request<SupportCenterOverview, void>({
                path: `/drv/support-center/overview`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags test-exception-controller
         * @name GetTestEnum
         * @request GET:/drv/test-default-enum
         */
        getTestEnum: (params: RequestParams = {}) =>
            this.request<TestDefaultEnumResponse, void>({
                path: `/drv/test-default-enum`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags test-exception-controller
         * @name GetFromFeign
         * @request GET:/drv/test-exception
         */
        getFromFeign: (params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/drv/test-exception`,
                method: "GET",
                ...params,
            }),
    };
    pushNotification = {
        /**
         * No description
         *
         * @tags position-notification-controller
         * @name UpdatedPassengerPosition
         * @request GET:/push-notification/passenger-position
         */
        updatedPassengerPosition: (params: RequestParams = {}) =>
            this.request<PassengerPositionChangedMsgContent, void>({
                path: `/push-notification/passenger-position`,
                method: "GET",
                ...params,
            }),
    };
    pushNotifications = {
        /**
         * No description
         *
         * @tags desired-destination-documentation-controller
         * @name DesiredDestinationState
         * @request GET:/push-notifications/desired-destination/desired-destination-state
         */
        desiredDestinationState: (params: RequestParams = {}) =>
            this.request<DesiredDestinationStateMsg, void>({
                path: `/push-notifications/desired-destination/desired-destination-state`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-search-notification-documentation-controller
         * @name DeliveryInquiry
         * @request GET:/push-notifications/driverSearch/delivery-inquiry
         */
        deliveryInquiry: (params: RequestParams = {}) =>
            this.request<DeliveryInquiry, void>({
                path: `/push-notifications/driverSearch/delivery-inquiry`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-search-notification-documentation-controller
         * @name Inquiry
         * @request GET:/push-notifications/driverSearch/inquiry
         */
        inquiry: (params: RequestParams = {}) =>
            this.request<InquiryV2, void>({
                path: `/push-notifications/driverSearch/inquiry`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-search-notification-documentation-controller
         * @name OfferExpired
         * @request GET:/push-notifications/driverSearch/offer-expired
         */
        offerExpired: (params: RequestParams = {}) =>
            this.request<OfferExpiredMsgContent, void>({
                path: `/push-notifications/driverSearch/offer-expired`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-search-notification-documentation-controller
         * @name OfferRejected
         * @request GET:/push-notifications/driverSearch/offer-rejected
         */
        offerRejected: (params: RequestParams = {}) =>
            this.request<OfferRejectedMsgContent, void>({
                path: `/push-notifications/driverSearch/offer-rejected`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-search-notification-documentation-controller
         * @name TaxiInquiry
         * @request GET:/push-notifications/driverSearch/taxi-inquiry
         */
        taxiInquiry: (params: RequestParams = {}) =>
            this.request<TaxiInquiry, void>({
                path: `/push-notifications/driverSearch/taxi-inquiry`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags driver-lite-app-notification-documentation-controller
         * @name DriverStatusChanged
         * @request GET:/push-notifications/lite/drv/status-changed
         */
        driverStatusChanged: (params: RequestParams = {}) =>
            this.request<DriverStatusChangedMsg, void>({
                path: `/push-notifications/lite/drv/status-changed`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-lite-notification-documentation-controller
         * @name InitiateCall
         * @request GET:/push-notifications/lite/ride/initiate-call
         */
        initiateCall: (params: RequestParams = {}) =>
            this.request<InitiateCallMsg, void>({
                path: `/push-notifications/lite/ride/initiate-call`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags position-notification-controller
         * @name UpdatePosition
         * @request POST:/push-notifications/position
         */
        updatePosition: (data: PositionPush, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/push-notifications/position`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-notification-documentation-controller
         * @name NextRideCreated
         * @request GET:/push-notifications/ride/next-ride-created
         */
        nextRideCreated: (params: RequestParams = {}) =>
            this.request<NextRideCreatedMsg, void>({
                path: `/push-notifications/ride/next-ride-created`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-notification-documentation-controller
         * @name RideCancelled
         * @request GET:/push-notifications/ride/ride-cancelled
         */
        rideCancelled: (params: RequestParams = {}) =>
            this.request<RideCancelledMsg, void>({
                path: `/push-notifications/ride/ride-cancelled`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-notification-documentation-controller
         * @name RideChanged
         * @request GET:/push-notifications/ride/ride-changed
         */
        rideChanged: (params: RequestParams = {}) =>
            this.request<RideChangedMsg, void>({
                path: `/push-notifications/ride/ride-changed`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-notification-documentation-controller
         * @name RidePaymentResult
         * @request GET:/push-notifications/ride/ride-payment-result
         */
        ridePaymentResult: (params: RequestParams = {}) =>
            this.request<RidePaymentResultMsg, void>({
                path: `/push-notifications/ride/ride-payment-result`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-notification-documentation-controller
         * @name RideRouteChanged
         * @request GET:/push-notifications/ride/ride-route-changed
         */
        rideRouteChanged: (params: RequestParams = {}) =>
            this.request<TaxiRouteChangedMsg, void>({
                path: `/push-notifications/ride/ride-route-changed`,
                method: "GET",
                ...params,
            }),

        /**
         * No description
         *
         * @tags ride-notification-documentation-controller
         * @name RideStarted
         * @request GET:/push-notifications/ride/ride-started
         */
        rideStarted: (params: RequestParams = {}) =>
            this.request<RideStartedMsg, void>({
                path: `/push-notifications/ride/ride-started`,
                method: "GET",
                ...params,
            }),
    };
    v2 = {
        /**
         * No description
         *
         * @tags legacy-taxi-state-controller
         * @name OrderToDestination
         * @request POST:/v2/taxis/{taxiId}/ordertodest
         */
        orderToDestination: (taxiId: string, data: Position, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/v2/taxis/${taxiId}/ordertodest`,
                method: "POST",
                body: data,
                type: ContentType.Json,
                ...params,
            }),
    };
}
