import {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {clearStorage} from "./apiClientProvider";

export interface ReactivationErrorModalProps {
    errorMessageId: string;
    showModal: boolean;
}

export function ReactivationErrorModal({errorMessageId, showModal}: ReactivationErrorModalProps) {
    const [show, setShow] = useState(showModal);
    const handleClose = () => {
        setShow(false);
        clearStorage();
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title style={{fontSize: "20px", fontWeight: "500"}}>
                    <FormattedMessage id={errorMessageId} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button
                    variant="light"
                    onClick={handleClose}
                    size="lg"
                    style={{
                        width: "100%",
                        color: "var(--bs-primary)",
                        fontWeight: 500,
                        fontSize: "var(--font-size-text)",
                    }}
                >
                    <FormattedMessage id="ok" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
