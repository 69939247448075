import TagManager from "react-gtm-module";
import {DriverGroup} from "../generated-api/registerApi";

export function logGtm(event: "registration", driverType?: DriverGroup) {
    TagManager.dataLayer({
        dataLayer: {
            event: event,
            user: {
                id: undefined,
                type: "driver",
                driver_type: driverType,
            },
        },
    });
}
