import {Car} from "../generated-api/registerApi";

interface TutorialVideo {
    header: string;
    url: string;
}

export const tutorialUrlTaxi: TutorialVideo[] = [
    {header: "Informace o Liftagu", url: "https://youtu.be/UfZ9mJ5-UoE"},
    {header: "Základy řidičské aplikace", url: "https://youtu.be/G5YUzVSy5t0"},
    {header: "Taxi jízda v aplikaci", url: "https://youtu.be/1O0P4skrpQw"},
    {header: "Standardy", url: "https://youtu.be/_ZYzHzOj8AY"},
    {header: "Výhody řidiče univerzál", url: "https://youtu.be/Z-vClAXIiZw"},
    {header: "Podmínky spolupráce", url: "https://youtu.be/eaiVCTZ4pRg"},
];
export const tutorialUrlDelivery: TutorialVideo[] = [
    {header: "", url: "https://www.youtube.com/embed/Y6P_R3WYrOY"},
];

export const webViewKey = "drv-app";
export const regTokenCookieKey = "registrationToken";
export const reactivationTokenCookieKey = "reactivationToken";
export const minCarYear = 2000;
export const maxTestAttempts = 2;
export const maxRetries = 3;

export const appLink =
    "https://play.google.com/store/apps/details?id=com.adleritech.app.liftago.driver";

export const fuelTypes: Car["fuelType"][] = ["Diesel", "Gasoline", "Gas", "Hybrid", "Electric"];
export const carTypes: Car["type"][] = [
    "Combi",
    "Sedan",
    "Hatchback",
    "Van",
    "MPV",
    "Minibus",
    "SUV",
    "Other",
];
export const colors: Car["color"][] = [
    "BLACK",
    "BLUE",
    "BROWN",
    "GREEN",
    "ORANGE",
    "PURPLE",
    "RED",
    "SILVER",
    "WHITE",
    "YELLOW",
];

export const carManufacturers = [
    "Škoda",
    "Volkswagen",
    "Audi",
    "BMW",
    "Citroën",
    "Dacia",
    "Fiat",
    "Ford",
    "Honda",
    "Hyundai",
    "Kia",
    "Mazda",
    "Mercedes-Benz",
    "Nissan",
    "Opel",
    "Peugeot",
    "Renault",
    "Seat",
    "Toyota",
    "Volvo",
    "Abarth",
    "AC",
    "Acura",
    "Aixam",
    "Alfa Romeo",
    "Alpina",
    "Alpine",
    "AM General Truck",
    "Ariel",
    "ARO",
    "Asia Motors",
    "Aston Martin",
    "Austin",
    "Auverland",
    "Bellier",
    "Bentley",
    "Bugatti",
    "Buick",
    "Cadillac",
    "Casalini",
    "Caterham",
    "Cenntro",
    "Changhe",
    "Chatenet",
    "Chevrolet",
    "Chrysler",
    "Cupra",
    "Daewoo",
    "Daihatsu",
    "Datsun",
    "De Tomaso",
    "Dodge",
    "DongFeng",
    "DS",
    "Eagle",
    "Ferrari",
    "Fisker",
    "GAZ",
    "GMC",
    "Gonow",
    "Gordon",
    "GREAT WALL",
    "Grecav",
    "Gumpert",
    "Holden",
    "Honker",
    "Hummer",
    "Hurtan",
    "Infiniti",
    "Isuzu",
    "Italcar",
    "Iveco",
    "Jaguar",
    "JDM",
    "Jeep",
    "Kaipan",
    "Koenigsegg",
    "KTM",
    "Lada",
    "Lamborghini",
    "Lancia",
    "Land Rover",
    "Lexus",
    "Ligier",
    "Lincoln",
    "Lotus",
    "Marcos",
    "Martin Motors",
    "Maruti",
    "Maserati",
    "MCC",
    "McLaren",
    "Mercury",
    "MG",
    "Microcar",
    "Mini",
    "Mitsubishi",
    "Morgan",
    "Moskvič",
    "MTX",
    "Noble",
    "Oldsmobile",
    "Oltcit",
    "Pagani",
    "Plymouth",
    "Polski Fiat",
    "Pontiac",
    "Porsche",
    "Proton",
    "Radical",
    "Rolls Royce",
    "Rover",
    "RUF",
    "Saab",
    "Saleen",
    "Santana",
    "Saturn",
    "Scion",
    "Smart",
    "Spyker",
    "SsangYong",
    "Subaru",
    "Suzuki",
    "Tata",
    "Tatra",
    "Tavria",
    "Tesla",
    "Trabant",
    "Triumph",
    "TVR",
    "UAZ",
    "Ultima",
    "Vauxhall",
    "Volga",
    "Wartburg",
    "Yugo",
    "Zastava",
];
