import {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {DriverInformation, DriverTestPublicQuestion} from "../generated-api/registerApi";
import {RegistrationPage, SectionCard} from "./RegistrationPage";
import {useLoadDriverState, useUpdateDriverState} from "./apiClientProvider";
import {navigateToNext, navigateToPrev, pageNameForSection, testCompleted} from "./functions";
import {FormProps, SectionType} from "./model";

export function Test({loginType = "REGISTRATION"}: FormProps) {
    const navigate = useNavigate();

    const pageName = pageNameForSection(loginType, SectionType.TEST);

    const {driverState} = useLoadDriverState(loginType);
    const mutation = useUpdateDriverState(loginType, navigate);

    // redirect to registration overview just in case user tries to get here via navigation
    useEffect(() => {
        if (testCompleted(driverState.test)) {
            navigateToNext(navigate, pageName);
        }
    }, [driverState.test]);

    const questions: DriverTestPublicQuestion[] = driverState.test?.questions ?? [];
    if (!questions || questions.length === 0) {
        console.warn("No test questions returned from B4F.");
        navigateToPrev(navigate, pageName);
    }
    const [answeredQuestions, setAnsweredQuestions] = useState(questions ?? []);

    async function onSubmit(event: any) {
        event.preventDefault();
        // setting success is the quickest way of disabling test section in RegistrationSection - once we get response back from b4f, it gets replaced by actual result
        const newState: DriverInformation = {
            ...driverState,
            test: {questions: answeredQuestions, success: true},
        };
        mutation.mutate(newState);
        navigateToNext(navigate, pageName);
    }

    const children = () => (
        <>
            {questions.map((question, index) => (
                <TestQuestion
                    data={question}
                    questionNumber={index + 1}
                    onAnswerSelected={onAnswerSelected}
                    key={question.id}
                />
            ))}
        </>
    );

    function onAnswerSelected(questionId: string, answerId: string) {
        setAnsweredQuestions((prevState) =>
            prevState.map((question) =>
                question.id === questionId ? {...question, selectedAnswerId: answerId} : question
            )
        );
    }

    return (
        <RegistrationPage
            headerTextId="test"
            hasPrevPage
            hasNextPage
            onSubmitClick={onSubmit}
            onBackClick={() => navigateToPrev(navigate, pageName)}
            children={children}
            nextButtonTextId="send"
            nextPageDisabled={() =>
                answeredQuestions.some((question) => !question.selectedAnswerId)
            }
        />
    );
}

interface TestQuestionProps {
    data: DriverTestPublicQuestion;
    questionNumber: number;
    onAnswerSelected: (questionId: string, answerId: string) => void;
}

function TestQuestion({data, onAnswerSelected, questionNumber}: TestQuestionProps) {
    const [selectedAnswer, setSelectedAnswer] = useState<string | undefined>(data.selectedAnswerId);

    function onSelectedAnswer(event: any) {
        if (event.target.checked) {
            const selectedAnswerId = event.target.value;
            setSelectedAnswer(selectedAnswerId);
            onAnswerSelected(data.id, selectedAnswerId);
        }
    }

    function isSelected(id: string): boolean {
        const isSame = selectedAnswer === id;
        return isSame;
    }

    return (
        <SectionCard headerText={`${questionNumber}. ${data.text}`}>
            {data.answers.map((answer) => (
                <Form.Check
                    type="radio"
                    key={`${questionNumber}_${data.id}_${answer.id}`}
                    id={`${data.id}_${answer.id}`}
                    name={`${data.id}_${answer.id}`}
                    checked={isSelected(answer.id)}
                    value={answer.id}
                    label={<span>{answer.text}</span>}
                    onChange={onSelectedAnswer}
                />
            ))}
        </SectionCard>
    );
}
