import {yupResolver} from "@hookform/resolvers/yup";
import {isEqual} from "lodash";
import {useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import {useForm} from "react-hook-form";
import {FormattedMessage, useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {Car, DriverInformation} from "../generated-api/registerApi";
import {cachedLicensePlateValidator} from "../utils/cachedValidators";
import {ImageInput} from "./ImageInput";
import {ControlledRow, RegistrationPage, SectionCard} from "./RegistrationPage";
import {
    completeReactivationSection,
    reactivationSectionCompleted,
    useLoadDriverState,
    useUpdateDriverState,
} from "./apiClientProvider";
import {carManufacturers, carTypes, colors, fuelTypes} from "./constants";
import {carInitValues, carYupSchema} from "./formsSchema";
import {formatPlaceholder, navigateToNext, navigateToPrev, pageNameForSection} from "./functions";
import {FormProps, SectionType, SubFormProps} from "./model";

export function CarForm({loginType = "REGISTRATION"}: FormProps) {
    const intl = useIntl();
    const navigate = useNavigate();

    const pageName = pageNameForSection(loginType, SectionType.CAR);

    const {driverState} = useLoadDriverState(loginType);
    const mutation = useUpdateDriverState(loginType, navigate);

    const driverGroup = driverState.personalInfo!.driverGroup;
    const initValues: DriverInformation = driverState.car
        ? driverState
        : {...driverState, car: carInitValues};

    const {
        formState: {errors},
        control,
        handleSubmit,
        setValue,
    } = useForm({
        resolver: yupResolver(carYupSchema(intl, driverGroup)),
        defaultValues: initValues,
        values: initValues,
        mode: "onBlur",
    });

    async function onSubmit(data: DriverInformation) {
        const car = data.car;
        if (car) {
            const updatedCar: Car = {...car, yearOfManufacture: String(car.yearOfManufacture)};
            const shouldUpdate =
                !isEqual(updatedCar, driverState.car) ||
                (loginType === "REACTIVATION" && !reactivationSectionCompleted(SectionType.CAR));
            if (shouldUpdate) {
                mutation.mutate({...data, car: updatedCar});
            }
        }
        if (loginType === "REACTIVATION") {
            completeReactivationSection(SectionType.CAR);
        }
        navigateToNext(navigate, pageName);
    }

    function onBlurTrim(field: any) {
        setValue(field.name, field.value?.trim());
        field.onBlur();
    }

    const children = () => (
        <>
            <CarDetail
                control={control}
                errors={errors}
                onBlurTrim={onBlurTrim}
                shouldValidateRegPlate={driverGroup !== "CARGO"}
            />
            <TechnicalCertificate control={control} errors={errors} />
            {driverGroup !== "CARGO" && <VehicleRegistration control={control} errors={errors} />}
        </>
    );

    return (
        <RegistrationPage
            headerTextId="car"
            onSubmitClick={handleSubmit(onSubmit)}
            onBackClick={() => navigateToPrev(navigate, pageName)}
            hasNextPage
            hasPrevPage
            children={children}
        />
    );
}

function CarDetail({
    control,
    errors,
    onBlurTrim = (field: any) => field.onBlur(),
    shouldValidateRegPlate,
}: SubFormProps & {shouldValidateRegPlate: boolean}) {
    const intl = useIntl();

    const [invalidLicensePlate, setInvalidLicensePlate] = useState(false);

    function checkRegistrationPlate(field: any) {
        setInvalidLicensePlate(false);
        onBlurTrim(field);
        if (!shouldValidateRegPlate) {
            return;
        }
        const stringVal: string = field.value ?? "";
        // only validate czech license plates starting with number + (uppercase) letter + number
        const regex = /\d[a-zA-Z]\d/;
        if (stringVal.substring(0, 3).match(regex)) {
            cachedLicensePlateValidator(field.value).then((valid) =>
                setInvalidLicensePlate(!valid)
            );
        }
    }

    return (
        <SectionCard headerId="car">
            <ControlledRow
                name="car.brand"
                control={control}
                render={({field}) => (
                    <Typeahead
                        id="car.brand"
                        placeholder={carManufacturers[0]}
                        onInputChange={(text) => {
                            field.onChange(text);
                        }}
                        onChange={(selected) => {
                            if (selected[0]) {
                                field.onChange(selected[0]);
                            }
                        }}
                        options={carManufacturers}
                        selected={field.value ? [field.value] : []}
                    />
                )}
                errors={errors}
            />

            <Row style={{paddingTop: "var(--spacing-medium)"}}>
                <Col sm={4} className="mb-3" style={{width: "70%"}}>
                    <ControlledRow
                        name="car.model"
                        control={control}
                        render={({field}) => (
                            <Form.Control
                                {...field}
                                data-cy="car-model-input"
                                type="text"
                                placeholder={formatPlaceholder(intl, field.name)}
                                onBlur={(_) => onBlurTrim(field)}
                            />
                        )}
                        errors={errors}
                    />
                </Col>
                <Col sm={2} className="mb-3" style={{width: "30%"}}>
                    <ControlledRow
                        name="car.yearOfManufacture"
                        control={control}
                        render={({field}) => (
                            <Form.Control
                                {...field}
                                data-cy="car-year-input"
                                type="number"
                                step={1}
                                min={2000}
                            />
                        )}
                        errors={errors}
                    />
                </Col>
            </Row>
            <ControlledRow
                name="car.type"
                control={control}
                render={({field}) => (
                    <Form.Select {...field} data-cy="car-type-select">
                        <option value="" />
                        {carTypes.map((carType) => (
                            <option key={carType} value={carType}>
                                {intl.formatMessage({id: `carType${carType}`})}
                            </option>
                        ))}
                    </Form.Select>
                )}
                errors={errors}
            />
            <ControlledRow
                name="car.licencePlate"
                control={control}
                render={({field}) => (
                    <>
                        <Form.Control
                            {...field}
                            data-cy="car-licence-plate-input"
                            type="text"
                            placeholder={formatPlaceholder(intl, field.name)}
                            onBlur={() => checkRegistrationPlate(field)}
                        />
                        {invalidLicensePlate && (
                            <div style={{paddingTop: "var(--spacing-small)", paddingBottom: "4px"}}>
                                <span
                                    style={{
                                        fontSize: "var(--font-size-small)",
                                        color: "var(--bs-secondary)",
                                    }}
                                >
                                    <FormattedMessage id="car.licencePlateInvalid" />
                                </span>
                            </div>
                        )}
                    </>
                )}
                errors={errors}
            />
            <ControlledRow
                name="car.color"
                control={control}
                render={({field}) => (
                    <Form.Select {...field} data-cy="car-color-select">
                        <option value="" />
                        {colors.map((color) => (
                            <option key={color} value={color}>
                                {intl.formatMessage({id: `color${normalizeColorName(color)}`})}
                            </option>
                        ))}
                    </Form.Select>
                )}
                errors={errors}
            />
            <ControlledRow
                name="car.fuelType"
                control={control}
                render={({field}) => (
                    <Form.Select {...field} data-cy="car-fuel-type-select">
                        <option value="" />
                        {fuelTypes.map((type) => (
                            <option key={type} value={type}>
                                {intl.formatMessage({id: `fuelType${type}`})}
                            </option>
                        ))}
                    </Form.Select>
                )}
                errors={errors}
            />
            <ControlledRow
                name="car.seatCount"
                control={control}
                render={({field}) => (
                    <Form.Control
                        {...field}
                        data-cy="car-seat-count-input"
                        type="number"
                        min="1"
                        max="9"
                        placeholder={formatPlaceholder(intl, field.name)}
                    />
                )}
                errors={errors}
            />
            <ControlledRow
                name="car.photo"
                control={control}
                render={({field}) => <ImageInput field={field} />}
                errors={errors}
            />
            <div style={{paddingTop: "var(--spacing-small)"}}>
                <span style={{fontSize: "var(--font-size-small)"}}>
                    <FormattedMessage id="car.photoInfo" />
                </span>
            </div>
        </SectionCard>
    );
}

function normalizeColorName(color: Car["color"]): string {
    const firstLetter = color.substring(0, 1);
    const lowercaseRest = color.substring(1).toLocaleLowerCase();
    return firstLetter + lowercaseRest;
}

function TechnicalCertificate({control, errors}: SubFormProps) {
    return (
        <SectionCard headerId="technicalCertificate">
            <ControlledRow
                name="car.carTechnicalCertificateNumber"
                control={control}
                render={({field}) => (
                    <Form.Control {...field} type="text" data-cy="technical-certificate-input" />
                )}
                errors={errors}
            />
            <ControlledRow
                name="car.technicalCertificatePage1"
                control={control}
                render={({field}) => <ImageInput field={field} />}
                errors={errors}
            />
            <ControlledRow
                name="car.technicalCertificatePage2"
                control={control}
                render={({field}) => <ImageInput field={field} />}
                errors={errors}
            />
        </SectionCard>
    );
}

function VehicleRegistration({control, errors}: SubFormProps) {
    return (
        <SectionCard headerId="taxiEvidence">
            <ControlledRow
                name="car.taxiEvidencePhoto"
                control={control}
                render={({field}) => <ImageInput field={field} />}
                errors={errors}
            />
        </SectionCard>
    );
}
