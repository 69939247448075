import {useState} from "react";
import {FormattedMessage} from "react-intl";
import ReactPlayer from "react-player";
import {useNavigate} from "react-router-dom";
import {DriverInformation} from "../generated-api/registerApi";
import {RegistrationPage} from "./RegistrationPage";
import {useLoadDriverState, useUpdateDriverState} from "./apiClientProvider";
import {navigateToNext, navigateToPrev, pageNameForSection, videoUrls} from "./functions";
import {FormProps, SectionType} from "./model";

export function Tutorial({loginType = "REGISTRATION"}: FormProps) {
    const navigate = useNavigate();

    const pageName = pageNameForSection(loginType, SectionType.TUTORIAL);

    const {driverState} = useLoadDriverState(loginType);
    const [canPressNext, setCanPressNext] = useState(driverState.trainingCompleted ?? false);

    const mutation = useUpdateDriverState(loginType, navigate);
    const driverGroup = driverState.personalInfo!.driverGroup;
    const testCompleted =
        driverState.test &&
        driverState.test.success !== null &&
        driverState.test.success !== undefined;

    const tutorial = () => (
        <div style={{width: "100%"}}>
            <h4 style={{fontWeight: "var(--font-weight-normal)"}}>
                <FormattedMessage id="tutorialHeader" />
            </h4>
            <div style={{paddingBottom: "var(--spacing-small)"}}>
                <span>
                    <FormattedMessage id="tutorialInfo" />
                </span>
            </div>

            {videoUrls(driverGroup).map((video, index, array) => (
                <>
                    <h5>
                        {video.header ? `${index + 1}.` : ""} {video.header}
                    </h5>
                    {/* css magic, ratio 16:9 */}
                    <div style={{position: "relative", paddingTop: "56.25%"}}>
                        <ReactPlayer
                            style={{position: "absolute", top: 0, left: 0}}
                            url={video.url}
                            key={`tutorial-player-${index}`}
                            controls
                            onPlay={() => (array.length === index + 1 ? setCanPressNext(true) : {})}
                            onEnded={() =>
                                array.length === index + 1 ? setCanPressNext(true) : {}
                            }
                            width="100%"
                            height="100%"
                        />
                    </div>
                </>
            ))}
        </div>
    );

    async function onSubmit(event: any) {
        event.preventDefault();
        if (!driverState.trainingCompleted) {
            const newState: DriverInformation = {...driverState, trainingCompleted: true};
            mutation.mutate(newState);
        }
        if (testCompleted) {
            navigateToPrev(navigate, pageName);
        } else {
            navigateToNext(navigate, pageName);
        }
    }

    return (
        <RegistrationPage
            headerTextId="tutorial"
            onBackClick={() => navigateToPrev(navigate, pageName)}
            nextPageDisabled={() => !canPressNext}
            onSubmitClick={onSubmit}
            children={tutorial}
            hasNextPage
            hasPrevPage
        />
    );
}
