import {FormattedMessage, useIntl} from "react-intl";
import {useLoadDriverState} from "./apiClientProvider";
import {infoLinkIdForRideType} from "./functions";
import {FormProps} from "./model";
import {RegistrationPage} from "./RegistrationPage";

export function RegistrationClosed({loginType = "REGISTRATION"}: FormProps) {
    const intl = useIntl();
    const {driverState} = useLoadDriverState(loginType);

    const children = () => (
        <div style={{paddingBottom: "var(--spacing-medium)"}}>
            <h2 style={{fontWeight: "var(--font-weight-thin)"}}>
                <FormattedMessage id="registrationClosedHeader" />
            </h2>
            <span>
                <FormattedMessage id="registrationClosedBody" />
            </span>
            <div style={{paddingTop: "var(--spacing-medium)"}}>
                <a
                    style={{
                        fontWeight: "normal",
                        color: "var(--bs-primary)",
                        textDecoration: "none",
                    }}
                    href={intl.formatMessage({
                        id: infoLinkIdForRideType(
                            driverState?.personalInfo?.driverGroup ?? "UNIVERSAL"
                        ),
                    })}
                    target="_blank"
                    rel="noreferrer"
                >
                    <FormattedMessage id="driverTermsInfo" />
                </a>
            </div>
        </div>
    );

    return <RegistrationPage headerTextId="registration" children={children} />;
}
