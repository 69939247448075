import {yupResolver} from "@hookform/resolvers/yup";
import {useEffect, useState} from "react";
import {Button, Col, Form, InputGroup, Modal, Row} from "react-bootstrap";
import {Control, FieldErrors, useForm} from "react-hook-form";
import {BsEye, BsEyeSlash} from "react-icons/bs";
import {FormattedMessage, useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {LoaderSpinner} from "../App";
import {ControlledRow, RegistrationPage, SectionCard} from "./RegistrationPage";
import {notifyAndroidRegistrationDone, register, useLoadDriverState} from "./apiClientProvider";
import {registrationDataInitValues, registrationLoginSchema} from "./formsSchema";
import {formatPlaceholder, navigateToNext} from "./functions";
import {isWebView} from "./isWebView";
import {PageName, RegistrationData, SubFormProps} from "./model";

enum LoginResult {
    SUCCESS,
    ALREADY_REGISTERED,
    WRONG_PASSWORD,
    NOT_CALLED,
}

export function RegisterLogin() {
    const intl = useIntl();
    const navigate = useNavigate();
    const {refetch} = useLoadDriverState();

    const values: RegistrationData = registrationDataInitValues;
    const [showModal, setShowModal] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [loginResult, setLoginResult] = useState(LoginResult.NOT_CALLED);

    const {
        formState: {errors},
        control,
        handleSubmit,
    } = useForm({
        resolver: yupResolver(registrationLoginSchema(intl)),
        defaultValues: registrationDataInitValues,
        values: values,
        mode: "onBlur",
    });

    useEffect(() => {
        if (loginResult === LoginResult.SUCCESS) {
            // we want to fetch registration data and then redirect
            refetch().then(() => {
                navigateToNext(navigate, PageName.REGISTER_LOGIN);
                setSubmitting(false);
            });
        }
    }, [loginResult]);

    function onSubmit(registration: RegistrationData, event: any) {
        event.preventDefault();
        setSubmitting(true);

        register(registration)
            .then((tokenType) => {
                if (tokenType === "REGISTRATION") {
                    setLoginResult(LoginResult.SUCCESS);
                    notifyAndroidRegistrationDone();
                } else {
                    setLoginResult(LoginResult.ALREADY_REGISTERED);
                    setShowModal(true);
                    setSubmitting(false);
                }
            })
            .catch((ex): any => {
                setSubmitting(false);
                if (ex.response?.status === 403 || ex.response?.status === 401) {
                    setLoginResult(LoginResult.WRONG_PASSWORD);
                    setShowModal(true);
                } else {
                    console.error(ex);
                }
            });
    }

    function handleClose() {
        setShowModal(false);
        setLoginResult(LoginResult.NOT_CALLED);
    }

    const children = () => (
        <>
            <h4>
                <FormattedMessage id="welcome" />
            </h4>
            <Account control={control} errors={errors} />
            {showModal &&
                (isWebView() && loginResult === LoginResult.ALREADY_REGISTERED ? (
                    <AlreadyRegisteredModalWebView
                        showModal={showModal}
                        handleClose={handleClose}
                        handleClickLogin={() => notifyAndroidRegistrationDone()}
                    />
                ) : (
                    <AlreadyRegisteredModalWeb
                        showModal={showModal}
                        handleClose={handleClose}
                        headerTextId={
                            loginResult === LoginResult.WRONG_PASSWORD && isWebView()
                                ? "emailAlreadyExistsWrongPwd"
                                : "alreadyRegisteredText"
                        }
                    />
                ))}
        </>
    );

    return (
        <RegistrationPage
            hasNextPage={!submitting}
            headerTextId="account"
            onSubmitClick={handleSubmit(onSubmit)}
            children={submitting ? () => <LoaderSpinner /> : children}
        />
    );
}

function Account({control, errors}: SubFormProps) {
    const intl = useIntl();
    return (
        <SectionCard headerId="account">
            <ControlledRow
                name="email"
                control={control}
                render={({field}) => (
                    <Form.Control
                        {...field}
                        type="email"
                        autoComplete="username"
                        placeholder={formatPlaceholder(intl, field.name)}
                        value={field.value}
                    />
                )}
                errors={errors}
            />
            <PasswordField name="password" control={control} errors={errors} />
            <PasswordField name="passwordAgain" control={control} errors={errors} />
        </SectionCard>
    );
}

interface PasswordFieldProps {
    name: string;
    errors: FieldErrors;
    control: Control<any>;
}

function PasswordField({name, errors, control}: PasswordFieldProps) {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <ControlledRow
            name={name}
            control={control}
            render={({field}) => (
                <InputGroup>
                    <Form.Control
                        {...field}
                        type={showPassword ? "text" : "password"}
                        autoComplete="new-password"
                    />
                    <InputGroup.Text onClick={(_: any) => setShowPassword(!showPassword)}>
                        {showPassword ? <BsEyeSlash /> : <BsEye />}
                    </InputGroup.Text>
                </InputGroup>
            )}
            errors={errors}
        />
    );
}

function AlreadyRegisteredModalWeb({
    showModal,
    handleClose,
    headerTextId,
}: {
    showModal: boolean;
    handleClose: () => void;
    headerTextId: string;
}) {
    return (
        <Modal show={showModal} onHide={handleClose} centered>
            <Modal.Body style={{fontSize: "20px", fontWeight: "500"}}>
                <FormattedMessage id={headerTextId} />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="light"
                    onClick={handleClose}
                    size="lg"
                    style={{
                        width: "100%",
                        color: "var(--bs-primary)",
                        fontWeight: 500,
                        fontSize: "var(--font-size-text)",
                    }}
                >
                    <FormattedMessage id="ok" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function AlreadyRegisteredModalWebView({
    showModal,
    handleClose,
    handleClickLogin,
}: {
    showModal: boolean;
    handleClose: () => void;
    handleClickLogin: () => void;
}) {
    return (
        <Modal show={showModal} onHide={handleClose} centered>
            <Modal.Body style={{fontSize: "20px", fontWeight: "500"}}>
                <FormattedMessage id="alreadyRegisteredTextWebView" />
            </Modal.Body>
            <Modal.Footer>
                <Row style={{width: "100%"}}>
                    <Col style={{textAlign: "left"}}>
                        <Button
                            style={{
                                width: "100%",
                                color: "var(--bs-primary)",
                                fontSize: "var(--font-size-text)",
                                fontWeight: 500,
                            }}
                            size="lg"
                            onClick={handleClose}
                            variant="light"
                        >
                            <FormattedMessage id="close" />
                        </Button>
                    </Col>
                    <Col style={{textAlign: "right"}}>
                        <Button
                            onClick={handleClickLogin}
                            variant="primary"
                            size="lg"
                            style={{
                                width: "100%",
                                fontSize: "var(--font-size-text)",
                                fontWeight: 500,
                            }}
                        >
                            <FormattedMessage id="login2" />
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}
