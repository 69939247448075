import {yupResolver} from "@hookform/resolvers/yup";
import {formatISO} from "date-fns";
import {isEqual} from "lodash";
import {Button, Form, InputGroup} from "react-bootstrap";
import {useForm, UseFormTrigger, useWatch} from "react-hook-form";
import {FormattedMessage, useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {DriverDocuments, DriverInformation, TaxIdPrefillType} from "../generated-api/registerApi";
import {ImageInput, ImageInputWithCrop} from "./ImageInput";
import {ControlledRow, RegistrationPage, SectionCard} from "./RegistrationPage";
import {
    completeReactivationSection,
    lookupCompany,
    reactivationSectionCompleted,
    useLoadDriverState,
    useUpdateDriverState,
} from "./apiClientProvider";
import {documentsInitValues, documentsYupSchema} from "./formsSchema";
import {formatPlaceholder, navigateToNext, navigateToPrev, pageNameForSection} from "./functions";
import {FormProps, SectionType, SubFormProps} from "./model";
import {BsSearch} from "react-icons/bs";
import {UseFormSetValue} from "react-hook-form/dist/types/form";
import {useEffect, useState} from "react";

export function DocumentsForm({loginType = "REGISTRATION"}: FormProps) {
    const intl = useIntl();
    const navigate = useNavigate();

    const pageName = pageNameForSection(loginType, SectionType.DOCUMENTS);

    const {driverState} = useLoadDriverState(loginType);
    const mutation = useUpdateDriverState(loginType, navigate);
    const driverGroup = driverState.personalInfo!.driverGroup;

    const initValues: DriverInformation = driverState?.documents
        ? driverState
        : {...driverState, documents: documentsInitValues};

    const {
        formState: {errors},
        control,
        handleSubmit,
        setValue,
        trigger,
    } = useForm({
        resolver: yupResolver(documentsYupSchema(intl, driverGroup)),
        defaultValues: initValues,
        values: initValues,
        mode: "onBlur",
    });

    async function onSubmit(data: DriverInformation, event: any) {
        event.preventDefault();
        const documents = data.documents;
        const shouldSaveForReactivation = () =>
            loginType === "REACTIVATION" && !reactivationSectionCompleted(SectionType.DOCUMENTS);
        const shouldUpdate =
            documents &&
            (!isEqual(documents, driverState.documents) || shouldSaveForReactivation());
        if (shouldUpdate) {
            const updatedDocuments: DriverDocuments =
                driverGroup === "CARGO"
                    ? {...documents, yellowCard: undefined}
                    : {...documents, idCard: undefined};
            mutation.mutate({...data, documents: updatedDocuments});
        }
        if (loginType === "REACTIVATION") {
            completeReactivationSection(SectionType.DOCUMENTS);
        }
        navigateToNext(navigate, pageName);
    }

    function onBlurTrim(field: any) {
        setValue(field.name, field.value?.trim());
        field.onBlur();
    }

    const children = () => (
        <>
            <ProfilePhoto control={control} errors={errors} />
            {driverGroup !== "CARGO" && (
                <YellowCard control={control} errors={errors} onBlurTrim={onBlurTrim} />
            )}
            <DriverLicence control={control} errors={errors} onBlurTrim={onBlurTrim} />
            {driverGroup === "CARGO" && (
                <IdCard control={control} errors={errors} onBlurTrim={onBlurTrim} />
            )}
            <Company
                control={control}
                errors={errors}
                trigger={trigger}
                onBlurTrim={onBlurTrim}
                setValue={setValue}
                companyEditable={initValues.documents?.company?.editable !== false}
            />
        </>
    );

    return (
        <RegistrationPage
            headerTextId="documents"
            onSubmitClick={handleSubmit(onSubmit)}
            onBackClick={() => navigateToPrev(navigate, pageName)}
            hasNextPage
            hasPrevPage
            children={children}
        />
    );
}

function ProfilePhoto({control, errors}: SubFormProps) {
    return (
        <SectionCard headerId="profilePhotoSection">
            <ControlledRow
                name="documents.profilePhoto"
                control={control}
                render={({field}) => (
                    <>
                        <div>
                            <ImageInputWithCrop field={field} />
                        </div>
                    </>
                )}
                errors={errors}
            />
            <div style={{paddingTop: "var(--spacing-small)"}}>
                <span style={{fontSize: "var(--font-size-small)"}}>
                    <FormattedMessage id="documents.profilePhotoInfo" />
                </span>
            </div>
        </SectionCard>
    );
}

function YellowCard({control, errors, onBlurTrim = (field: any) => field.onBlur()}: SubFormProps) {
    const intl = useIntl();
    return (
        <SectionCard headerId="yellowCard">
            <ControlledRow
                name="documents.yellowCard.number"
                control={control}
                render={({field}) => (
                    <Form.Control
                        {...field}
                        data-cy="yellow-card-input"
                        type="text"
                        placeholder={formatPlaceholder(intl, field.name)}
                        onBlur={(_) => onBlurTrim(field)}
                    />
                )}
                errors={errors}
            />
            <ControlledRow
                name="documents.yellowCard.validity"
                control={control}
                render={({field}) => (
                    <Form.Control
                        {...field}
                        data-cy="yellow-card-validity-input"
                        type="date"
                        pattern="\d{4}-\d{2}-\d{2}"
                        min={formatISO(new Date(), {representation: "date"})}
                    />
                )}
                errors={errors}
            />
            <ControlledRow
                name="documents.yellowCard.photo"
                control={control}
                render={({field}) => <ImageInput field={field} />}
                errors={errors}
            />
        </SectionCard>
    );
}

function DriverLicence({
    control,
    errors,
    onBlurTrim = (field: any) => field.onBlur(),
}: SubFormProps) {
    const intl = useIntl();
    return (
        <SectionCard headerId="drivingLicense">
            <ControlledRow
                name="documents.drivingLicense.number"
                control={control}
                render={({field}) => (
                    <Form.Control
                        {...field}
                        data-cy="driving-license-input"
                        type="text"
                        placeholder={formatPlaceholder(intl, field.name)}
                        onBlur={(_) => onBlurTrim(field)}
                    />
                )}
                errors={errors}
            />
            <ControlledRow
                name="documents.drivingLicense.expiration"
                control={control}
                render={({field}) => (
                    <Form.Control
                        {...field}
                        data-cy="driving-license-validity-input"
                        type="date"
                        pattern="\d{4}-\d{2}-\d{2}"
                        min={formatISO(new Date(), {representation: "date"})}
                    />
                )}
                errors={errors}
            />
            <ControlledRow
                name="documents.drivingLicense.birthDate"
                control={control}
                render={({field}) => (
                    <Form.Control
                        {...field}
                        data-cy="birth-date-input"
                        type="date"
                        pattern="\d{4}-\d{2}-\d{2}"
                        max={formatISO(new Date(), {representation: "date"})}
                    />
                )}
                errors={errors}
            />
            
            <ControlledRow
                name="documents.drivingLicense.birthPlace"
                control={control}
                render={({field}) => (
                    <Form.Control
                        {...field}
                        data-cy="birth-place-input"
                        type="text"
                        placeholder={formatPlaceholder(intl, field.name)}
                        onBlur={(_) => onBlurTrim(field)}
                    />
                )}
                errors={errors}
            />
            <ControlledRow
                name="documents.drivingLicense.photoPage1"
                control={control}
                render={({field}) => <ImageInput field={field} />}
                errors={errors}
            />
            <ControlledRow
                name="documents.drivingLicense.photoPage2"
                control={control}
                render={({field}) => <ImageInput field={field} />}
                errors={errors}
            />
        </SectionCard>
    );
}

function IdCard({control, errors, onBlurTrim = (field: any) => field.onBlur()}: SubFormProps) {
    const intl = useIntl();
    return (
        <SectionCard headerId="idCard">
            <ControlledRow
                name="documents.idCard.number"
                control={control}
                render={({field}) => (
                    <Form.Control
                        {...field}
                        data-cy="id-card-input"
                        type="text"
                        placeholder={formatPlaceholder(intl, field.name)}
                        onBlur={(_) => onBlurTrim(field)}
                    />
                )}
                errors={errors}
            />
            <ControlledRow
                name="documents.idCard.expiration"
                control={control}
                render={({field}) => (
                    <Form.Control
                        {...field}
                        data-cy="id-card-validity-input"
                        type="date"
                        pattern="\d{4}-\d{2}-\d{2}"
                        min={formatISO(new Date(), {representation: "date"})}
                    />
                )}
                errors={errors}
            />
            <ControlledRow
                name="documents.idCard.photo"
                control={control}
                render={({field}) => <ImageInput field={field} />}
                errors={errors}
            />
        </SectionCard>
    );
}

interface SettableSubFormProps extends SubFormProps {
    setValue: UseFormSetValue<any>;
}

interface CompanySubFormProps extends SettableSubFormProps {
    companyEditable: boolean;
    trigger: UseFormTrigger<any>,
}

function Company({
    control,
    errors, 
    trigger,
    onBlurTrim = (field: any) => field.onBlur(),
    setValue,
    companyEditable,
}: CompanySubFormProps) {
    const intl = useIntl();
    const companyId = useWatch({
        control: control,
        name: "documents.company.companyId",
    });
    const companyName = useWatch({
        control: control,
        name: "documents.company.companyName",
    });
    const [isLookingUp, setIsLookingUp] = useState<boolean>();
    const [isEditDisabled, setIsEditDisabled] = useState<boolean>(!companyEditable);
    const [isNotFound, setIsNotFound] = useState<boolean>(false);

    const paysVAT = useWatch({
        control: control,
        name: "documents.company.paysVAT"
    })

    useEffect(() => {
        trigger("documents.company.taxId");
    }, [paysVAT, trigger]);
    
    function evaluateTaxId(lookedUpTaxId?: string, taxIdPrefillType?: TaxIdPrefillType): string {
        if (lookedUpTaxId) {
            return lookedUpTaxId;
        } else {
            switch (taxIdPrefillType) {
                case "COMPANY_ID":
                    return "CZ" + companyId;
                default:
                    return "";
            }
        }
    }

    function lookup() {
        setIsLookingUp(true);
        lookupCompany(companyId)
            .then((value) => {
                setValue("documents.company.paysVAT", value?.paysVAT ?? false);
                setValue(
                    "documents.company.taxId",
                    evaluateTaxId(value?.taxId, value?.taxIdPrefillType)
                );
                setValue("documents.company.companyName", value?.companyName ?? "");
                setValue("documents.company.street", value?.street ?? "");
                setValue("documents.company.city", value?.city ?? "");
                setValue("documents.company.zip", value?.zip ?? "");
                if (!value) {
                    control.setError("documents.company.companyId", {
                        type: "manual",
                        message: intl.formatMessage({id: "documents.company.companyIdNotFound"}),
                    });
                    setIsNotFound(true);
                }
                if (value?.editable === false) {
                    setIsEditDisabled(true);
                    control.setError("documents.company.companyId", {
                        type: "manual",
                        message: intl.formatMessage({id: "documents.company.companyNotEditable"}),
                    });
                } else {
                    setIsEditDisabled(false);
                }
            })
            .finally(() => {
                setIsLookingUp(false);
            });
    }

    return (
        <SectionCard headerId="company">
            <ControlledRow
                name="documents.company.companyId"
                control={control}
                render={({field}) => (
                    <>
                        <InputGroup className="mb-3">
                            <Form.Control
                                {...field}
                                data-cy="company-id-input"
                                type="text"
                                placeholder={formatPlaceholder(intl, field.name)}
                                onBlur={(_) => {
                                    onBlurTrim(field);
                                    lookup();
                                }}
                            />{" "}
                            <Button
                                data-cy="search-company-id-btn"
                                variant="outline-secondary"
                                onClick={lookup}
                                disabled={!field.value || isLookingUp}
                            >
                                {isLookingUp ? (
                                    <span className="spinner-border spinner-border-sm mr-1"></span>
                                ) : (
                                    <BsSearch />
                                )}
                            </Button>
                        </InputGroup>
                    </>
                )}
                errors={errors}
            />
            {(companyName || isNotFound) && (
                <>
                    <ControlledRow
                        displayLabel={false}
                        name="documents.company.paysVAT"
                        control={control}
                        render={({field}) => (
                            <Form.Check
                                {...field}
                                data-cy="company-pays-vat-switch"
                                type="switch"
                                disabled={isEditDisabled}
                                label={
                                    <span style={{alignItems: "end"}}>
                                        {intl.formatMessage({id: field.name})}
                                    </span>
                                }
                                reverse
                                checked={field.value}
                                style={{textAlign: "left"}}
                            />
                        )}
                        errors={errors}
                    />

                    <ControlledRow
                        name="documents.company.taxId"
                        control={control}
                        render={({field}) => (
                            <Form.Control
                                {...field}
                                data-cy="company-tax-id-input"
                                type="text"
                                disabled={isEditDisabled}
                                placeholder={formatPlaceholder(intl, field.name)}
                                onBlur={(_) => onBlurTrim(field)}
                            />
                        )}
                        errors={errors}
                    />
                    <ControlledRow
                        name="documents.company.companyName"
                        control={control}
                        render={({field}) => (
                            <Form.Control
                                {...field}
                                data-cy="company-name-input"
                                type="text"
                                disabled={isEditDisabled}
                                placeholder={formatPlaceholder(intl, field.name)}
                                onBlur={(_) => onBlurTrim(field)}
                            />
                        )}
                        errors={errors}
                    />
                    <ControlledRow
                        name="documents.company.street"
                        control={control}
                        render={({field}) => (
                            <Form.Control
                                {...field}
                                data-cy="company-street-input"
                                type="text"
                                disabled={isEditDisabled}
                                placeholder={formatPlaceholder(intl, field.name)}
                                onBlur={(_) => onBlurTrim(field)}
                            />
                        )}
                        errors={errors}
                    />
                    <ControlledRow
                        name="documents.company.city"
                        control={control}
                        render={({field}) => (
                            <Form.Control
                                {...field}
                                data-cy="company-city-input"
                                type="text"
                                disabled={isEditDisabled}
                                placeholder={formatPlaceholder(intl, field.name)}
                                onBlur={(_) => onBlurTrim(field)}
                            />
                        )}
                        errors={errors}
                    />
                    <ControlledRow
                        name="documents.company.zip"
                        control={control}
                        render={({field}) => (
                            <Form.Control
                                {...field}
                                data-cy="company-zip-input"
                                type="text"
                                disabled={isEditDisabled}
                                placeholder={formatPlaceholder(intl, field.name)}
                                onBlur={(_) => onBlurTrim(field)}
                            />
                        )}
                        errors={errors}
                    />
                </>
            )}
        </SectionCard>
    );
}
