import {ReactElement, ReactNode, useEffect} from "react";
import {Button, Card, Col, Container, FormGroup, FormLabel, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {Control, Controller, ControllerRenderProps} from "react-hook-form";
import {FormattedMessage, useIntl} from "react-intl";
import styled from "styled-components";
import logo from "../img/logo.svg";
import {isWebView} from "./isWebView";
import {PageName} from "./model";

export interface ControlledRowProps {
    name: string;
    errors: any;
    control: Control<any>;
    required?: boolean;
    render: ({field}: {field: ControllerRenderProps}) => ReactElement;
    labelId?: string;
    displayLabel?: boolean;
}

export interface RegistrationPageProps {
    headerTextId: string;
    nextPage?: PageName;
    prevPage?: PageName;
    hasNextPage?: boolean;
    hasPrevPage?: boolean;
    nextPageDisabled?: () => boolean;
    children: () => ReactElement;
    onBackClick?: () => void;
    onSubmitClick?: (event: any) => Promise<void>;
    nextButtonTextId?: string;
}

interface FooterButtonProps {
    hasNextPage: boolean;
    hasPrevPage: boolean;
    nextButtonTextId: string;
    onBackClick?: () => void;
    nextPageDisabled: () => boolean;
}

function parseErrorMessage(errors: any, name: string) {
    if (name.includes(".")) {
        const errorPaths = name.split(".");
        let currentError = errors[errorPaths[0]];
        for (let index = 1; index < errorPaths.length; index++) {
            if (currentError && currentError[errorPaths[index]]) {
                currentError = currentError[errorPaths[index]];
            } else {
                break;
            }
        }
        return currentError?.message;
    } else {
        return errors[name]?.message;
    }
}

export function ControlledRow({
    name,
    control,
    errors,
    render,
    required = true,
    labelId = name,
    displayLabel = true,
}: ControlledRowProps) {
    // need to compare this because value can be null
    const rules = required === true ? {required: true} : {required: false};
    const intl = useIntl();
    const errorMsg = parseErrorMessage(errors, name);

    return (
        <FormGroup controlId={name} className="form-group">
            {displayLabel && (
                <FormLabel style={{fontSize: "small"}}>
                    {intl.formatMessage({id: labelId})}
                </FormLabel>
            )}
            <Controller rules={rules} name={name} control={control} render={render} />
            {errorMsg && <ErrorText>{errorMsg.toString()}</ErrorText>}
        </FormGroup>
    );
}

export async function doNothing() {
    return;
}

export function RegistrationPage({
    headerTextId,
    hasNextPage = false,
    hasPrevPage = false,
    nextPageDisabled = () => false,
    children,
    onSubmitClick = doNothing,
    onBackClick = () => null,
    nextButtonTextId = "next",
}: RegistrationPageProps) {
    const intl = useIntl();
    const headerTitle = intl.formatMessage({id: headerTextId});

    return (
        <>
            {!isWebView() && <HeaderWithLogo />}
            <Helmet>
                <title>{headerTitle}</title>
            </Helmet>

            <form onSubmit={onSubmitClick}>
                <Container
                    style={{
                        paddingLeft: "var(--spacing-large)",
                        paddingRight: "var(--spacing-large)",
                        paddingTop: "var(--spacing-small)",
                    }}
                >
                    <Row>
                        <Col md={{span: 6, offset: 3}}>{children()}</Col>
                    </Row>
                </Container>
                <Container
                    style={{
                        paddingTop: "var(--spacing-medium)",
                        paddingBottom: "var(--spacing-medium)",
                        paddingLeft: "var(--spacing-large)",
                        paddingRight: "var(--spacing-large)",
                        width: "100%",
                    }}
                >
                    <FooterButtons
                        hasNextPage={hasNextPage}
                        hasPrevPage={hasPrevPage}
                        nextButtonTextId={nextButtonTextId}
                        onBackClick={onBackClick}
                        nextPageDisabled={nextPageDisabled}
                    />
                </Container>
            </form>
        </>
    );
}

function FooterButtons({
    hasNextPage,
    hasPrevPage,
    nextButtonTextId,
    onBackClick,
    nextPageDisabled,
}: FooterButtonProps) {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    if (hasNextPage && hasPrevPage) {
        return (
            <Row>
                <Col md={{span: 6, offset: 3}}>
                    <Row>
                        <Col style={{textAlign: "left"}}>
                            {hasPrevPage && (
                                <Button
                                    data-cy="drv-reg-back-btn"
                                    style={{
                                        width: "100%",
                                        fontSize: "var(--font-size-text)",
                                        fontWeight: 500,
                                    }}
                                    variant="light"
                                    type="reset"
                                    size="lg"
                                    onClick={onBackClick}
                                >
                                    <FormattedMessage id="back" />
                                </Button>
                            )}
                        </Col>
                        <Col style={{textAlign: "right"}}>
                            {hasNextPage && (
                                <Button
                                    data-cy="drv-reg-go-next-btn"
                                    variant="primary"
                                    type="submit"
                                    size="lg"
                                    disabled={nextPageDisabled()}
                                    style={{
                                        width: "100%",
                                        fontSize: "var(--font-size-text)",
                                        fontWeight: 500,
                                    }}
                                >
                                    <FormattedMessage id={nextButtonTextId} />
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    } else if (hasNextPage || hasPrevPage) {
        return (
            <Row>
                <Col md={{span: 6, offset: 3}}>
                    <Button
                        data-cy="drv-reg-submit-reg-btn"
                        variant={hasNextPage ? "primary" : "light"}
                        type={hasNextPage ? "submit" : "reset"}
                        size="lg"
                        disabled={hasNextPage ? nextPageDisabled() : false}
                        onClick={hasPrevPage ? onBackClick : () => null}
                        style={{width: "100%", fontSize: "var(--font-size-text)", fontWeight: 500}}
                    >
                        <FormattedMessage id={hasNextPage ? nextButtonTextId : "back"} />
                    </Button>
                </Col>
            </Row>
        );
    } else {
        return <></>;
    }
}

function HeaderWithLogo() {
    return (
        <HeaderWrapper>
            <img height={36} src={logo} alt="Liftago" />
        </HeaderWrapper>
    );
}

export interface SectionCardProps {
    headerId?: string;
    children: ReactNode;
    headerText?: string;
}

export function SectionCard({headerId = "", children, headerText = ""}: SectionCardProps) {
    return (
        <Card style={{marginTop: "var(--spacing-small)"}}>
            <Card.Header style={{fontWeight: "var(--font-weight-normal)"}}>
                {headerId ? <FormattedMessage id={headerId} /> : headerText}
            </Card.Header>
            <Card.Body>{children}</Card.Body>
        </Card>
    );
}

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--navigation-height);
    background-color: #0070d4;

    & > .dropdown > a {
        color: #334e73;
    }
    margin-bottom: var(--spacing-medium);
`;

const ErrorText = styled.div`
    font-size: var(--font-size-small);
    color: var(--bs-error-text-v2);
    font-weight: var(--font-weight-thin)
    margin-top: 0.5rem;
`;
