import {useEffect} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {useLocation} from "react-router-dom";
import {DriverPersonalInformation} from "../generated-api/registerApi";
import logo from "../img/logo.svg";
import {RegistrationPage} from "./RegistrationPage";
import {clearStorage} from "./apiClientProvider";
import {appLink} from "./constants";

export type RegistrationCompleteDriverState = {
    workingCity: string;
    driverGroup: DriverPersonalInformation["driverGroup"];
};

export function RegistrationComplete() {
    const location = useLocation();
    const state = location.state as RegistrationCompleteDriverState;
    // just in case...
    useEffect(() => {
        clearStorage();
    }, []);

    const children = () => (
        <>
            <div style={{paddingBottom: "var(--spacing-medium)"}}>
                <h2 style={{fontWeight: "var(--font-weight-thin)"}}>
                    <FormattedMessage id="registrationFinishSuccessHeader" />
                </h2>
                <span>
                    {state?.workingCity?.toLowerCase() === "prague" &&
                    ["TAXI", "UNIVERSAL"].includes(state?.driverGroup) ? (
                        <PragueTaxiCapableCompletedText />
                    ) : (
                        <NonPragueOrCargoCompletedText />
                    )}
                    <FormattedMessage id="registrationDownloadApp" />
                </span>
            </div>
            <Row style={{display: "flex", alignItems: "center"}}>
                <Col xs={9} className="d-flex justify-content-start">
                    <div
                        className="d-flex justify-content-left"
                        style={{
                            height: "46px",
                            width: "46px",
                            backgroundColor: "var(--bs-primary)",
                            borderRadius: "var(--border-radius)",
                        }}
                    >
                        <img width={36} src={logo} alt="Liftago" style={{textAlign: "left"}} />
                    </div>
                    <Col style={{paddingLeft: "var(--spacing-tiny)"}}>
                        <Row>
                            <span style={{fontWeight: "var(--font-weight-normal)"}}>
                                Liftago Driver
                            </span>
                        </Row>
                        <Row>
                            <span style={{fontSize: "14px", color: "var(--bs-secondary)"}}>
                                Taxi & Delivery App
                            </span>
                        </Row>
                    </Col>
                </Col>
                <Col xs={3} className="d-flex justify-content-end">
                    <a href={appLink} target="_blank" rel="noreferrer">
                        <Button style={{fontWeight: "var(--font-weight-normal)"}}>
                            <FormattedMessage id="download" />
                        </Button>
                    </a>
                </Col>
            </Row>
        </>
    );

    return <RegistrationPage headerTextId="registration" children={children} />;
}

function PragueTaxiCapableCompletedText() {
    return (
        <>
            <p>
                Nábor nových řidičů do Liftaga probíhá zpravidla v měsících, kdy se blíží nárůst
                poptávky, konkrétně v:
                <ul>
                    <li>Dubnu - květnu</li>
                    <li>Září - listopadu</li>
                </ul>
            </p>
            <p>
                Pokud chcete být v těchto měsících spuštěni rychleji, kontrolujte svou emailvou
                schránku a postupujte dle kroků, které vám budeme do emailu posílat. V případě, že
                budete mít vše připraveno, budete spuštěni rychleji.
            </p>
        </>
    );
}

function NonPragueOrCargoCompletedText() {
    return (
        <p>
            Do emailu Vám zašleme aktuální stav vaší registrace. V případě, že bude potřeba zaslat
            ještě nějaké dokumenty, učiňte tak co nejdříve.
        </p>
    );
}
