import {minCarYear} from "./app/constants";

export const messages = {
    cs: {
        "sign_up.gdpr_bullet_1": "registrace, vedení a správy uživatelského profilu řidiče",
        "sign_up.gdpr_bullet_2":
            "poskytnutí služeb LIFTAGO, a to na základě plnění smlouvy dle <a>Všeobecných obchodních podmínek</a>",
        "sign_up.gdpr_bullet_3":
            "oslovování s nabídkami produktů a služeb, vč. nabídek na míru, společnosti Liftago CZ, s.r.o., a to na základě jejího oprávněného zájmu.",
        "sign_up.gdpr_intro":
            "Pokračováním berete na vědomí, že společnosti Liftago, a.s. a Liftago CZ, s.r.o. jako společní správci osobních údajů budou zpracovávat Vaše osobní údaje, a to za účelem:",
        "sign_up.gdpr_outro":
            "Další informace ke zpracování, včetně možnosti odhlášení z odběru nabídek naleznete v <a>Zásadách ochrany osobních údajů</a>",
        account: "Účet",
        alreadyRegisteredText:
            "Tento email je již registrovaný. Přihlaste se prosím v mobilní aplikaci Liftago Driver",
        alreadyRegisteredTextWebView: "Tento email je již registrovaný. Přejete si přihlásit?",
        attemptOne: "pokus",
        attemptsMany: "pokusy",
        back: "Zpět",
        car: "Vozidlo",
        "car.brand": "Značka vozu",
        "car.color": "Barva",
        "car.fuelType": "Typ pohonu",
        "car.licencePlate": "Registrační značka",
        "car.licencePlateInvalid": "Registrační značka není v evidenci vozidel taxi",
        "car.licencePlatePlaceholder": "1A12345",
        "car.model": "Model",
        "car.modelPlaceholder": "Octavia",
        "car.photo": "Fotografie",
        "car.photoInfo":
            "Nahrajte takovou fotografii, aby byla vidět přední i boční strana vozu včetně viditelé SPZ",
        "car.carTechnicalCertificateNumber": "Číslo technického průkazu",
        "car.technicalCertificatePage1": "Fotografie přední strana",
        "car.technicalCertificatePage2": "Fotografie zadní strana",
        "car.type": "Typ",
        "car.taxiEvidencePhoto": "Fotografie",
        "car.yearOfManufacture": "Rok",
        "car.seatCount": "Počet sedadel (včetně řidiče)",
        "car.seatCountInvalid": "Musí být číslo v rozsahu 1 až 9",
        "car.carYearOfManufactureInvalid": "Rok musí být celé číslo",
        "car.carYearOfManufactureTooHigh": "Rok nesmí být vyšší než {currentYear}",
        "car.carYearOfManufactureTooLow": `Rok nesmí být nižší než ${minCarYear}`,
        carTypeCombi: "Kombi",
        carTypeHatchback: "Hatchback",
        carTypeMinibus: "Minibus",
        carTypeMPV: "MPV",
        carTypeSUV: "SUV",
        carTypeOther: "Jiný",
        carTypeSedan: "Sedan",
        carTypeVan: "Dodávka",
        chooseFile: "Vybrat soubor",
        close: "Zavřít",
        colorBlack: "Černá (tmavě šedá)",
        colorBlue: "Modrá",
        colorBrown: "Hnědá (bronzová)",
        colorGreen: "Zelená",
        colorOrange: "Oranžová",
        colorPurple: "Fialová (vínová)",
        colorRed: "Červená",
        colorSilver: "Stříbrná (světle šedá)",
        colorWhite: "Bílá (béžová)",
        colorYellow: "Žlutá (zlatá)",
        cropImage: "Ořízněte fotografii",
        documents: "Dokumenty",
        "documents.profilePhoto": "Fotografie",
        "documents.profilePhotoInfo":
            "Fotografie bude viditelná pro zákazníky a ovlivní vaší úspěšnost v aukcích. Nahrajte fotografii na bílém pozadí od ramen výše",
        "documents.drivingLicense.expiration": "Platnost",
        "documents.drivingLicense.birthNumber": "Rodné číslo",
        "documents.drivingLicense.birthDate": "Datum narození",
        "documents.drivingLicense.birthPlace": "Místo narození",
        "documents.drivingLicense.number": "Číslo průkazu",
        "documents.drivingLicense.numberPlaceholder": "405183283",
        "documents.drivingLicense.photoPage1": "Fotografie přední strana",
        "documents.drivingLicense.photoPage2": "Fotografie zadní strana",
        "documents.idCard.expiration": "Platnost",
        "documents.idCard.number": "Číslo průkazu",
        "documents.idCard.numberPlaceholder": "998009476",
        "documents.idCard.photo": "Fotografie",
        "documents.company.companyId": "IČO",
        "documents.company.companyIdPlaceholder": "12345678",
        "documents.company.companyIdNotFound":
            "Zadané IČO nebylo rozpoznáno. Zkontrolujte číslo nebo zadejte údaje ručně.",
        "documents.company.companyNotEditable":
            "Údaje nelze upravit kvůli spojení s již existující flotilou",
        "documents.company.paysVAT": "Plátce DPH",
        "documents.company.taxId": "DIČ",
        "documents.company.taxIdPlaceholder": "CZ1234567890",
        "documents.company.companyName": "Celé jméno / název koncesionáře",
        "documents.company.companyNamePlaceholder": "Jan Novák",
        "documents.company.street": "Ulice, č.o.",
        "documents.company.streetPlaceholder": "Národní 1/12",
        "documents.company.city": "Město",
        "documents.company.cityPlaceholder": "Praha",
        "documents.company.zip": "PSČ",
        "documents.company.zipPlaceholder": "100 00",
        "document.company.zipIncorrectFormat": "Nesprávný formát PSČ",
        "documents.yellowCard.number": "Číslo průkazu",
        "documents.yellowCard.numberPlaceholder": "123456",
        "documents.yellowCard.photo": "Fotografie",
        "documents.yellowCard.validity": "Platnost",
        driverGroupAll: "Univerzál (Taxi jízdy a zásilky)",
        driverGroupDelivery: "Cargo (Pouze zásilky)",
        driverGroupTaxi: "Taxi (Pouze taxi jízdy)",
        driverTermsInfo: "Více informací pro řidiče a řidičky naleznete zde na odkaze",
        drivingLicense: "Řidičský průkaz",
        download: "Stáhnout",
        email: "E-mail",
        emailAlreadyExists: "Tento email je již registrovaný, přejete se přihlásit?",
        emailAlreadyExistsWrongPwd: "Tento email je již registrovaný. Přejděte na přihlášení.",
        emailPlaceholder: "Zadejte svůj email",
        error: "Chyba",
        finishRegistration: "Dokončit registraci",
        finishRequest: "Dokončit žádost",
        fuelTypeDiesel: "Nafta",
        fuelTypeElectric: "Elektrické",
        fuelTypeGas: "Plyn",
        fuelTypeGasoline: "Benzin",
        fuelTypeHybrid: "Hybrid",
        generalTerms: "https://www.liftago.cz/vseobecne-obchodni-podminky/",
        idCard: "Občanský průkaz",
        company: "Fakturační údaje",
        invalidField: "{label} má neplatnou hodnotu",
        linkInfoAll:
            "https://www.liftago.cz/podpora/pro-ridice/podminky-spoluprace/pro-zajemce/univerzal/",
        linkInfoDelivery:
            "https://www.liftago.cz/podpora/pro-ridice/podminky-spoluprace/pro-zajemce/ridici-rozvozu-zasilek/",
        linkInfoTaxi:
            "https://www.liftago.cz/podpora/pro-ridice/podminky-spoluprace/pro-zajemce/ridici-osobnich-jizd/",
        loading: "Načítám",
        login: "Přihlášení",
        login2: "Přihlásit",
        next: "Pokračovat",
        ok: "OK",
        password: "Heslo",
        passwordAgain: "Potvrďte heslo",
        passwordsAreNotSame: "Hesla nejsou stejná",
        passwordShort: "Heslo musí být alespoň 8 znaků dlouhé.",
        passwordWhitespace: "Heslo nesmí obsahovat mezery.",
        "personalInfo.driverGroup": "Typ jízd",
        "personalInfo.driverGroupLabel": "O jaký typ jízd máte zájem?",
        "personalInfo.firstName": "Křestní jméno",
        "personalInfo.firstNamePlaceholder": "Zadejte své křestní jméno",
        "personalInfo.lastName": "Příjmení",
        "personalInfo.lastNamePlaceholder": "Zadejte své příjmení",
        "personalInfo.noTaximeterDescription": "Cena jízdy bude určena aplikací.",
        "personalInfo.phoneNumber": "Telefonní číslo",
        "personalInfo.phoneNumberInvalid":
            "Neplatné telefonní číslo (napište ve formátu +420777123456)",
        "personalInfo.taximeter": "Mám taxametr ve voze",
        "personalInfo.workingCity": "Město",
        "personalInfo.workingCityLabel": "Město, ve  kterém budete jezdit?",
        pleaseWait: "Načítám forografii...",
        privacyPolicy: "https://www.liftago.cz/zasady-ochrany-osobnich-udaju/",
        profile: "Profil",
        profilePhotoSection: "Profilová fotografie",
        reactivation: "Obnovení účtu",
        reactivationAlreadyCompleted:
            "Účet již byl obnovený, pokračujte prosím v mobilní aplikaci Liftago Driver",
        reactivationError:
            "Účet momentálně nelze obnovit, pokračujte prosím v mobilní aplikaci Liftago Driver",
        reactivationSectionsHeader: "Děkujeme za zájem o znovu zařazení do provozu.",
        reactivationSectionsText:
            "Pro opětovné spuštění si aktualizujte jednotlivé údaje ve všech sekcích a vyplňte test.",
        registration: "Registrace",
        registrationCase4: "registraci",
        registrationDownloadApp:
            "Pro dokončení profilu si stáhněte mobilní aplikaci Liftago Driver, která je dostupná na Google Play.",
        registrationFinishSuccessHeader: "Úspěšně jste se registroval/a do Liftaga!",
        registrationClosedBody:
            "Děkujeme za projevený zájem. Jakmile bude nábor opět spuštěn (zpravidla na jaře a na podzim), budeme vás kontaktovat pomocí e-mailu, který jste vyplnili v registračním formuláři. Nyní došlo k zapsání na čekací listinu.",
        registrationClosedHeader: "Nábor je aktuálně uzavřen.",
        registrationSectionsHeader:
            "Pro úspěšné vytvoření řidičského profilu vyplňte následující sekce",
        registrationSectionsHeaderAddon: "Celý proces vám zabere přibližně 45 min",
        registrationSectionsTimeLimit: "do 3 dnů od zahájení",
        remove: "Odstranit",
        request: "žádost",
        requiredField: "{label} je povinné pole",
        send: "Odeslat",
        sending: "Odesílám",
        successBody: "Kontaktujeme vás telefonicky, jakmile bude vaše {type} zkontrolována.",
        successHeader: "Kompletní profil. Dokončete {type} a máte hotovo!",
        technicalCertificate: "Malý technický průkaz",
        test: "Test",
        testAttemptRemainsMany: "zbývají",
        testAttemptRemainsOne: "zbývá",
        tutorial: "Školení",
        tutorialHeader: "Jak na aplikaci?",
        tutorialInfo:
            "Zde dbejte zvýšené pozornosti, po školení bude potřeba absolvovat krátký test, který rozhodne o vašem vstupu do Liftaga.",
        taxiEvidence: "Výpis z evidence vozidel taxislužby",
        unknownError: "Došlo k nečekané chybě, aplikace vás nyní přesměřuje.",
        welcome:
            "Těší nás, že se chcete stát řidičem Liftaga! V následujících krocích dokončete svou registraci",
        yellowCard: "Průkaz řidiče taxislužby",
    },
};

export function getLanguage() {
    const allowedLanguages = ["cs"];
    const language = navigator.language.split("-")[0];
    return allowedLanguages.indexOf(language) > -1 ? language : "cs";
}

export function getMessages() {
    return messages[getLanguage()];
}
